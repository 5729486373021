import { FC, useState, useEffect, useMemo } from "react";
import {
  Form,
  Button,
  Drawer,
  message,
  Typography,
  Input,
  Radio,
  RadioChangeEvent,
  Select,
  Row,
} from "antd";
import { Firebase } from "services";
import { IEntity } from "types";
import {
  internalTransferTypes,
  TInternalTransferType,
  TRANSACTION_TYPE,
} from "types/transfers";
import { openQueryWithTimestamp, toCurrencyNumber } from "utils";
import db from "services/firestore";
import { ICurrentBalanceDoc } from "types/balances";

const { Title, Text } = Typography;

const subscribeToBalances = (
  entityId: string,
  callback: (data: ICurrentBalanceDoc[]) => void
) => {
  try {
    const subscribe = db
      .collection("currentBalances")
      .where("_owner", "==", entityId)
      .onSnapshot((query) =>
        callback(
          openQueryWithTimestamp(query, [
            "_created",
            "_updated",
          ]) as ICurrentBalanceDoc[]
        )
      );

    return subscribe;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

interface IOwnProps {
  entity: IEntity;
  isVisible: boolean;
  onClose: (...args: any) => void;
}

const InternalTransfer: FC<IOwnProps> = ({ isVisible, entity, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [balances, setBalances] = useState<ICurrentBalanceDoc[]>([]);
  const [houseBalances, setHouseBalances] = useState<ICurrentBalanceDoc[]>([]);

  const [balance, setBalance] = useState<ICurrentBalanceDoc>();
  const [amount, setAmount] = useState<number>();
  const [type, setType] = useState<TInternalTransferType>(
    TRANSACTION_TYPE.manual
  );
  const [reason, setReason] = useState<string>();

  const [transferVariant, setTransferVariant] = useState<
    "house-to-entity" | "entity-to-house"
  >("entity-to-house");

  const form = Form.useForm()[0];

  const currenciesForDropdown = useMemo(
    () => (transferVariant === "entity-to-house" ? balances : houseBalances),
    [transferVariant, balances, houseBalances]
  );

  const onChangeTransferVariant = (e: RadioChangeEvent) =>
    setTransferVariant(e.target.value);

  const onSendInternalTransfer = async () => {
    if (balance.amount < amount) {
      message.error("Amount more then available balance");
      return;
    }

    try {
      setIsLoading(true);

      const { data } = await Firebase.createInternalTransfer({
        fromEntityId:
          transferVariant === "entity-to-house" ? entity.id : "house",
        toEntityId: transferVariant === "entity-to-house" ? "house" : entity.id,
        amount,
        currency: balance.currency,
        type,
        reason,
      });

      if (data.success) {
        message.success(`Created transfer successfully`);
        form.resetFields();
        onClose();
      } else {
        message.error(data.message);
      }
    } catch (error) {
      message.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const unsubscribeToBalances = subscribeToBalances(entity.id, (balances) =>
      setBalances(
        balances
          .map((balance) => ({
            ...balance,
            _created: balance._created.toISOString(),
          }))
          .filter((balance) => balance.source === "currencyCloud")
      )
    );

    const unsubscribeToHouseBalances = subscribeToBalances(
      "house",
      (balances) =>
        setHouseBalances(
          balances
            .map((balance) => ({
              ...balance,
              _created: balance._created.toISOString(),
            }))
            .filter((balance) => balance.source === "currencyCloud")
        )
    );

    return () => {
      unsubscribeToBalances && unsubscribeToBalances();
      unsubscribeToHouseBalances && unsubscribeToHouseBalances();
    };
  }, [entity.id]);

  return (
    <Drawer
      title={`Create Internal Transfer`}
      width={420}
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      open={isVisible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form form={form} layout="vertical" onFinish={onSendInternalTransfer}>
        <Form.Item>
          <Radio.Group
            onChange={onChangeTransferVariant}
            value={transferVariant}
          >
            <Radio value={"entity-to-house"}>From Entity to House</Radio>
            <Radio value={"house-to-entity"}>From House to Entity</Radio>
          </Radio.Group>
        </Form.Item>

        <Row
          style={{ marginBottom: 12 }}
          justify="space-between"
          align="middle"
        >
          <Title level={5}>Currency:</Title>
          <Select
            style={{
              width: 180,
            }}
            placeholder="Currency"
            onChange={(value) =>
              setBalance(
                currenciesForDropdown.find((balance) => balance.id === value)
              )
            }
          >
            {currenciesForDropdown.map((balance) => (
              <Select.Option key={balance.id} value={balance.id}>
                {balance.currency}
              </Select.Option>
            ))}
          </Select>
        </Row>

        <Row
          style={{ marginBottom: 12 }}
          justify="space-between"
          align="middle"
        >
          <Title level={5}>Type:</Title>

          <Select
            style={{
              width: 180,
            }}
            placeholder="Internal Transfer Type"
            onChange={(value) => setType(value)}
          >
            {internalTransferTypes.map((internalTransferType) => (
              <Select.Option
                key={internalTransferType}
                value={internalTransferType}
              >
                {internalTransferType}
              </Select.Option>
            ))}
          </Select>
        </Row>

        <Row
          style={{ marginBottom: 12 }}
          justify="space-between"
          align="middle"
        >
          <Title level={5}>Available Amount:</Title>
          <Text>
            {!balance
              ? "-"
              : `${balance.currency} ${toCurrencyNumber(balance.amount)}`}
          </Text>
        </Row>

        <Row
          style={{ marginBottom: 12 }}
          justify="space-between"
          align="middle"
        >
          <Title level={5}>Amount to Transfer:</Title>
          <Row>
            <Input
              type="number"
              value={amount}
              disabled={!balance}
              max={balance?.amount}
              onChange={(e) => setAmount(+e.target.value)}
            />
          </Row>
        </Row>

        <Row
          style={{ marginBottom: 24 }}
          justify="space-between"
          align="middle"
        >
          <Title level={5}>Reason:</Title>
          <Row>
            <Input
              value={reason}
              disabled={!balance}
              onChange={(e) => setReason(e.target.value)}
            />
          </Row>
        </Row>

        <Form.Item>
          <Row justify="space-between" align="middle">
            <Button
              onClick={() => {
                form.resetFields();
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button
              disabled={isLoading || !balance || !amount}
              htmlType="submit"
              type="primary"
            >
              Create Internal Transfer
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default InternalTransfer;
