import { FC, useState, useEffect } from "react";
import { Button, message, Typography } from "antd";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import ace from "brace";
import "brace/mode/json";
import "brace/theme/github";
import { openDoc, TCustomRouteComponentProps } from "utils";
import "./CurrencyRates.css";
import db from "services/firestore";
const { Title } = Typography;

const subscribeToCurrencyRatesConfig = (callback) => {
  try {
    const unsubscribe = db
      .collection("currencyRates")
      .doc("config")
      .onSnapshot((doc) => callback(openDoc(doc)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const CurrencyRates: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [currencyRatesConfig, setCurrencyRatesConfig] = useState(null);

  const updateCurrencyRatesConfig = async () => {
    try {
      await db
        .collection("currencyRates")
        .doc("config")
        .update(currencyRatesConfig);

      message.success("Currency Rates was updated.");
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    const unsubscribe = subscribeToCurrencyRatesConfig((data) =>
      setCurrencyRatesConfig(data)
    );

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  return currencyRatesConfig ? (
    <div>
      <Title>Currency Rates Config</Title>

      <Editor
        ace={ace}
        theme="ace/theme/github"
        value={currencyRatesConfig}
        mode="code"
        onChange={(data) => {
          if (!data) {
            setCurrencyRatesConfig({});
            return;
          }
          setCurrencyRatesConfig(data);
        }}
      />

      {allowWrite && (
        <Button
          style={{
            margin: "16px 0",
          }}
          type="primary"
          onClick={updateCurrencyRatesConfig}
        >
          Update
        </Button>
      )}
    </div>
  ) : null;
};

export default CurrencyRates;
