import { IResponse } from "types";
import { AxiosPrivateFirebaseInstance } from "settings";

export const getExternalInvoice = async ({
  sourceSystemId,
  entityId,
}: {sourceSystemId: string, entityId: string }) => {
  const response = await AxiosPrivateFirebaseInstance.get<IResponse<any>>(
    `/admin_actions/integrations/${entityId}/invoices/external/${sourceSystemId}`,
  );

  return response;
};
