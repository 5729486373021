import { action, Action, thunk, Thunk, computed, Computed } from 'easy-peasy';

import { Firebase } from 'services';
import { ICurrency, IFollowedCurrencyPair } from 'types';
import { Notify } from 'utils';

export interface CurrenciesStateModel {
  currencies: ICurrency[];
  followedCurrencies: IFollowedCurrencyPair[];
  setState: Action<CurrenciesStateModel, [string, any]>;
  getCurrencies: Thunk<CurrenciesStateModel>;
  currencyByCode: Computed<
    CurrenciesStateModel,
    (currencyCode: ICurrency['code']) => ICurrency | null
  >;
  followedCurrencyById: Computed<
    CurrenciesStateModel,
    (currencyId: IFollowedCurrencyPair['id']) => IFollowedCurrencyPair | null
  >;
  sellCurrencies: Computed<CurrenciesStateModel, ICurrency[]>;
  buyCurrencies: Computed<CurrenciesStateModel, ICurrency[]>;
  subscribeToFollowedCurrencies: Thunk<
    CurrenciesStateModel,
    Omit<Firebase.SubscribeToFollowedCurrenciesParams, 'callback'>,
    null,
    null,
    () => void
  >;
  addFollowedCurrencyPair: Thunk<
    CurrenciesStateModel,
    Omit<IFollowedCurrencyPair, 'id'>
  >;
  deleteFollowedCurrencyPair: Thunk<
    CurrenciesStateModel,
    IFollowedCurrencyPair['id']
  >;
  updateFollowedCurrencyPair: Thunk<
    CurrenciesStateModel,
    {
      followedCurrencyId: IFollowedCurrencyPair['id'];
      followedCurrencyData: Omit<IFollowedCurrencyPair, 'id'>;
    }
  >;
}

export const CurrenciesState: CurrenciesStateModel = {
  currencies: [],
  followedCurrencies: [],
  setState: action((state, payload) => {
    const [prop, to] = payload;
    state[prop] = to;
  }),
  getCurrencies: thunk(async (actions) => {
    const data = await Firebase.getCurrencies();

    if (data) {
      actions.setState(['currencies', data]);
    }
  }),
  subscribeToFollowedCurrencies: thunk(({ setState }, payload) => {
    const subscriber = Firebase.subscribeToFollowedCurrencies({
      ...payload,
      callback: (followedCurrencies) =>
        setState(['followedCurrencies', followedCurrencies]),
    });

    return subscriber;
  }),
  addFollowedCurrencyPair: thunk(async (actions, payload) => {
    const data = await Firebase.addFollowedCurrencyPair(payload);

    if (data && data.success) {
      return data;
    } else {
      Notify.error(data?.message || 'Failed to create followed currency');
    }
  }),
  deleteFollowedCurrencyPair: thunk(async (actions, payload) => {
    const data = await Firebase.deleteFollowedCurrencyPair(payload);

    if (data && data.success) {
      return data;
    } else {
      Notify.error(data?.message || 'Failed to delete followed currency');
    }
  }),
  updateFollowedCurrencyPair: thunk(async (actions, payload) => {
    const data = await Firebase.updateFollowedCurrencyPair(payload);

    if (data && data.success) {
      return data;
    } else {
      Notify.error(data?.message || 'Failed to update followed currency');
    }
  }),
  currencyByCode: computed((state) => (currencyCode) =>
    state.currencies.find((item) => item.code === currencyCode) || null
  ),
  followedCurrencyById: computed((state) => (currencyId) =>
    state.followedCurrencies.find((item) => item.id === currencyId) || null
  ),
  sellCurrencies: computed(
    (state) => state.currencies?.filter((currency) => currency.sell) || []
  ),
  buyCurrencies: computed(
    (state) => state.currencies?.filter((currency) => currency.buy) || []
  ),
};
