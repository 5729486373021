import {
  DatePicker,
  DatePickerProps,
  message,
  Row,
  Spin,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { Firebase } from "services";
import { IEntity } from "types";
import { toCurrencyNumber } from "utils";
const { Title, Text } = Typography;

const dateMonthFormat = "YYYY-MM";

const paymentsCountsColumns = [
  {
    title: "CCY",
    key: "currency",
    render: (data: IPaymentsSummary) => <Text>{data.currency}</Text>,
  },
  {
    title: "Local",
    key: "local",
    render: (data: IPaymentsSummary) => (
      <Row align="middle">
        <b style={{ width: "40px", marginRight: "6px" }}>{data.local.count}</b>
        <Text>{toCurrencyNumber(data.local.amount)} </Text>
      </Row>
    ),
  },
  {
    title: "SWIFT",
    key: "swift",
    render: (data: IPaymentsSummary) => (
      <Row align="middle">
        <b style={{ width: "40px", marginRight: "6px" }}>{data.swift.count}</b>
        <Text>{toCurrencyNumber(data.swift.amount)} </Text>
      </Row>
    ),
  },
  {
    title: "Cancelled",
    key: "cancelled",
    render: (data: IPaymentsSummary) => (
      <Row align="middle">
        <b style={{ width: "40px", marginRight: "6px" }}>
          {data.cancelled.count}
        </b>
        <Text>{toCurrencyNumber(data.cancelled.amount)} </Text>
      </Row>
    ),
  },
  {
    title: "Failed",
    key: "failed",
    render: (data: IPaymentsSummary) => (
      <Row align="middle">
        <b style={{ width: "40px", marginRight: "6px" }}>{data.failed.count}</b>
        <Text>{toCurrencyNumber(data.failed.amount)} </Text>
      </Row>
    ),
  },
  {
    title: "Total",
    key: "total",
    render: (data: IPaymentsSummary) => (
      <Row align="middle">
        <b style={{ width: "40px", marginRight: "6px" }}>{data.total.count}</b>
        <Text>{toCurrencyNumber(data.total.amount)} </Text>
      </Row>
    ),
  },
];

export interface IPaymentsCounts {
  currency: string;
  cancelled: number;
  failed: number;
  total: number;
}

export interface IPaymentsSummary {
  currency: string; // USD
  date: string; // 2024-10
  local: {
    count: number;
    amount: number;
  };
  swift: {
    count: number;
    amount: number;
  };
  cancelled: {
    count: number;
    amount: number;
  };
  failed: {
    count: number;
    amount: number;
  };
  total: {
    count: number;
    amount: number;
  };
}

const PaymentsCounts: FC<{ entity: IEntity }> = ({ entity }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [paymentsCounts, setLocalPaymentsCounts] =
    useState<IPaymentsCounts[]>(null);
  const [selectedDate, setSelectedDate] = useState(moment());

  useEffect(() => {
    const getPaymentsCounts = async () => {
      if (!selectedDate) {
        return;
      }

      try {
        setIsLoading(true);
        const { data } = await Firebase.getPaymentsCounts({
          entityId: entity.id,
          date: selectedDate.format(dateMonthFormat),
        });

        setLocalPaymentsCounts(data.data);
      } catch (error) {
        console.log(error);

        message.error("Failed to fetch payments counts");
      } finally {
        setIsLoading(false);
      }
    };

    if (entity) {
      getPaymentsCounts();
    }
  }, [entity, selectedDate]);

  const onDateChange: DatePickerProps["onChange"] = (date, dateString) =>
    setSelectedDate(date);

  return (
    <Table
      title={() => (
        <Row align="middle" justify="space-between">
          <Row align="middle">
            <Title style={{ marginRight: "16px" }} level={2}>
              Transfers counts
            </Title>
            {isLoading && <Spin style={{ marginBottom: "16px" }} />}
          </Row>

          <DatePicker
            defaultValue={selectedDate}
            format={dateMonthFormat}
            onChange={onDateChange}
            picker="month"
          />
        </Row>
      )}
      columns={paymentsCountsColumns}
      dataSource={paymentsCounts}
    />
  );
};

export default PaymentsCounts;
