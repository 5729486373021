export const defaultTheme = {
  breakpoint: {
    extraSmallMax: 374,
    extraSmallMin: 375,

    smallMax: 575,
    smallMin: 576,

    mediumMax: 767,
    mediumMin: 768,

    largeMax: 1024,
    largeMin: 1025,

    extraLargeMax: 1319,
    extraLargeMin: 1320,
  },

  color: {
    // B&W
    black: '#000',
    dark: '#121213',
    darkLight: '#1F2022',
    greyDark: '#454D58',
    grey: '#8C9199',
    greyLight_1: '#DCDEE1',
    greyLight_2: '#F2F2F2',
    greyLight_3: '#F7F7F7',
    greyLight_4: '#FBFBFB',
    white: '#fff',
    transparent: 'rgba(0,0,0,0)',

    // Accent
    emeraldDark: '#1CBD85',
    emeraldLight: '#DBF4EB',

    // Status
    green: '#3AA33A',
    coral: '#E87C53',
    red: '#EC4B4B',
  },
  transition: '0.3s',
  shadow: '0 0 9px rgba(0, 0, 0, 0.1)',
};
