import { FC, useState, useEffect } from "react";
import { Table, Tag, Typography } from "antd";

import dayjs from "dayjs";
import "./EntityList.css";
import { openQueryWithTimestamp, TCustomRouteComponentProps } from "utils";
import { Link } from "react-router-dom";

import db from "services/firestore";
import { IEntity } from "types";

const getEntities = async () => {
  try {
    const entities = await db
      .collection("entities")
      .where("status", "==", "onboarded")
      .get()
      .then((query) => openQueryWithTimestamp(query, ["_created"]));

    return entities.sort((a, b) =>
      b._created.toISOString().localeCompare(a._created.toISOString())
    );
  } catch (error) {
    console.log(error);
  }
};

const { Title } = Typography;

const entityColumns = [
  {
    title: "Created",
    dataIndex: "_created",
    key: "_created",
    render: (date) => dayjs(date).format("DD MMM YYYY"),
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text, record) => {
      return (
        <span>
          <Link to={"/app/entity-detail/" + record.id}>{text}</Link>
          {!record.enabled && <>{" "}<Tag color="red">DISABLED</Tag></>}
        </span>
      );
    },
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "CC Account Id",
    dataIndex: ["externalRefs", "ccId"],
    key: "ccId",
  },
  {
    title: "CC User/OnBehalfOf Id",
    dataIndex: ["externalRefs", "ccOnBehalfOfId"],
    key: "ccOnBehalfOfId",
  },
];

const EntityList: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [entities, setEntities] = useState<IEntity[]>([]);

  useEffect(() => {
    getEntities().then((res) => {
      if (res) {
        setEntities(res);
      }
    });
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      {entities && (
        <Table
          title={() => <Title level={2}>Onboarded Entities</Title>}
          columns={entityColumns}
          dataSource={entities}
        />
      )}
    </div>
  );
};

export default EntityList;
