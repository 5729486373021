import styled from 'styled-components';

export const BalancesContentWrapper = styled.div`
padding: 8px 16px;
background: white;
width: 100%;
display: flex;
border-radius: 8px;
overflow-x: auto;
margin-bottom: 35px;
min-height: 68px;
align-items: center;
`;

export const Separator = styled.div`
  min-width: 1px;
  height: 42px;
  margin: 0 16px;
  background: grey;
`;

export const AmountText = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin: 0 4px 0 6px;
  white-space: nowrap;
`;

export const AmountWithAvailableAmountText = styled.p`
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  white-space: nowrap;
`;

export const LedgerAmountText = styled.p`
  font-size: 10px;
  font-style: italic;
  text-align: right;
  line-height: 14px;
  margin: 0;
  white-space: nowrap;
`;
