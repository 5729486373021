import { openQueryWithTimestamp } from "utils";

import db from 'services/firestore';

export interface SubscribeToUserLogsParams {
  callback: (userLogs: any) => void;
}

export const subscribeToUserLogs = ({ callback }: SubscribeToUserLogsParams) => {
  const subscribe = db
    .collection("userLogs")
    .onSnapshot((query) =>
      callback(openQueryWithTimestamp(query, ["_createdAt"]))
    );

  return subscribe;
};
