import React, { FC, useState, useEffect } from "react";
import { Table, Tag, Typography } from "antd";

import dayjs from "dayjs";
import { Link } from "react-router-dom";
import "./TrackedRates.css";
import { convertFireStoreTimestampToDateTimeString, openQueryWithTimestamp } from "utils";

import db from 'services/firestore';
import { getRate } from "services/firebase";

const getRatesTracking = async () => {
  try {
    const trackings = await db
      .collection("ratesTracking")
      .get()
      .then((query) => openQueryWithTimestamp(query, ["_created"]))
      .then(trackingsFromDb => addRateAndRateDiffToTrackings(trackingsFromDb));

    return trackings;
  } catch (error) {
    console.log(error);
  }
};

const getTrackingViaInvoices = async () => {
  try {
    const trackings = await db
      .collection("tracking")
      .get()
      .then((query) => openQueryWithTimestamp(query, ["_created"]))
      .then(trackingsFromDb => addRateAndRateDiffToTrackings(trackingsFromDb));

    return trackings;
  } catch (error) {
    console.log(error);
  }
};

const getFollowedCurrenciesTargets = async () => {
  try {
    let followedCurrenciesWithTargets = await db.collectionGroup('followedCurrencyPairs')
      .where("targetRate", ">", 0)
      .get()
      .then((query) => openQueryWithTimestamp(query, ["_created"]))
      .then(followedCurrenciesWithTargetsFromDb => addRateAndRateDiffToTrackings(followedCurrenciesWithTargetsFromDb));

    return followedCurrenciesWithTargets;
  } catch (error) {
    console.log(error);
  }
};

const addRateAndRateDiffToTrackings = async (trackings: any[]) => {
  const getSign = (targetRate: number, clientRate: number, alertWhenMarketRateIs?: string) => {
    if (alertWhenMarketRateIs) {
      return alertWhenMarketRateIs === "aboveTargetRate" ? -1 : 1;
    }
    return targetRate > clientRate ? -1 : 1;
  }

  return Promise.all(trackings.map(async tracking => {
    const rateResponse = await getRate({sellCurrency: tracking.sellCurrency, buyCurrency: tracking.buyCurrency, entityId: tracking._owner});
    const clientRate = rateResponse?.data?.rate;

    const targetRate = tracking.targetRate;
    const diff = Math.abs(targetRate - clientRate);
    const diffSign = getSign(targetRate, clientRate, tracking.alertWhenMarketRateIs);

    const rateDiff = clientRate && targetRate ? diffSign * diff : null;

    const recordOutput = {...tracking, rate: clientRate, rateDiff};
    return recordOutput;
  }))
}


const { Title } = Typography;

const getInferredRate = (record) => {
  const sellCurrency = record.sellCurrency;
  const sellAmount = record.sellAmount;
  const buyCurrency = record.buyCurrency;
  const buyAmount = record.targetAmount;
  const currencyPair = sellCurrency + buyCurrency;
  const inferredRate = sellAmount / buyAmount;
  
  return { currencyPair, rate: Number.parseFloat(inferredRate.toFixed(4)) };
}

const RateDiff = ({ rateDiff }) => {
  const rateDiffAbs = Math.abs(rateDiff);
  return (
    <>
      {rateDiffAbs >= 0.05 && <Tag color="green">{rateDiff?.toFixed(5)}</Tag>}
      {rateDiffAbs >= 0.02 && rateDiffAbs < 0.05 && <Tag color="orange">{rateDiff?.toFixed(5)}</Tag>}
      {rateDiffAbs < 0.02 && <Tag color="red">{rateDiff?.toFixed(5)}</Tag>}
    </>
  )
};

const ratesTrackingColumns = () => 
  [
    {
      title: "Created",
      dataIndex: "_created",
      key: "_created",
      render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Owner",
      dataIndex: "alertToBeSentTo",
      key: "alertToBeSentTo",
      render: (text, record) => {
        return (
          <span>
            <Link to={"/app/users/" + record.alertToBeSentTo}>{text}</Link>
          </span>
        );
      },
    },
    {
      title: "Sell Currency",
      dataIndex: "sellCurrency",
      key: "sellCurrency",
    },
    {
      title: "Buy Currency",
      dataIndex: "buyCurrency",
      key: "buyCurrency",
    },
    {
      title: "Target Rate",
      dataIndex: "targetRate",
      key: "targetRate",
    },
    {
      title: "Alert when rate is",
      dataIndex: "alertWhenMarketRateIs",
      key: "alertWhenMarketRateIs",
    },
    {
      title: "Market rate",
      dataIndex: "rate",
      key: "rate",
    },
    {
      title: "Diff",
      dataIndex: "rateDiff",
      key: "rateDiff",
      render: (rateDiff, record) => {
        return (<RateDiff rateDiff={rateDiff} />)
      },
    },
    {
      title: "Target reached",
      dataIndex: "targetReached",
      key: "targetReached",
      render: (text, record) => {
        return (<>{record.targetReached ? <>TRUE</> : <>false</>}</>)
      }
    },
    {
      title: "Alert sent on",
      dataIndex: "alertSentTimestamp",
      key: "alertSentTimestamp",
      render: (firestoreTimestamp) => {
        const date = convertFireStoreTimestampToDateTimeString(firestoreTimestamp);
        return (<>{date ? <>{dayjs(date).format("DD MMM YYYY HH:mm:ss")}</> : <></>}</>)
      }
    },
    {
      title: "Tracking for",
      key: "trackingFor",
      render: (text, record) => {
        return (<>{record.ownerMetaData?.docId}</>);
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
  ];

const targetColumns = () => 
  [
    {
      title: "Created",
      dataIndex: "_created",
      key: "_created",
      render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Owner",
      dataIndex: "_createdBy",
      key: "_createdBy",
      render: (text, record) => {
        return (
          <span>
            <Link to={"/app/users/" + record._createdBy}>{text}</Link>
          </span>
        );
      },
    },
    {
      title: "Sell Currency",
      dataIndex: "sellCurrency",
      key: "sellCurrency",
    },
    {
      title: "Buy Currency",
      dataIndex: "buyCurrency",
      key: "buyCurrency",
    },
    {
      title: "Invert Rate",
      dataIndex: "invertRate",
      key: "invertRate",
      render: (text, record) => {
        return (<>{record.invertRate ? <>TRUE</> : <>false</>}</>)
      }
    },
    {
      title: "Target Rate",
      dataIndex: "targetRate",
      key: "targetRate",
    },
    {
      title: "Market rate",
      dataIndex: "rate",
      key: "rate",
    },
    {
      title: "Diff",
      dataIndex: "rateDiff",
      key: "rateDiff",
      render: (rateDiff, record) => {
        return (<RateDiff rateDiff={rateDiff} />)
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
  ];

const trackingColumns = () => 
  [
    {
      title: "Created",
      dataIndex: "_created",
      key: "_created",
      render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Owner",
      dataIndex: "_owner",
      key: "owner",
      render: (text, record) => {
        return (
          <span>
            <Link to={"/app/entity-detail/" + record._owner}>{text}</Link>
          </span>
        );
      },
    },
    {
      title: "Sell Currency",
      dataIndex: "sellCurrency",
      key: "sellCurrency",
    },
    {
      title: "Sell Amount",
      dataIndex: "sellAmount",
      key: "sellAmount",
    },
    {
      title: "Buy Currency",
      dataIndex: "buyCurrency",
      key: "buyCurrency",
    },
    {
      title: "Target Amount",
      dataIndex: "targetAmount",
      key: "targetAmount",
    },
    {
      title: "Target Rate",
      dataIndex: "targetRate",
      key: "targetRate",
    },
    {
      title: "Inferred Target Rate",
      key: "inferredRate",
      render: (text, record) => {
        const inferredRate = getInferredRate(record);
        const inferredString = inferredRate.currencyPair + ' ' + inferredRate.rate;

        return (
          <>
            {record.targetAmount > 0 && inferredString}
          </>
        );
      },
    },
    {
      title: "Market rate",
      dataIndex: "rate",
      key: "rate",
    },
    {
      title: "Diff",
      dataIndex: "rateDiff",
      key: "rateDiff",
      render: (rateDiff, record) => {
        return (<RateDiff rateDiff={rateDiff} />)
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
  ];

const TrackedRates: FC = () => {
  const [tracking, setTracking] = useState([]);
  const [ratesTracking, setRatesTracking] = useState([]);
  const [followedCurrenciesTargets, setFollowedCurrenciesTargets] = useState([]);

  useEffect(() => {
    getRatesTracking().then((res) => {
      if (res) {
        setRatesTracking(res);
      }
    });
  }, []);

  useEffect(() => {
    getTrackingViaInvoices().then((res) => {
      if (res) {
        setTracking(res);
      }
    });
  }, []);

  useEffect(() => {
    getFollowedCurrenciesTargets().then((res) => {
      if (res) {
        setFollowedCurrenciesTargets(res);
      }
    });
  }, []);

  return (
    <div>
      <div>
        <Table
          title={() => <Title level={2}>Rates Tracking</Title>}
          columns={ratesTrackingColumns()}
          dataSource={ratesTracking}
        />
      </div>
      <div>
        <Table
          title={() => <Title level={2}>Targets via Followed Currencies</Title>}
          columns={targetColumns()}
          dataSource={followedCurrenciesTargets}
        />
      </div>
      <div>
        <Table
          title={() => <Title level={2}>Tracked Rates via Invoices</Title>}
          columns={trackingColumns()}
          dataSource={tracking}
        />
      </div>
    </div>
  );
};

export default TrackedRates;
