import { IUpdateAdminUserRoles } from "services/firebase";
import { IAdminUser } from "types";
import RolePickerCell from "./components/RolePickerCell/RolePickerCell";

interface IAdminUsersColumnsProps {
  updateAdminUserRolesHandler: (
    payload: IUpdateAdminUserRoles
  ) => Promise<void>;
  isLoading: boolean;
  allowWrite: boolean;
}

export const adminUsersColumns = ({
  isLoading,
  allowWrite,
  updateAdminUserRolesHandler,
}: IAdminUsersColumnsProps) => [
  {
    title: "Id",
    dataIndex: "uid",
    key: "uid",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Roles",
    key: "customClaims",
    render: (user: IAdminUser) => (
      <RolePickerCell
        user={user}
        allowWrite={allowWrite}
        isLoading={isLoading}
        updateAdminUserRolesHandler={updateAdminUserRolesHandler}
      />
    ),
  },
];
