import { Notify, openQueryWithTimestamp } from "utils";
import { ITransfer, IResponse, ITransferInput } from "types";
import { AxiosPrivateFirebaseInstance } from "settings";

import db from "services/firestore";
import { IInternalTransferToInitiate } from "types/transfers";

export const getTransfers = async () => {
  try {
    const data = await db.collection("transfers").get();

    return openQueryWithTimestamp(data, ["_created"]);
  } catch (error) {
    console.warn(error.message);
  }
};

export interface SubscribeToTransfersParams {
  callback: (transfers: ITransfer[]) => void;
}

export const subscribeToTransfers = ({
  callback,
}: SubscribeToTransfersParams) => {
  try {
    return db
      .collection("transfers")
      .orderBy("_created", "desc")
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ["_created"]))
      );
  } catch (error) {
    console.warn(error);
  }
};

export const createTransfer = async (payload: ITransferInput) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.post<IResponse>(
      `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/transfers`,
      payload
    );

    if (data.success) {
      return data;
    } else {
      // @ts-ignore
      Notify.error(data.message);
    }
  } catch (error) {
    console.warn(error);
  }
};

export interface UpdateTransferParams {
  transferId: string;
  updateData: Partial<ITransfer>;
}

export const updateTransfer = async ({
  transferId,
  updateData,
}: UpdateTransferParams) => {
  const response = await AxiosPrivateFirebaseInstance.put<IResponse>(
    `/admin_actions/transfers/${transferId}`,
    updateData
  );

  return response;
};

export const createInternalTransfer = async (
  payload: IInternalTransferToInitiate
) =>
  AxiosPrivateFirebaseInstance.post<IResponse>(
    `/admin_actions/funding/internal-transfer`,
    payload
  );
