import { FC } from "react";
import { Switch } from "react-router-dom";
import PrivateRoutes from "./components/PrivateRoutes/PrivateRoutes";
import { useStoreState } from "state";
import PublicRoutes from "./components/PublicRoutes/PublicRoutes";

const Routes: FC = () => {
  const { userId, isAuthDone } = useStoreState((state) => state.UserState);

  return (
    <Switch>
      {isAuthDone && !userId && <PublicRoutes />}
      {isAuthDone && !!userId && <PrivateRoutes />}
    </Switch>
  );
};

export default Routes;
