let notification: any;

export type PositionTypes = "top";

export const setNotificationRef = (ref: any) => {
  notification = ref;
};

export const open = ({
  message = "Notification text",
  duration = 5000,
  color = "",
  icon = "",
}) => {
  notification?.open({
    message,
    duration,
    color,
    icon,
  });
};

export const success = (message: string) => {
  open({
    message,
    color: "#3AA33A",
    icon: "notification-success-ico",
    duration: 3000,
  });
};

export const error = (message: string) => {
  open({
    message,
    color: "#EC4B4B",
    icon: "notification-error-ico",
    duration: 3000,
  });
};

export const info = (message: string) => {
  open({
    message,
    color: "#1F4EC8",
    icon: "notification-error-ico",
    duration: 3000,
  });
};
