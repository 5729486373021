import { FC, useState, useEffect } from "react";
import { Button, Form, message, Modal, Table, Typography } from "antd";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import ace from "brace";
import "brace/mode/json";
import "brace/theme/github";

import { openQuery, TCustomRouteComponentProps } from "utils";
import "./Countries.css";
import _get from "lodash/get";

import db from "services/firestore";

const subscribeToCountries = (callback) => {
  try {
    const unsubscribe = db
      .collection("referenceData")
      .doc("collections")
      .collection("countries")
      .onSnapshot((query) => callback(openQuery(query)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const { Title } = Typography;

const Countries: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [jsonValue, setJsonValue] = useState({
    alpha2: "",
    alpha3: "",
    currency: "",
    iban: false,
    enabled: false,
    name: "",
  });
  const [isShowAddModal, setIsShowAddModal] = useState(false);
  const [isShowEditModal, setIsShowEditModal] = useState(false);

  useEffect(() => {
    const unsubscribe = subscribeToCountries((data) => setCountries(data));

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const addCountry = async (values) => {
    try {
      await db
        .collection("referenceData")
        .doc("collections")
        .collection("countries")
        .doc(values.alpha2.toUpperCase())
        .set({ ...values });

      message.success("New country was added.");
    } catch (error) {
      console.log(error, "error");
    }
  };

  const updateCountry = async ({ id, ...rest }) => {
    try {
      await db
        .collection("referenceData")
        .doc("collections")
        .collection("countries")
        .doc(id)
        .update(rest);

      message.success("Country was updated.");
    } catch (error) {
      console.log(error, "error");
    }
  };

  const countriesColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Alpha2",
      dataIndex: "alpha2",
      key: "alpha2",
    },
    {
      title: "Alpha3",
      dataIndex: "alpha3",
      key: "alpha3",
    },
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Is IBAN country",
      dataIndex: "iban",
      key: "iban",
      render: (text, record) => {
        return <span>{`${_get(record, "iban", "Not specified")}`}</span>;
      },
    },
    {
      title: "Is enabled?",
      dataIndex: "enabled",
      key: "enabled",
      render: (text, record) => {
        return <span>{`${_get(record, "enabled", "Not specified")}`}</span>;
      },
    },
    ...(allowWrite
      ? [
          {
            title: "",
            dataIndex: "",
            key: "toggleEnabledState",
            render: (record) => (
              <Button
                onClick={() => {
                  setSelectedCountry(record);
                  setIsShowEditModal(true);
                }}
                type="primary"
              >
                Edit
              </Button>
            ),
          },
        ]
      : []),
  ];

  return (
    <div>
      <Title>Countries reference data</Title>

      {allowWrite && (
        <Button
          type="primary"
          style={{
            margin: "16px 0",
          }}
          onClick={() => setIsShowAddModal(!isShowAddModal)}
        >
          + Add country
        </Button>
      )}

      <Table columns={countriesColumns} dataSource={countries} />

      <Modal
        title="Add new country"
        centered
        visible={isShowAddModal}
        onCancel={() => setIsShowAddModal(false)}
        footer={null}
      >
        <Form
          layout="vertical"
          onFinish={async () => {
            await addCountry(jsonValue);
            setIsShowAddModal(false);
          }}
        >
          <Form.Item label="Currency data">
            <Editor
              ace={ace}
              theme="ace/theme/github"
              value={jsonValue}
              mode="code"
              onChange={(data) => {
                setJsonValue(data);
              }}
            />
          </Form.Item>

          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form>
      </Modal>

      <Modal
        title="Edit country"
        centered
        visible={isShowEditModal}
        onCancel={() => {
          setIsShowEditModal(false);
          setSelectedCountry({});
        }}
        footer={null}
      >
        <Form
          layout="vertical"
          onFinish={async () => {
            // @ts-ignore
            await updateCountry(selectedCountry);
            setIsShowEditModal(false);
            setSelectedCountry({});
          }}
        >
          <Form.Item label="Country data">
            <Editor
              ace={ace}
              theme="ace/theme/github"
              value={selectedCountry}
              mode="code"
              onChange={(data) => {
                setSelectedCountry(data);
              }}
            />
          </Form.Item>

          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default Countries;
