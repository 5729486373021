import { FC, useState, useEffect } from "react";
import { Button, message, Typography } from "antd";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import ace from "brace";
import "brace/mode/json";
import "brace/theme/github";

import "./RiskContributions.css";

import db from "services/firestore";
import { TCustomRouteComponentProps } from "utils";

const subscribeToRiskContributions = (callback) => {
  try {
    const unsubscribe = db
      .collection("referenceData")
      .doc("riskContribution")
      .onSnapshot((doc) => callback(doc.data()));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const { Title } = Typography;

const RiskContributions: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [riskContributions, setRiskContributions] = useState(null);

  useEffect(() => {
    const unsubscribe = subscribeToRiskContributions((data) =>
      setRiskContributions(data)
    );

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const updateRiskContributions = async () => {
    try {
      await db
        .collection("referenceData")
        .doc("riskContribution")
        .update(riskContributions);

      message.success("Risk Contributions were updated.");
    } catch (error) {
      message.error(
        `Could not update Risk Contributions. Error: ${error.message}`,
        5
      );
    }
  };

  return riskContributions ? (
    <div>
      <Title>Risk Contributions reference data</Title>

      <Editor
        ace={ace}
        theme="ace/theme/github"
        value={riskContributions}
        mode="code"
        onChange={(value) => {
          if (!value) {
            setRiskContributions({});
            return;
          }
          setRiskContributions(value);
        }}
      />

      {allowWrite && (
        <Button
          style={{
            margin: "16px 0",
          }}
          type="primary"
          onClick={updateRiskContributions}
        >
          Update
        </Button>
      )}
    </div>
  ) : (
    <Title>No Risk Contributions found</Title>
  );
};

export default RiskContributions;
