import { FC, useState, useEffect, useCallback } from "react";
import { message, Table, Tag, Typography, Row, Button } from "antd";
import dayjs from "dayjs";
import { Firebase } from "services";
import { IEntityInvite, IEntityUser } from "types";
import { openQueryWithTimestamp } from "utils";
import db from "services/firestore";

const { Paragraph, Title } = Typography;

const entityUsersAndInvitesColumns = ({
  onSwitchAdminApproval,
  allowWrite,
  isInvites,
  isLoading,
}: {
  onSwitchAdminApproval?: (userId: string) => void;
  allowWrite?: boolean;
  isInvites?: boolean;
  isLoading?: boolean;
}) => [
  ...(isInvites
    ? [
        {
          title: "Created",
          dataIndex: "_created",
          key: "_created",
          render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
        },
      ]
    : []),
  {
    title: "Name",
    key: "name",
    render: (record) => (
      <Paragraph style={{ margin: `0` }}>
        {record.firstName} {record.lastName}
      </Paragraph>
    ),
  },
  {
    title: "Mail",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Roles",
    dataIndex: "roles",
    key: "roles",
    render: (roles) =>
      isInvites
        ? roles.map((role) => <Tag key={role}>{role}</Tag>)
        : roles.map((role) => <Tag key={role}>{role.role}</Tag>),
  },
  ...(isInvites
    ? [
        {
          title: "Admin Approval",
          key: "approved",
          render: (record) => (
            <Row>
              {allowWrite &&
                !!onSwitchAdminApproval &&
                record.status === "accepted" &&
                !record.isApprovedByAdmin && (
                  <Button
                    disabled={isLoading}
                    onClick={() => onSwitchAdminApproval(record.id)}
                  >
                    Approve
                  </Button>
                )}

              {record.status !== "accepted" && (
                <Tag color="orange">Not accepted</Tag>
              )}

              {record.isApprovedByAdmin && <Tag color="green">Approved</Tag>}
            </Row>
          ),
        },
        {
          title: "Approved at",
          dataIndex: "approvedByAdminAt",
          key: "approvedByAdminAt",
          render: (date) => (
            <Paragraph
              style={{
                margin: `0px`,
              }}
            >
              {date
                ? dayjs(date).format("DD MMM YYYY HH:mm:ss")
                : "Not approved yet"}
            </Paragraph>
          ),
        },
      ]
    : []),
];

const subscribeToInvites = (entityId: string, callback) => {
  try {
    const subscribe = db
      .collection("invites")
      .where("entityId", "==", entityId)
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ["_created"]))
      );

    return subscribe;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

const EntityUsersAndInvites: FC<{ entityId: string; allowWrite: boolean }> = ({
  entityId,
  allowWrite,
}) => {
  const [isLoadingEntityUsers, setIsLoadingEntityUsers] = useState(false);
  const [isLoadingSwitchAdminApproval, setIsLoadingSwitchAdminApproval] =
    useState(false);
  const [entityUsers, setEntityUsers] = useState<IEntityUser[]>([]);
  const [invites, setInvites] = useState<IEntityInvite[]>([]);

  useEffect(() => {
    if (entityId) {
      const unsubscribe = subscribeToInvites(entityId, setInvites);

      return () => {
        unsubscribe && unsubscribe();
      };
    }
  }, [entityId]);

  const getEntityUsers = useCallback(async () => {
    try {
      setIsLoadingEntityUsers(true);
      const { data } = await Firebase.getEntityUsers({ entityId });

      if (data.success) {
        setEntityUsers(data.data);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoadingEntityUsers(false);
    }
  }, [entityId]);

  useEffect(() => {
    if (entityId) {
      getEntityUsers();
    }
  }, [entityId, getEntityUsers]);

  const onSwitchAdminApproval = async (inviteId: string) => {
    try {
      setIsLoadingSwitchAdminApproval(true);
      await Firebase.approveInviteAndSetUserRoles(inviteId);
      message.success("User approved");
      getEntityUsers();
    } catch (error) {
      message.error(error.message);
    } finally {
      setIsLoadingSwitchAdminApproval(false);
    }
  };

  return (
    <>
      <Table
        title={() => <Title level={2}>Invites</Title>}
        columns={entityUsersAndInvitesColumns({
          isInvites: true,
          onSwitchAdminApproval,
          allowWrite,
          isLoading: isLoadingEntityUsers || isLoadingSwitchAdminApproval,
        })}
        dataSource={invites}
      />

      <Table
        title={() => <Title level={2}>Entity Users</Title>}
        columns={entityUsersAndInvitesColumns({})}
        dataSource={entityUsers}
      />
    </>
  );
};

export default EntityUsersAndInvites;
