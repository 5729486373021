import { FC, useState, useEffect } from "react";
import { Button, Form, message, Modal, Table, Typography } from "antd";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import ace from "brace";
import "brace/mode/json";
import "brace/theme/github";

import "./ApplicationVersion.css";

import db from "services/firestore";
import { TCustomRouteComponentProps } from "utils";

const subscribeToApplicationVersionDocument = (callback) => {
  try {
    const unsubscribe = db
      .collection("referenceData")
      .doc("applications")
      .onSnapshot((doc) => {
        const docData = doc.data();

        const docDataResult = {};

        for (const key in docData) {
          const value = docData[key];

          docDataResult[key] = { ...value, lastRun: value.lastRun?.toDate() };
        }

        callback(docDataResult);
      });

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const { Title } = Typography;

const ApplicationVersion: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [applicationVersions, setApplicationVersions] = useState(null);
  const [isShowAddModal, setIsShowAddModal] = useState(false);
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
  const [jsonValue, setJsonValue] = useState<any>({
    applicationName: "",
    version: "",
  });

  useEffect(() => {
    const unsubscribe = subscribeToApplicationVersionDocument((data) => {
      const parsedData = Object.entries(data).map(([key, value]) => ({
        id: key,
        // @ts-ignore
        ...value,
      }));
      const parsedDataSortedById = parsedData.sort((a, b) =>
        a.id > b.id ? 1 : -1
      );

      setApplicationVersions(parsedDataSortedById);
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const addOrUpdateApplicationVersion = async ({ applicationName, version, usdMultiplier }) => {
    try {
      const updateDoc: {
        [key: string]: { version: string };
      } = {
        [applicationName]: { version },
      };
      await db
        .collection("referenceData")
        .doc("applications")
        .update(updateDoc);

      message.success("New or updated application version added successfully");
    } catch (error) {
      console.log(error, "error");
    }
  };

  const applicationVersionColumns = [
    {
      title: "App Name",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "Edit Version",
      render: (text, record) => {
        return (
          <Button style={{marginLeft: "10px"}} onClick={() => {
            setJsonValue({
              applicationName: record.id,
              version: record.version,
            });
            setIsShowAddModal(true)}
          }
          >Edit</Button>

        );
      },
    }
  ];

  return (
    <div>
      <Title>Application Version reference data</Title>

      {allowWrite && (
        <>
          <Button
            type="primary"
            style={{
              margin: "16px 0",
            }}
            onClick={() => {setIsShowAddModal(!isShowAddModal)}}
          >
            + Add application version
          </Button>
        </>
      )}

      <Table columns={applicationVersionColumns} dataSource={applicationVersions} />

      <Modal
        title="Add or update application version"
        centered
        visible={isShowAddModal}
        onCancel={() => setIsShowAddModal(false)}
        footer={null}
      >
        <Form
          layout="vertical"
          onFinish={async () => {
            if (!jsonValue.error) {
              setIsShowConfirmModal(true);
            }
          }}
        >
          <Form.Item label="Application version data">
            <Editor
              ace={ace}
              theme="ace/theme/github"
              value={jsonValue}
              mode="code"
              onChange={(data) => {
                setJsonValue(data);
              }}
            />
          </Form.Item>

          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form>
      </Modal>

      <Modal
        title="Confirm"
        centered
        visible={isShowConfirmModal}
        onCancel={() => setIsShowConfirmModal(false)}
        footer={null}
      >
        <Form
          layout="vertical"
          onFinish={async () => {
            await addOrUpdateApplicationVersion(jsonValue);
            setIsShowAddModal(false);
            setIsShowConfirmModal(false);
          }}
        >
          <Button htmlType="submit" type="primary">
            Are you sure?
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default ApplicationVersion;
