import { FC, useState, useEffect } from "react";
import { Button, Table, Typography } from "antd";

import dayjs from "dayjs";
import "./Ledger.css";
import {
  toCurrencyNumber,
  openDoc,
  openDocWithTimestamp,
  openQueryWithTimestamp,
  TCustomRouteComponentProps,
} from "utils";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import db from "services/firestore";
import {
  IEntity,
  ILedgerSummaryDoc,
  ILedgerTransactionDoc,
} from "types";
import EditLedgerTransaction from "./components/EditLedgerTransaction";
import DocViewer from "pages/Overview/components/DocViewer";
import CreateLedgerTransaction from "./components/CreateLedgerTransaction";

const subscribeToLedger = (ledgerId, callback) => {
  try {
    const unsubscribe = db
      .collection("ledgerSummaries")
      .doc(ledgerId)
      .onSnapshot((doc) =>
        callback(openDocWithTimestamp(doc, ["_created", "_updated"]))
      );
    return unsubscribe;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

const subscribeToLedgerTransactions = (ledgerId, callback) => {
  try {
    const unsubscribe = db
      .collection("ledgerTransactions")
      .where("ledger", "==", ledgerId)
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ["_created", "_updated"]))
      );

    return unsubscribe;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

const subscribeToEntity = (entityId, callback) => {
  try {
    const unsubscribe = db
      .collection("entities")
      .doc(entityId)
      .onSnapshot((doc) => callback(openDoc(doc)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

const { Title } = Typography;

const Ledger: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [entity, setEntity] = useState(null as IEntity);
  const [ledger, setLedger] = useState(null as ILedgerSummaryDoc);
  const [ledgerTransactions, setLedgerTransactions] = useState<ILedgerTransactionDoc[]>([]);
  const [selectedLedgerTransactions, setSelectedLedgerTransactions] = useState<ILedgerTransactionDoc | null>(null);
  const [modifyLedgerTransaction, setModifyLedgerTransaction] = useState(false);
  const [docToView, setDocToView] = useState(null);
  const [accountsAndValues, setAccountsAndValues] = useState<{ account: string, [key: string]: any }[]>([]);
  const [ledgerColumns, setLedgerColumns] = useState([]);
  const [onboardingToCreate, setOnboardingToCreate] = useState(null);

  // @ts-ignore
  const { ledger_id } = useParams<{ledger_id: string}>();

  const getLedgerColumns = (data: any[]) => {
    const LedgerColumns = [];

    if (data.length > 0) {
      data.forEach((item) => {
        Object.keys(item).forEach((key) => {
          if (!LedgerColumns.find((column) => column.key === key)) {
            if (key === 'account') {
              LedgerColumns.push({
                title: 'Account',
                dataIndex: key,
                key: key,
              });
            } else {
              LedgerColumns.push({
                title: key,
                dataIndex: key,
                key: key,
                render: (text) => <span style={{paddingLeft: "40px"}}>{toCurrencyNumber(text/100, 2)}</span>,
                align: 'right',
              });
            }
          }
        });
      });
    }

    return LedgerColumns;
  };

  const getLedgerTransactionColumns = (setDocToView) => {
    const LedgerTransactionColumns = [
      {
        title: "Created",
        dataIndex: "_created",
        key: "_created",
        render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
      },
      {
        title: "Postings",
        dataIndex: "postings",
        key: "postings",
        render: (text, record: ILedgerTransactionDoc) => {
          return (
            <>
              {record.postings?.map((item) => {
                return (
                  <div className="currencyAmount">
                    {`${item.from}->${item.to}`} &nbsp;
                    <b>{item.currency}</b> &nbsp;
                    {toCurrencyNumber(item.amount)}
                  </div>
                );
              })}
            </>
          );
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text, record) => {
          return <>{record.status}</>;
        },
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        render: (text, record) => {
          return (
            <>{text}</>
          );
        },
      },
      {
        title: "Source ID",
        dataIndex: "sourceId",
        key: "sourceId",
        render: (text, record: ILedgerTransactionDoc) => {
          const sourceText = `${record.source} ${record.sourceEvent} (${text})`;
          return (
            <span>
              <Link to={"/app/transfer/" + record.sourceId}>
                {sourceText}
              </Link>
            </span>
          );
        },
      },
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        render: (text, record) => {
          return (
            <span>
              <Link to="#" onClick={() => setDocToView(record)}>
                {text}
              </Link>
            </span>
          );
        },
      },
      ...(allowWrite
        ? [
            {
              title: "Actions",
              key: "actions",
              render: (_, record: ILedgerTransactionDoc) => {
                return (
                  <span>
                   <Button
                      type="primary"
                      style={{ float: "right" }}
                      onClick={() => {
                        setSelectedLedgerTransactions(record);
                        setModifyLedgerTransaction(true);
                      }}
                    >
                      Modify
                    </Button>
                  </span>
                );
              },
            },
          ]
        : []),
    ];

    return LedgerTransactionColumns;
  };

  useEffect(() => {
    if (ledger_id) {
      const unsubscribeLedger = subscribeToLedger(ledger_id, (data) =>
        setLedger(data)
      );

      const unsubscribeLedgerTransactions = subscribeToLedgerTransactions(ledger_id, (data) =>
        setLedgerTransactions(data)
      );

      return () => {
        unsubscribeLedger?.();
        unsubscribeLedgerTransactions?.();
      };
    }
  }, [ledger_id]);

  useEffect(() => {
    if (ledger_id && !entity) {
      const unsubscribe = subscribeToEntity(ledger_id, (data) =>
        setEntity(data)
      );

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [ledger_id, entity]);

  useEffect(() => {
    const accountsAndValuesTemp: { account: string, [key: string]: any }[] = [];
    if (ledger) {
      Object.keys(ledger).forEach((key) => {
        if (!key.startsWith('_') && key !== 'id') {
          const colonIndex = key.lastIndexOf(':');
          const currency = key.substring(colonIndex + 1);
          const accountName = key.substring(0, colonIndex);
          const amount = ledger[key];
          const accountsObjectFromArray = accountsAndValuesTemp.find((account) => account.account === accountName);
          if (accountsObjectFromArray) {
            accountsObjectFromArray[currency] = amount;
          } else {
            accountsAndValuesTemp.push({ account: accountName, [currency]: amount });
          }
        }
      })

      // Sort accountsObjectFromArray by account name
      accountsAndValuesTemp.sort((a, b) => {
        if (a.account < b.account) {
          return -1;
        }
        if (a.account > b.account) {
          return 1;
        }
        return 0;
      });

      setAccountsAndValues(accountsAndValuesTemp);
      setLedgerColumns(getLedgerColumns(accountsAndValuesTemp));
    }
  }, [ledger]);

  return (
    <div>
      <>
        <CreateLedgerTransaction
          entityId={ledger_id}
          onClose={() => setOnboardingToCreate(null)}
          isVisible={!!onboardingToCreate}
        />
        <EditLedgerTransaction
          ledgerTransaction={selectedLedgerTransactions}
          isVisible={modifyLedgerTransaction}
          onClose={() => setModifyLedgerTransaction(false)}
        />
        <DocViewer
          doc={docToView}
          isVisible={!!docToView}
          onClose={() => setDocToView(null)}
        />
      </>
      <>
        <Title>Ledger Details</Title>
        {!ledger && 
          <Button
            type="primary"
            style={{ margin: "8px 0 0 0", float: "right" }}
            onClick={() => setOnboardingToCreate({})}
          >
            Add a Ledger Transaction
          </Button>
        }
        {ledger && (
          <>
            <p>
              {entity && (
                <>
                  For{" "}
                  <Link to={"/app/entity-detail/" + entity.id}>{entity.name}</Link>
                </>
              )}
            </p>

            <div style={{display:"flex", flexDirection:"row"}}>

            <Table
              columns={ledgerColumns}
              dataSource={accountsAndValues}
              pagination={false}
              size="small"
              />
            </div>

            <Table
              title={() => <Title level={2}>
                Ledger Transactions
                <Button
                  type="primary"
                  style={{ margin: "8px 0 0 0", float: "right" }}
                  onClick={() => setOnboardingToCreate({})}
                >
                  Add a Ledger Transaction
                </Button>
                </Title>}
              columns={getLedgerTransactionColumns(setDocToView)}
              dataSource={ledgerTransactions}
            />

          </>
        )}

      </>
    </div>
  );
};

export default Ledger;
