import { FC, useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, message, Row, Typography, Select } from "antd";
import { JsonEditor as Editor } from "jsoneditor-react";
import db from "services/firestore";
import "jsoneditor-react/es/editor.min.css";
import ace from "brace";
import "brace/mode/json";
import "brace/theme/github";

import "./AssetView.css";
import Input from "antd/lib/input/Input";
import { openDoc, openQuery } from "utils";
import { getExternalInvoice, IIntegrationBase } from "services/firebase";
import { AxiosPrivateFirebaseInstance } from "settings";
import { Link } from "react-router-dom";
import { delay } from "lodash";

const { Title, Paragraph } = Typography;
const { Option } = Select;

interface IGetAssetParams {
  searchField: string;
  searchValue: string;
  assetType: string;
}

interface IGetAssetHistoryParams {
  id: string;
  assetType: string;
}

interface IAssetSearchParams {
  [key: string]: string;
}

const ASSETS = {
  invoices: {
    name: "Invoice",
    searchFields: ["id", "invoiceNumber", "externalRefs.sourceSystemId"],
    sourceGet: true
  },
  transfers: {
    name: "Transfer",
    innerPage: "transfer",
    searchFields: ["id"],
  },
  bulkPayments: {
    name: "Bulk transfer",
    innerPage: "bulk-payment",
    searchFields: ["id"],
  },
  conversions: {
    name: "Conversion",
    searchFields: ["id"],
  },
  rateContracts: {
    name: "Prebook",
    innerPage: "contract",
    searchFields: ["id"],
  },
  recipients: {
    name: "Recipient",
    innerPage: "recipient-detail",
    searchFields: ["id", "externalRefs.sourceSystemId"],
  },
  entities: {
    name: "Entity",
    innerPage: "entity-detail",
    searchFields: ["id"],
  },
  integrationSettings: {
    name: "Integration Settings",
    innerPage: "integration-detail",
    searchFields: ["id"],
  },
  kafkaLogsFlat: {
    name: "Kafka Logs flat",
    searchFields: ["id"],
  },
  writeEvents: {
    name: "Write Events",
    searchFields: ["id"],
  },
};

export const getIntegrationSettings = async (entityId: string) => {
  const integrations: IIntegrationBase[] =
    await AxiosPrivateFirebaseInstance.get(
      `/admin_actions/integrations/${entityId}/settings`
    )
      .then((res: any) => {
        if (res.data.success) {
          return {
            ...res.data.data,
            id: entityId,
          } as any;
        } else {
          message.error(res.message);
          return [];
        }
      })
      .catch((err) => {
        console.log(err);
        message.error(err.message);
        return [];
      });

  return integrations;
};

export const getAssetById = async ({
  searchField,
  searchValue,
  assetType,
}: IGetAssetParams) => {
  if (assetType === "integrationSettings") {
    return getIntegrationSettings(searchValue);
  }

  if (searchField === "id") {
    return db
      .collection(assetType)
      .doc(searchValue)
      .get()
      .then((doc) => openDoc(doc));
  }

  return db
    .collection(assetType)
    .where(searchField, "==", searchValue)
    .get()
    .then((doc) => openQuery(doc));
};

export const getAssetHistoryById = async ({
  id,
  assetType,
}: IGetAssetHistoryParams) =>
  db
    .collection(assetType)
    .doc(id)
    .collection("history")
    .get()
    .then((doc) => openQuery(doc));

const AssetView: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [asset, setAsset] = useState(null);
  const [assetHistory, setAssetHistory] = useState<any[] | null>(null);
  const [assetType, setAssetType] = useState(null);
  const [searchValue, setSearchValue] = useState<IAssetSearchParams>({});
  const [searchField, setSearchField] = useState("");
  const [activeAssetHistory, setActiveAssetHistory] = useState(null);
  const [activeAssetToShowId, setActiveAssetToShowId] = useState(null);
  const isAssetIsArray = Array.isArray(asset);
  const [externalAsset, setExternalAsset] = useState<any>(null);

  const getExternalInvoiceWrapped = async (
    {entityId, sourceSystemId} :
    {entityId: string, sourceSystemId: string}
  ) => {
    const response = await getExternalInvoice({entityId, sourceSystemId});
    setExternalAsset(response.data.data)
  }

  const resetFields = () => {
    setAsset(null);
    setActiveAssetToShowId(null);
    setAssetHistory(null);
    setActiveAssetHistory(null);
  };

  const onSubmit = async (field: string) => {
    if (!assetType) {
      message.warning("Select Asset type");
    }
    if (!searchValue[field]) {
      message.warning("Add search value");
    }

    setSearchField(field);

    try {
      setIsLoading(true);

      const assetFromDB = await getAssetById({
        searchField: field,
        searchValue: searchValue[field],
        assetType,
      });

      if (
        (assetFromDB && !Array.isArray(assetFromDB)) ||
        (Array.isArray(assetFromDB) && assetFromDB.length > 0)
      ) {
        setAsset(assetFromDB);
        setAssetHistory(null);
        setActiveAssetHistory(null);

        if (Array.isArray(assetFromDB) && assetType !== "integrationSettings") {
          const defaultItemToShowId = assetFromDB[0]?.id;

          if (defaultItemToShowId) {
            setActiveAssetToShowId(defaultItemToShowId);
          }
        }
      } else {
        resetFields();
        message.error(`No data found for ${field}: ${searchValue[field]}`);
      }

      if (
        assetFromDB &&
        !Array.isArray(assetFromDB) &&
        assetType !== "integrationSettings"
      ) {
        const assetHistoryFromDB = await getAssetHistoryById({
          // @ts-ignore
          id: assetFromDB.id,
          assetType,
        });

        if (assetHistoryFromDB.length) {
          setAssetHistory(assetHistoryFromDB);
        }
      }
    } catch (error) {
      resetFields();
      message.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (activeAssetToShowId && assetType !== "integrationSettings") {
      const getActiveAssetHistory = async () => {
        try {
          setIsLoading(true);
          const assetHistoryFromDB = await getAssetHistoryById({
            id: activeAssetToShowId,
            assetType,
          });

          if (assetHistoryFromDB.length) {
            setAssetHistory(assetHistoryFromDB);
            const defaultHistoryItemId = assetHistoryFromDB[0]?.id;

            if (defaultHistoryItemId) {
              setActiveAssetHistory(defaultHistoryItemId);
            }
          }
        } catch (error) {
          message.error(error.message);
        } finally {
          setIsLoading(false);
        }
      };

      getActiveAssetHistory();
    }
  }, [activeAssetToShowId, assetType]);

  const onSetAssetType = (value) => {
    setAssetType(value);
    setSearchValue({});
    setSearchField("");
    resetFields();
  };

  return (
    <div>
      <Title>Asset view</Title>
      <Row
        align="middle"
        style={{
          marginBottom: 16,
        }}
      >
        <Select
          style={{
            width: 180,
          }}
          placeholder="Asset type"
          onChange={(value) => onSetAssetType(value)}
        >
          {Object.keys(ASSETS).map((key) => (
            <Option key={key} value={key}>
              {ASSETS[key].name}
            </Option>
          ))}
        </Select>

        {assetType &&
          ASSETS[assetType].searchFields.map((field) => (
            <Row align="middle" key={field}>
              <Input
                placeholder={field}
                value={searchValue[field]}
                onChange={(e) =>
                  setSearchValue((prev) => ({
                    ...prev,
                    [field]: e.target.value,
                  }))
                }
                style={{ width: 240, margin: "0 12px" }}
              />
              <Button
                type="primary"
                onClick={() => {
                  onSubmit(field);
                }}
                style={{ width: 90 }}
                icon={<SearchOutlined />}
              >
                Search
              </Button>
            </Row>
          ))}
          </Row>
          <Row>
        {assetType === 'invoices' &&
          ASSETS[assetType].sourceGet && asset?.externalRefs?.sourceSystemId && asset._owner &&
          (
            <Button
              type="primary"
              onClick={() => {
                getExternalInvoiceWrapped({
                  entityId: asset._owner,
                  sourceSystemId: asset.externalRefs.sourceSystemId
                })
              }}
              style={{ marginLeft: 12 }}
              icon={<SearchOutlined />}
            >
              Get Source Asset
            </Button>
            )}
            </Row>

      {isLoading && <Paragraph>Loading...</Paragraph>}
      {!isLoading && (
        <Row justify="space-between">
          <div style={{ width: "70%" }}>
            {asset && (
              <>
                <Row
                  style={{
                    marginBottom: 24,
                  }}
                >
                  <Title level={5} style={{ margin: 0 }}>
                    {ASSETS[assetType].name} {searchField}:
                  </Title>

                  <Title style={{ margin: "0 0 0 12px" }} level={5}>
                    {searchValue[searchField]}
                  </Title>
                </Row>

                {isAssetIsArray && (
                  <>
                    <Select
                      style={{
                        width: "100%",
                        margin: "0 0 16px",
                      }}
                      value={activeAssetToShowId}
                      placeholder="Select item"
                      onChange={async (value) => {
                        setActiveAssetToShowId(null);
                        setAssetHistory(null);
                        setActiveAssetHistory(null);
                        // It is for making sure the value is set to null, so json editor remounts with new value after the delay.
                        // Otherwise react will not set it to null and json editor will stay unchanged.
                        delay(() => {
                          setActiveAssetToShowId(value);
                        }, 100);
                      }}
                    >
                      {asset.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.id}
                        </Option>
                      ))}
                    </Select>
                    {!activeAssetToShowId && (
                      <Row justify="center">
                        <Paragraph>
                          Select {ASSETS[assetType].name} item
                        </Paragraph>
                      </Row>
                    )}
                    {activeAssetToShowId && (
                      <Editor
                        ace={ace}
                        theme="ace/theme/github"
                        value={asset.find(
                          (item) => item.id === activeAssetToShowId
                        )}
                        mode="code"
                      />
                    )}
                  </>
                )}

                {!isAssetIsArray && (
                  <Editor
                    ace={ace}
                    theme="ace/theme/github"
                    value={asset}
                    mode="code"
                  />
                )}
              </>
            )}
          </div>
          {assetType !== "integrationSettings" && (
            <div style={{ width: "28%" }}>
              {asset && !assetHistory && (
                <Row justify="center">
                  <Title style={{ margin: 0 }} level={5}>
                    No history available
                  </Title>
                </Row>
              )}
              {assetHistory && (
                <>
                  <Row
                    style={{
                      marginBottom: 24,
                    }}
                  >
                    <Title level={5} style={{ margin: 0 }}>
                      History:
                    </Title>
                  </Row>
                  <Select
                    style={{
                      width: "100%",
                      margin: "0 0 16px",
                    }}
                    value={activeAssetHistory}
                    placeholder="Select history item"
                    onChange={async (value) => {
                      setActiveAssetHistory(null);

                      // It is for making sure the value is set to null, so json editor remounts with new value after the delay.
                      // Otherwise react will not set it to null and json editor will stay unchanged.
                      delay(() => {
                        setActiveAssetHistory(value);
                      }, 100);
                    }}
                  >
                    {assetHistory.map((historyItem) => (
                      <Option key={historyItem.id} value={historyItem.id}>
                        {historyItem.id}
                      </Option>
                    ))}
                  </Select>
                  {!activeAssetHistory && (
                    <Row justify="center">
                      <Paragraph>
                        Select {ASSETS[assetType].name} history item
                      </Paragraph>
                    </Row>
                  )}
                  {activeAssetHistory && (
                    <Editor
                      ace={ace}
                      theme="ace/theme/github"
                      value={assetHistory.find(
                        (item) => item.id === activeAssetHistory
                      )}
                      mode="code"
                    />
                  )}
                </>
              )}
            </div>
          )}
        </Row>
      )}

      {asset &&
        (!isAssetIsArray || (isAssetIsArray && activeAssetToShowId)) &&
        !!ASSETS[assetType].innerPage && (
          <Row
            style={{
              marginTop: 16,
            }}
          >
            <Link to={`/app/${ASSETS[assetType].innerPage}/${asset.id}`}>
              {ASSETS[assetType].name} details:{" "}
              {isAssetIsArray && assetType !== "integrationSettings"
                ? activeAssetToShowId
                : asset.id}
            </Link>
          </Row>
        )}

        {externalAsset && (
          <Editor
            ace={ace}
            theme="ace/theme/github"
            value={externalAsset}
            mode="code"
          />
        )}
    </div>
  );
};

export default AssetView;
