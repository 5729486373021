import { IContract, IResponse } from "types";
import { Notify, openQueryWithTimestamp } from "utils";
import { AxiosPrivateFirebaseInstance } from "settings";

import db from "services/firestore";

export interface CreateRateContractPayload {
  sellAmount: number;
  sellCurrency: string;
  buyAmount: number;
  buyCurrency: string;
  rate: number; //1.2345
  expiryDate: string; //YYYY-MM-DD

  depositRate: number; //0.05 for 5%
  feeRate: number; //0.0089 for 0.89%

  depositAmount: number;
  feeAmount: number;
  prepaymentAmount: number; //depositAmount + feeAmount
  balanceAmount: number; //sellAmount - depositAmount
  totalAmount: number; //sellAmount + feeAmount

  bookingName?: string;
}

export const createRateContract = async (
  payload: CreateRateContractPayload
): Promise<IResponse | undefined> => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.post<IResponse>(
      `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/contracts`,
      payload
    );

    if (data.success) {
      return data;
    } else {
      // @ts-ignore
      Notify.error(data.message);
    }
  } catch (error) {
    console.warn(error);
  }
};

export interface SubscribeToRateContractsParams {
  callback: (rateContracts: IContract[]) => void;
}

export const subscribeToRateContracts = ({
  callback,
}: SubscribeToRateContractsParams) => {
  try {
    return db
      .collection("rateContracts")
      .orderBy("_created", "desc")
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ["_created"]))
      );
  } catch (error) {
    console.warn(error);
  }
};

export interface GetFeesParams {
  currencyPair: string;
  date: string;
}

export const getFees = async ({ currencyPair, date }: GetFeesParams) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.get<IResponse>(
      `/rates/${currencyPair}/fees/${date}`
    );

    return data;
    // TODO: discuss response structure
    // if (data.success) {
    //   return data;
    // } else {
    //   console.warn(data.error);
    // }
  } catch (error) {
    console.warn(error);
  }
};

export interface UpdateContractParams {
  contractId: string;
  updateData: Partial<IContract>;
}

export const updateContract = async ({
  contractId,
  updateData,
}: UpdateContractParams) => {
  const response = await AxiosPrivateFirebaseInstance.put<IResponse>(
    `/admin_actions/contracts/${contractId}`,
    updateData
  );

  return response;
};
