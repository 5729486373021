import { FC, useState, useEffect, useRef } from "react";
import {
  Tag,
  Typography,
  Input,
  Button,
  Descriptions,
  Badge,
  message,
  Row,
  Table,
} from "antd";
import firebase from "firebase/app";
import axios from "axios";
import { Firebase } from "services";
import { Link } from "react-router-dom";
import { useStoreState } from "state";

import "./RecipientDetails.css";
import {
  toCurrencyNumber,
  asyncForEach,
  getUniqueDecreasingNumber,
  openDoc,
  openQuery,
  openQueryWithTimestamp,
  TCustomRouteComponentProps,
} from "utils";
import { useParams } from "react-router";

import { UploadOutlined } from "@ant-design/icons";
import { removeRecipientFile, uploadRecipientFile } from "services/firebase";

import db from "services/firestore";
import { ITransfer, IRecipient, RECIPIENT_STATUS, ISanctionResult, isSanctionSearchDoc, isSanctionUpdateDoc } from "types";
import dayjs from "dayjs";
import EditOnboardingRecord from "./components/EditOnboardingRecord";

const subscribeToEntity = (entityId, callback) => {
  const unsubscribe = db
    .collection("entities")
    .doc(entityId)
    .onSnapshot((doc) => callback(openDoc(doc)));

  return unsubscribe;
};

const subscribeToRecipient = (
  recipientId: string,
  callback: (data: any) => void
) => {
  const unsubscribe = db
    .collection("recipients")
    .doc(recipientId)
    .onSnapshot((doc) => callback(openDoc(doc)));

  return unsubscribe;
};

const subscribeToRecipientFiles = (
  recipientId: string,
  callback: (data: any) => void
) => {
  const unsubscribe = db
    .collection("recipients")
    .doc(recipientId)
    .collection("onboardingFiles")
    .onSnapshot((query) => callback(openQuery(query)));

  return unsubscribe;
};

const subscribeToRecipientOnboarding = (
  recipientId: string,
  callback: (data: any) => void
) => {
  const unsubscribe = db
    .collection("recipients")
    .doc(recipientId)
    .collection("onboarding")
    .where(firebase.firestore.FieldPath.documentId(), "==", "onboardingReview")
    .onSnapshot((query) => callback(openQuery(query)));

  return unsubscribe;
};

const subscribeToTransfers = (
  recipientId: string,
  callback: (data: any) => void
) => {
  try {
    const unsubscribe = db
      .collection("transfers")
      .where("recipientId", "==", recipientId)
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ["_created"]))
      );

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToSanctionCheck = (
  recipientId: string,
  callback: (data: any) => void
) => {
  try {
    const unsubscribe = db
      .collection("recipients")
      .doc(recipientId)
      .collection("onboarding")
      .where(
        firebase.firestore.FieldPath.documentId(),
        "!=",
        "onboardingReview"
      )
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ["_created"]))
      );

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const transfersColumns = [
  {
    title: "Created",
    dataIndex: "_created",
    key: "_created",
    render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
  },
  {
    title: "Sell",
    dataIndex: "sell",
    key: "sell",
    render: (text, record: ITransfer) => {
      return (
        <div className="currencyAmount">
          {toCurrencyNumber(record.sellAmount)}&nbsp;
          <b>{record.sellCurrency}</b>
        </div>
      );
    },
  },
  {
    title: "Buy",
    dataIndex: "buy",
    key: "buy",
    render: (text, record: ITransfer) => {
      return (
        <div className="currencyAmount">
          {toCurrencyNumber(record.buyAmount)} &nbsp;<b>{record.buyCurrency}</b>
        </div>
      );
    },
  },
  {
    title: "Funding required",
    dataIndex: "payAmount",
    key: "payAmount",
    render: (text, record: ITransfer) => {
      return (
        <div className="currencyAmount">
          {toCurrencyNumber(record.payAmount)} &nbsp;
          <b>{record.sellCurrency}</b>
        </div>
      );
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (text, record) => {
      return (
        <span>
          <Link to={"/app/transfer/" + record.id}>{text}</Link>
        </span>
      );
    },
  },
];

const sanctionChecksColumns = (setDocToView, allowWrite) => [
  {
    title: "Created",
    dataIndex: "_created",
    key: "_created",
    render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
  },
  {
    title: "Type of check",
    render: (text, record) =>
      record.id.includes("CAB") ? "Bank Name" : "Recipient",
  },
  {
    title: "Search term",
    render: (text, record) => record.result?.search_term || "",
  },
  {
    title: "Has hits",
    dataIndex: "hasHits",
    key: "hasHits",
    render: (text, record) => (text ? `Yes (${record.numberOfHits})` : "No"),
  },
  {
    title: "Match status",
    dataIndex: "matchStatus",
    key: "matchStatus",
  },
  {
    title: "Success",
    dataIndex: "success",
    key: "success",
    render: (text, record) => (text ? "Yes" : "No"),
  },
  {
    title: "Clear",
    dataIndex: "clear",
    key: "clear",
    render: (isClear: boolean, record) =>
      isClear ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>,
  },
  {
    title: "Resolved",
    dataIndex: "resolved",
    key: "resolved",
    render: (isResolved: boolean, record) => {
      if (record.clear) {
        return <>n/a</>
      } else {
        return isResolved ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>;
      }
    }
  },
  {
    title: "Comments",
    dataIndex: "comments",
    key: "comments",
  },
  ...(allowWrite
    ? [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          render: (text, record) => {
            return (
              <span>
                <Link to="#" onClick={() => setDocToView(record)}>
                  {text}
                </Link>
              </span>
            );
          },
        },
      ]
    : []),
];

const sanctionChecksUpdatesColumns = (setDocToView, allowWrite) => [
  {
    title: "Update received on",
    dataIndex: "_created",
    key: "_created",
    render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
  },
  {
    title: "Summary",
    render: (text, record) => `New (${record.new?.length}) - Updates (${record.updated?.length}) - Removed (${record.removed?.length})`,
  },
  {
    title: "Comments",
    dataIndex: "comments",
    key: "comments",
  },
  ...(allowWrite
    ? [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          render: (text, record) => {
            return (
              <span>
                <Link to="#" onClick={() => setDocToView(record)}>
                  {text}
                </Link>
              </span>
            );
          },
        },
      ]
    : []),
];

const { Title } = Typography;
const { TextArea } = Input;

const RecipientDetails: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [entity, setEntity] = useState(null);
  const [recipient, setRecipient] = useState<IRecipient | null>(null);
  const [sanctionChecks, setSanctionCheck] = useState<ISanctionResult[]>([]);
  const [sanctionChecksUpdates, setSanctionCheckUpdates] = useState<ISanctionResult[]>([]);
  const { user } = useStoreState((state) => state.UserState);
  const [isLoading, setIsLoading] = useState(false);
  const [isFilesUploading, setIsFilesUploading] = useState(false);
  const [recipientFiles, setRecipientFiles] = useState([]);
  const fileInputRef = useRef<HTMLInputElement>();
  const [commentText, setCommentText] = useState("");
  const [transfers, setTransfers] = useState([]);
  const [docToView, setDocToView] = useState(null);

  // @ts-ignore
  const { recipient_id } = useParams();

  useEffect(() => {
    let unsubscribeFromRecipient = null;
    let unsubscribeFromSanctionCheck = null;
    let unsubscribeFromRecipientOnboarding = null;
    let unsubscribeTransfers = null;

    if (recipient_id) {
      unsubscribeFromRecipient = subscribeToRecipient(recipient_id, (data) =>
        setRecipient(data)
      );
      unsubscribeFromSanctionCheck = subscribeToSanctionCheck(
        recipient_id,
        (data) => {
          setSanctionCheck(data.filter((check) => isSanctionSearchDoc(check)));
          setSanctionCheckUpdates(data.filter((check) => isSanctionUpdateDoc(check)));

        }
      );
      unsubscribeFromRecipientOnboarding = subscribeToRecipientOnboarding(
        recipient_id,
        (data) => setCommentText(data[0]?.comments || "")
      );
      unsubscribeTransfers = subscribeToTransfers(recipient_id, (data) =>
        setTransfers(data)
      );

      db.collection("recipients").doc(recipient_id).get();
    }

    return () => {
      unsubscribeFromRecipient && unsubscribeFromRecipient();
      unsubscribeFromSanctionCheck && unsubscribeFromSanctionCheck();
      unsubscribeFromRecipientOnboarding && unsubscribeFromRecipientOnboarding();
      unsubscribeTransfers && unsubscribeTransfers();
    };
  }, [recipient_id]);

  useEffect(() => {
    let unsubscribeFromEntity = null;
    let unsubscribeFromUser = null;
    let unsubscribeFromRecipientFiles = null;

    if (recipient) {
      unsubscribeFromEntity = subscribeToEntity(recipient._owner, (data) =>
        setEntity(data)
      );
      unsubscribeFromRecipientFiles = subscribeToRecipientFiles(
        recipient.id,
        (files) => setRecipientFiles(files)
      );
    }

    return () => {
      unsubscribeFromEntity && unsubscribeFromEntity();
      unsubscribeFromUser && unsubscribeFromUser();
      unsubscribeFromRecipientFiles && unsubscribeFromRecipientFiles();
    };
  }, [recipient]);

  const approveRecipient = async (recipientId: string) => {
    const callApproveRecipientService = async (recipientId: string) => {
      const token = await Firebase.auth.currentUser.getIdToken();
      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      return axios.post(
        `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/admin_actions/recipients/${recipientId}/approve`,
        null,
        headers
      );
    };

    try {
      setIsLoading(true);

      const doAnySanctionChecksRequireReview = sanctionChecks.some(check => isSanctionSearchDoc(check) && check.resolved === false);
      if (doAnySanctionChecksRequireReview) {
        message.error("Cannot approve recipient as there are unresolved sanction checks");
        return;
      }

      const svcResponse: any = await callApproveRecipientService(
        recipientId
      ).then((res) => res.data);

      if (svcResponse.success) {
        message.success("Approved recipient");
      } else {
        message.error(`Failed to approve recipient: ${svcResponse.message}`);
      }
    } catch (error) {
      message.error("Failed to approve recipient");
    } finally {
      setIsLoading(false);
    }
  };

  const rejectRecipient = async (recipientId: string) => {
    const callRejectRecipientService = async (recipientId: string) => {
      const token = await Firebase.auth.currentUser.getIdToken();
      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      return axios.post(
        `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/admin_actions/recipients/${recipientId}/reject`,
        null,
        headers
      );
    };

    try {
      setIsLoading(true);

      const svcResponse = await callRejectRecipientService(recipientId).then(
        (res) => res.data
      );

      if (svcResponse.success) {
        message.success("Rejected recipient");
      } else {
        message.error(`Failed to reject recipient: ${svcResponse.message}`);
      }
    } catch (error) {
      message.error("Failed to reject recipient");
    } finally {
      setIsLoading(false);
    }
  };

  const onAddFile = async (file) => {
    try {
      const downloadURL = await uploadRecipientFile({
        entityId: entity.id,
        recipientId: recipient_id,
        fileToUpload: file,
      });

      const fileDetails = {
        id: getUniqueDecreasingNumber(),
        name: file.name,
        location: downloadURL,
        linkedTo: `onboarding/${entity.id}/recipients/${recipient_id}`,
      };

      await db
        .collection("recipients")
        .doc(recipient_id)
        .collection("onboardingFiles")
        .doc(getUniqueDecreasingNumber())
        .set(fileDetails);
    } catch (error) {}
  };

  const onRemoveFile = async (fileDetails) => {
    try {
      await removeRecipientFile({
        storagePath: `${fileDetails.linkedTo}/${fileDetails.name}`,
      });

      await db
        .collection("recipients")
        .doc(recipient_id)
        .collection("onboardingFiles")
        .doc(fileDetails.id)
        .delete();
    } catch (error) {}
  };

  const onCommentUpdate = async () => {
    const updateObject = {
      _updated: firebase.firestore.FieldValue.serverTimestamp(),
      _updatedBy: user.id,
      _owner: entity.id,
      _version: firebase.firestore.FieldValue.increment(1),
      comments: commentText,
    };

    try {
      await db
        .doc(`/recipients/${recipient_id}/onboarding/onboardingReview`)
        .set(updateObject, { merge: true });
    } catch (error) {}
  };

  return (
    <div>
      <>
        <EditOnboardingRecord
          recipientId={recipient_id}
          sanctionResult={docToView}
          isVisible={!!docToView}
          onClose={() => setDocToView(null)} />
      </>
      <>
        {recipient && entity && (
          <>
            <Title>{recipient.recipientName}</Title>
            <p>
              {recipient &&
                `${recipient.id} - CC ID: ${recipient.externalRefs?.ccId}`}{" "}
              <br />
              {entity && (
                <>
                  For{" "}
                  <Link to={"/app/entity-detail/" + entity.id}>
                    {entity.name}
                  </Link>
                </>
              )}
            </p>

            {recipient._owner === "house" && (
              <>
                <Title level={3}>MASTER RECIPIENT - All Sanction reviews will trickle down to other recipients</Title>
                <Descriptions bordered>
                  <Descriptions.Item label="Status" span={2}>
                    {recipient.status === undefined && (
                      <Badge status="default" text="No Action Required" />
                    )}
                    {recipient.status === RECIPIENT_STATUS.requiresReview && (
                      <>
                        <Badge
                          status="processing"
                          text="Recipient Requires Review"
                        />
                        {recipient.statusDetails && (
                          <>
                            <br />
                            Reason: {recipient.statusDetails}
                          </>
                        )}
                      </>
                    )}
                    {recipient.status === RECIPIENT_STATUS.rejected && (
                      <Badge status="error" text="Recipient Rejected" />
                    )}
                    {recipient.status === RECIPIENT_STATUS.approved && (
                      <Badge status="success" text="Recipient Approved" />
                    )}
                    {recipient.status === RECIPIENT_STATUS.deleted && (
                      <Badge status="error" text="Deleted" />
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Supporting Docs">
                    {allowWrite && (
                      <Button
                        loading={isFilesUploading}
                        disabled={isFilesUploading}
                        style={{
                          marginBottom: 16,
                        }}
                        icon={<UploadOutlined />}
                        onClick={() => {
                          fileInputRef.current.click();
                        }}
                      >
                        Upload new file
                      </Button>
                    )}
                    <input
                      ref={fileInputRef}
                      type="file"
                      multiple
                      hidden
                      onChange={async (event) => {
                        const files = event.target.files;

                        const filesArray = Array.from(files);
                        const newFiles = filesArray.filter(
                          (item) =>
                            !recipientFiles.some(
                              (itemEx) => itemEx.name === item.name
                            )
                        );

                        setIsFilesUploading(true);

                        await asyncForEach(newFiles, async (file) => {
                          await onAddFile(file);
                        });

                        setIsFilesUploading(false);

                        event.target.value = null;
                      }}
                    />

                    {recipientFiles.map((file, index) => {
                      const isFirst = index === 0;
                      return (
                        <Row
                          key={file.name}
                          justify="space-between"
                          align="middle"
                          style={{
                            height: 60,
                            borderBottom: "1px solid #d3caca",
                            borderTop: isFirst ? "1px solid #d3caca" : "none",
                          }}
                        >
                          <Typography.Link
                            href={file.location}
                            rel="noreferrer"
                            target="_blank"
                          >
                            {file.name}
                          </Typography.Link>

                          {allowWrite && (
                            <Button
                              danger
                              onClick={() => {
                                onRemoveFile(file);
                              }}
                            >
                              Remove
                            </Button>
                          )}
                        </Row>
                      );
                    })}
                  </Descriptions.Item>
                </Descriptions>
              </>
            )}

            {recipient._owner !== "house" && (
              <Descriptions bordered>
                <Descriptions.Item label="Recipient Type">
                  {recipient.recipientEntityType}
                </Descriptions.Item>
                <Descriptions.Item label="Payment Type">
                  {recipient.paymentType}
                </Descriptions.Item>
                <Descriptions.Item label="Recipient ID">
                  {recipient.id}
                </Descriptions.Item>

                <Descriptions.Item label="Account Country">
                  {recipient.accountCountry}
                </Descriptions.Item>
                <Descriptions.Item label="Currency">
                  {recipient.currency}
                </Descriptions.Item>
                <Descriptions.Item label="CC ID">
                  {recipient.externalRefs?.ccId}
                </Descriptions.Item>

                <Descriptions.Item label="Address">
                  {recipient.recipientAddress && (
                    <>
                      {recipient.recipientAddress}
                      <br />
                    </>
                  )}
                  {recipient.recipientPostcode && (
                    <>
                      {recipient.recipientPostcode}
                      <br />
                    </>
                  )}
                  {recipient.recipientCity && (
                    <>
                      {recipient.recipientCity}
                      <br />
                    </>
                  )}
                  {recipient.recipientStateOrProvince && (
                    <>
                      {recipient.recipientStateOrProvince}
                      <br />
                    </>
                  )}
                  {recipient.recipientCountry && (
                    <>
                      {recipient.recipientCountry}
                      <br />
                    </>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Account">
                  {recipient.bicSwift && (
                    <>
                      BicSwift: {recipient.bicSwift}
                      <br />
                    </>
                  )}
                  {recipient.accountNumber && (
                    <>
                      Acc No: {recipient.accountNumber}
                      <br />
                    </>
                  )}
                  {recipient.routingType && (
                    <>
                      {recipient.routingType}: {recipient.routingNumber}
                      <br />
                    </>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Supporting Docs">
                  {allowWrite && (
                    <Button
                      loading={isFilesUploading}
                      disabled={isFilesUploading}
                      style={{
                        marginBottom: 16,
                      }}
                      icon={<UploadOutlined />}
                      onClick={() => {
                        fileInputRef.current.click();
                      }}
                    >
                      Upload new file
                    </Button>
                  )}
                  <input
                    ref={fileInputRef}
                    type="file"
                    multiple
                    hidden
                    onChange={async (event) => {
                      const files = event.target.files;

                      const filesArray = Array.from(files);
                      const newFiles = filesArray.filter(
                        (item) =>
                          !recipientFiles.some(
                            (itemEx) => itemEx.name === item.name
                          )
                      );

                      setIsFilesUploading(true);

                      await asyncForEach(newFiles, async (file) => {
                        await onAddFile(file);
                      });

                      setIsFilesUploading(false);

                      event.target.value = null;
                    }}
                  />

                  {recipientFiles.map((file, index) => {
                    const isFirst = index === 0;
                    return (
                      <Row
                        key={file.name}
                        justify="space-between"
                        align="middle"
                        style={{
                          height: 60,
                          borderBottom: "1px solid #d3caca",
                          borderTop: isFirst ? "1px solid #d3caca" : "none",
                        }}
                      >
                        <Typography.Link
                          href={file.location}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {file.name}
                        </Typography.Link>

                        {allowWrite && (
                          <Button
                            danger
                            onClick={() => {
                              onRemoveFile(file);
                            }}
                          >
                            Remove
                          </Button>
                        )}
                      </Row>
                    );
                  })}
                </Descriptions.Item>

                <Descriptions.Item label="Status" span={3}>
                  {recipient.status === undefined && (
                    <Badge status="default" text="No Action Required" />
                  )}
                  {recipient.status === RECIPIENT_STATUS.requiresReview && (
                    <>
                      <Badge
                        status="processing"
                        text="Recipient Requires Review"
                      />
                      {recipient.statusDetails && (
                        <>
                          <br />
                          Reason: {recipient.statusDetails}
                        </>
                      )}
                    </>
                  )}
                  {recipient.status === RECIPIENT_STATUS.rejected && (
                    <Badge status="error" text="Recipient Rejected" />
                  )}
                  {recipient.status === RECIPIENT_STATUS.approved && (
                    <Badge status="success" text="Recipient Approved" />
                  )}
                  {recipient.status === RECIPIENT_STATUS.deleted && (
                    <Badge status="error" text="Deleted" />
                  )}
                </Descriptions.Item>
              </Descriptions>
            )}
          </>
        )}

        <div
          className="col"
          style={{
            marginBottom: 16,
          }}
        >
          <Title level={5}>Overall Comments</Title>
          <TextArea
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            rows={6}
          />
        </div>
        <br />

        {allowWrite && (
          <div>
            <Button type="default" onClick={onCommentUpdate}>
              Update
            </Button>
            <Button
              disabled={
                isLoading ||
                recipient?.status === RECIPIENT_STATUS.rejected ||
                recipient?.status === RECIPIENT_STATUS.deleted
              }
              danger
              style={{ margin: "0 16px 0 0", float: "right" }}
              onClick={() => rejectRecipient(recipient_id)}
            >
              Reject
            </Button>

            <Button
              disabled={
                isLoading ||
                recipient?.status === RECIPIENT_STATUS.approved ||
                recipient?.status === RECIPIENT_STATUS.deleted
              }
              style={{
                background: "#87d068",
                borderColor: "green",
                margin: "0 16px 0 0",
                float: "right",
              }}
              onClick={() => approveRecipient(recipient_id)}
            >
              Approve recipient
            </Button>
          </div>
        )}
        <br />
        <br />

        {recipient?._owner !== 'house' && (
          <Table
            title={() => <Title level={2}>Transfers</Title>}
            columns={transfersColumns}
            dataSource={transfers}
          />
        )}

        <Table
          title={() => <Title level={2}>Sanction Checks</Title>}
          columns={sanctionChecksColumns(setDocToView, allowWrite)}
          dataSource={sanctionChecks.map((item, index) => ({
            ...item,
            key: index,
          }))}
          pagination={false}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <Table
                  columns={sanctionChecksUpdatesColumns(setDocToView, allowWrite)}
                  dataSource={sanctionChecksUpdates.filter(check => isSanctionUpdateDoc(check) && check.search_id === record.result?.id)}
                />
              );
            },
            rowExpandable: (record) =>
              sanctionChecksUpdates.filter(check => isSanctionUpdateDoc(check) && check.search_id === record.result?.id).length > 0,
          }}
        />

        <br />
      </>
    </div>
  );
};

export default RecipientDetails;
