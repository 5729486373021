import { openQueryWithTimestamp } from "utils";

import db from "services/firestore";
import { message } from "antd";
import { AxiosPrivateFirebaseInstance } from "settings";
import { IResponse } from "types";

export interface SubscribeToUsersParams {
  callback: (users: any) => void;
}

export const subscribeToUsers = ({ callback }: SubscribeToUsersParams) => {
  const subscribe = db
    .collection("users")
    .onSnapshot((query) =>
      callback(openQueryWithTimestamp(query, ["_created", "_updated"]))
    );

  return subscribe;
};

export interface ToggleUserDebugModeParams {
  userId: string;
  value: boolean;
}

export const toggleUserDebugMode = async ({
  userId,
  value,
}: ToggleUserDebugModeParams) => {
  await db.collection("users").doc(userId).update({
    debuggable: value,
  });
};

export const userAuthChangeStatus = async (
  userId: string,
  status: "disable" | "enable"
) => {
  try {
    await AxiosPrivateFirebaseInstance.post<IResponse>(
      `/admin_actions/auth/${userId}/${status}`
    );
    message.success(`User has been ${status}d`);
  } catch (error) {
    console.log(error);
    message.error("Error updating user. Error: ", error);
  }
};
