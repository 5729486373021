import { FC, useState, useEffect, useRef } from "react";
import {
  Tag,
  Typography,
  Input,
  Button,
  Descriptions,
  Badge,
  message,
  Row,
  Table,
} from "antd";
import firebase from "firebase/app";
import axios from "axios";
import { Firebase } from "services";
import { Link } from "react-router-dom";
import { useStoreState } from "state";

import "./SenderDetails.css";
import {
  toCurrencyNumber,
  asyncForEach,
  getUniqueDecreasingNumber,
  openDoc,
  openQuery,
  openQueryWithTimestamp,
  TCustomRouteComponentProps,
} from "utils";
import { useParams } from "react-router";

import { UploadOutlined } from "@ant-design/icons";
import {
  markSenderAsPayoutAccount,
  removeSenderFile,
  uploadSenderFile,
} from "services/firebase";
import DocViewer from "pages/Overview/components/DocViewer";

import db from "services/firestore";
import dayjs from "dayjs";
import FundingStatus from "components/FundingStatus/FundingStatus";

const subscribeToEntity = (entityId: string, callback) => {
  const unsubscribe = db
    .collection("entities")
    .doc(entityId)
    .onSnapshot((doc) => callback(openDoc(doc)));

  return unsubscribe;
};

const subscribeToSender = (senderId: string, callback: (data: any) => void) => {
  const unsubscribe = db
    .collection("senders")
    .doc(senderId)
    .onSnapshot((doc) => callback(openDoc(doc)));

  return unsubscribe;
};

const subscribeToSenderFiles = (
  senderId: string,
  callback: (data: any) => void
) => {
  const unsubscribe = db
    .collection("senders")
    .doc(senderId)
    .collection("onboardingFiles")
    .onSnapshot((query) => callback(openQuery(query)));

  return unsubscribe;
};

const subscribeToSenderOnboarding = (
  senderId: string,
  callback: (data: any) => void
) => {
  const unsubscribe = db
    .collection("senders")
    .doc(senderId)
    .collection("onboarding")
    .where(firebase.firestore.FieldPath.documentId(), "==", "onboardingReview")
    .onSnapshot((query) => callback(openQuery(query)));

  return unsubscribe;
};

const subscribeToFunding = (
  senderId: string,
  callback: (data: any) => void
) => {
  try {
    const unsubscribe = db
      .collection("funding")
      .where("senderId", "==", senderId)
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ["_created"]))
      );

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToSanctionCheck = (
  senderId: string,
  callback: (data: any) => void
) => {
  try {
    const unsubscribe = db
      .collection("senders")
      .doc(senderId)
      .collection("onboarding")
      .where(
        firebase.firestore.FieldPath.documentId(),
        "!=",
        "onboardingReview"
      )
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ["_created"]))
      );

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const fundingColumns = (setDocToView) => [
  {
    title: "Created",
    dataIndex: "_created",
    key: "_created",
    render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
  },
  {
    title: "Funded",
    dataIndex: "funded",
    key: "funded",
    render: (text, record) => {
      return (
        <div className="currencyAmount">
          {toCurrencyNumber(record.amount)}&nbsp;<b>{record.currency}</b>
        </div>
      );
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) => <FundingStatus status={status} />,
  },
  {
    title: "Source ID",
    dataIndex: "sourceId",
    key: "sourceId",
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (text, record) => {
      return (
        <span>
          <Link to="#" onClick={() => setDocToView(record)}>
            {text}
          </Link>
        </span>
      );
    },
  },
];

const sanctionChecksColumns = (setDocToView) => [
  {
    title: "Created",
    dataIndex: "_created",
    key: "_created",
    render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
  },
  {
    title: "Type of check",
    render: (text, record) =>
      record.result?.client_ref?.includes("bankname") ? "Bank Name" : "Sender",
  },
  {
    title: "Has hits",
    dataIndex: "hasHits",
    key: "hasHits",
    render: (text, record) => (text ? `Yes (${record.numberOfHits})` : "No"),
  },
  {
    title: "Match Status",
    dataIndex: "matchStatus",
    key: "matchStatus",
  },
  {
    title: "Success",
    dataIndex: "success",
    key: "success",
    render: (text, record) => (text ? "Yes" : "No"),
  },
  {
    title: "Clear",
    dataIndex: "clear",
    key: "clear",
    render: (text, record) =>
      text ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>,
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (text, record) => {
      return (
        <span>
          <Link to="#" onClick={() => setDocToView(record)}>
            {text}
          </Link>
        </span>
      );
    },
  },
];

const { Title } = Typography;
const { TextArea } = Input;

const SenderDetails: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [entity, setEntity] = useState(null);
  const [sender, setSender] = useState(null);
  const [sanctionChecks, setSanctionCheck] = useState([]);
  const { user } = useStoreState((state) => state.UserState);
  const [isLoading, setIsLoading] = useState(false);
  const [isFilesUploading, setIsFilesUploading] = useState(false);
  const [senderFiles, setSenderFiles] = useState([]);
  const fileInputRef = useRef<HTMLInputElement>();
  const [commentText, setCommentText] = useState("");
  const [funding, setFunding] = useState([]);
  const [docToView, setDocToView] = useState(null);

  // @ts-ignore
  const { sender_id } = useParams();

  useEffect(() => {
    let unsubscribeFromSender = null;
    let unsubscribeFromSanctionCheck = null;
    let unsubscribeFromSenderOnboarding = null;
    let unsubscribeFunding = null;

    if (sender_id) {
      unsubscribeFromSender = subscribeToSender(sender_id, (data) =>
        setSender(data)
      );
      unsubscribeFromSanctionCheck = subscribeToSanctionCheck(
        sender_id,
        (data) => setSanctionCheck(data)
      );
      unsubscribeFromSenderOnboarding = subscribeToSenderOnboarding(
        sender_id,
        (data) => setCommentText(data[0]?.comments || "")
      );
      unsubscribeFunding = subscribeToFunding(sender_id, (data) =>
        setFunding(data)
      );

      db.collection("senders").doc(sender_id).get();
    }

    return () => {
      unsubscribeFromSender && unsubscribeFromSender();
      unsubscribeFromSanctionCheck && unsubscribeFromSanctionCheck();
      unsubscribeFromSenderOnboarding && unsubscribeFromSenderOnboarding();
      unsubscribeFunding && unsubscribeFunding();
    };
  }, [sender_id]);

  useEffect(() => {
    let unsubscribeFromEntity = null;
    let unsubscribeFromSenderFiles = null;

    if (sender) {
      unsubscribeFromEntity = subscribeToEntity(sender._owner, (data) =>
        setEntity(data)
      );
      unsubscribeFromSenderFiles = subscribeToSenderFiles(sender.id, (files) =>
        setSenderFiles(files)
      );
    }

    return () => {
      unsubscribeFromEntity && unsubscribeFromEntity();
      unsubscribeFromSenderFiles && unsubscribeFromSenderFiles();
    };
  }, [sender]);

  const approveSender = async (senderId: string) => {
    const callApproveSenderService = async (senderId: string) => {
      const token = await Firebase.auth.currentUser.getIdToken();
      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      return axios.post(
        `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/senders/${senderId}/approve`,
        null,
        headers
      );
    };

    try {
      setIsLoading(true);

      const svcResponse: any = await callApproveSenderService(senderId).then(
        (res) => res.data
      );

      if (svcResponse.success) {
        message.success("Approved sender");
      } else {
        message.error(`Failed to approve sender: ${svcResponse.message}`);
      }
    } catch (error) {
      message.error("Failed to approve sender");
    } finally {
      setIsLoading(false);
    }
  };

  const rejectSender = async (senderId: string) => {
    const callRejectSenderService = async (senderId: string) => {
      const token = await Firebase.auth.currentUser.getIdToken();
      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      return axios.post(
        `${process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL}/senders/${senderId}/reject`,
        null,
        headers
      );
    };

    try {
      setIsLoading(true);

      const svcResponse = await callRejectSenderService(senderId).then(
        (res) => res.data
      );

      if (svcResponse.success) {
        message.success("Rejected sender");
      } else {
        message.error(`Failed to reject sender: ${svcResponse.message}`);
      }
    } catch (error) {
      message.error("Failed to reject sender");
    } finally {
      setIsLoading(false);
    }
  };

  const onAddFile = async (file) => {
    try {
      const downloadURL = await uploadSenderFile({
        entityId: entity.id,
        senderId: sender_id,
        fileToUpload: file,
      });

      const fileDetails = {
        id: getUniqueDecreasingNumber(),
        name: file.name,
        location: downloadURL,
        linkedTo: `onboarding/${entity.id}/senders/${sender_id}`,
      };

      await db
        .collection("senders")
        .doc(sender_id)
        .collection("onboardingFiles")
        .doc(getUniqueDecreasingNumber())
        .set(fileDetails);
    } catch (error) {}
  };

  const onRemoveFile = async (fileDetails) => {
    try {
      await removeSenderFile({
        storagePath: `${fileDetails.linkedTo}/${fileDetails.name}`,
      });

      await db
        .collection("senders")
        .doc(sender_id)
        .collection("onboardingFiles")
        .doc(fileDetails.id)
        .delete();
    } catch (error) {}
  };

  const onCommentUpdate = async () => {
    const updateObject = {
      _updated: firebase.firestore.FieldValue.serverTimestamp(),
      _updatedBy: user.id,
      _owner: entity.id,
      _version: firebase.firestore.FieldValue.increment(1),
      comments: commentText,
    };

    try {
      console.log(
        "onCommentUpdate",
        `/senders/${sender_id}/onboarding/onboardingReview`,
        updateObject
      );

      await db
        .doc(`/senders/${sender_id}/onboarding/onboardingReview`)
        .set(updateObject, { merge: true });
      console.log("done");
    } catch (error) {
      console.log("Error updating comment", error.message);
    }
  };

  const onMarkAsEntityAccount = async () => {
    const updateObject = {
      _updated: firebase.firestore.FieldValue.serverTimestamp(),
      _updatedBy: user.id,
      _owner: entity.id,
      _version: firebase.firestore.FieldValue.increment(1),
      isEntityAccount: true,
    };

    try {
      console.log(
        "onMarkAsEntityAccount",
        `/senders/${sender_id}`,
        updateObject
      );

      await db.doc(`/senders/${sender_id}`).set(updateObject, { merge: true });
      console.log("done");
    } catch (error) {
      console.log("Error updating to entity account", error.message);
    }
  };

  const onMarkAsPayoutAccount = async () => {
    try {
      await markSenderAsPayoutAccount(sender_id, entity.id);
      message.success("Marked as payout account");
    } catch (error) {
      console.log("Error updating to entity account", error.message);
    }
  };

  return (
    <div>
      <>
        <DocViewer
          doc={docToView}
          isVisible={!!docToView}
          onClose={() => setDocToView(null)}
        />
      </>
      <>
        {sender && entity && (
          <>
            <Title>{sender.name}</Title>
            <p>
              {sender && `${sender.id}`} <br />
              {entity && (
                <>
                  For{" "}
                  <Link to={"/app/entity-detail/" + entity.id}>
                    {entity.name}
                  </Link>
                </>
              )}
            </p>
            <Descriptions bordered>
              <Descriptions.Item label="Account Number">
                {sender.accountNumber}
              </Descriptions.Item>
              <Descriptions.Item label="BIC/SWIFT">
                {sender.bicSwift}
              </Descriptions.Item>
              <Descriptions.Item label="Address">
                {sender.address}
              </Descriptions.Item>

              <Descriptions.Item label="Currency">
                {/* we always have fundings of the same currency */}
                {funding[0].currency}
              </Descriptions.Item>
              <Descriptions.Item label="Routing Number">
                {sender.routingNumber}
              </Descriptions.Item>
              <Descriptions.Item label="Country ID">
                {sender.country}
              </Descriptions.Item>

              <Descriptions.Item label="Status">
                {sender?.status === undefined && (
                  <Badge status="default" text="No Action Required" />
                )}
                {sender.status === "requiresReview" && (
                  <>
                    <Badge status="processing" text="Sender Requires Review" />
                    {sender.statusDetails && (
                      <>
                        <br />
                        Reason: {sender.statusDetails}
                      </>
                    )}
                  </>
                )}
                {sender.status === "rejected" && (
                  <Badge status="error" text="Sender Rejected" />
                )}
                {sender.status === "approved" && (
                  <Badge status="success" text="Sender Approved" />
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Is entity account?">
                {sender.isEntityAccount ? (
                  "Yes"
                ) : (
                  <>
                    <span style={{ paddingRight: "10px" }}>No</span>
                    {allowWrite && (
                      <Button type="default" onClick={onMarkAsEntityAccount}>
                        Mark as an Entity Account
                      </Button>
                    )}
                  </>
                )}
              </Descriptions.Item>
              <Descriptions.Item
                label={`Is entity payout account for ${funding[0].currency}?`}
              >
                {sender.payoutAccountCurrency === funding[0].currency ? (
                  "Yes"
                ) : (
                  <>
                    <span style={{ paddingRight: "10px" }}>No</span>
                    {allowWrite && (
                      <Button type="default" onClick={onMarkAsPayoutAccount}>
                        Mark as an Entity Payout Account for{" "}
                        {funding[0].currency}
                      </Button>
                    )}
                  </>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Supporting Docs">
                {allowWrite && (
                  <Button
                    loading={isFilesUploading}
                    disabled={isFilesUploading}
                    style={{
                      marginBottom: 16,
                    }}
                    icon={<UploadOutlined />}
                    onClick={() => {
                      fileInputRef.current.click();
                    }}
                  >
                    Upload new file
                  </Button>
                )}
                <input
                  ref={fileInputRef}
                  type="file"
                  multiple
                  hidden
                  onChange={async (event) => {
                    const files = event.target.files;

                    const filesArray = Array.from(files);
                    const newFiles = filesArray.filter(
                      (item) =>
                        !senderFiles.some((itemEx) => itemEx.name === item.name)
                    );

                    setIsFilesUploading(true);

                    await asyncForEach(newFiles, async (file) => {
                      await onAddFile(file);
                    });

                    setIsFilesUploading(false);

                    event.target.value = null;
                  }}
                />

                {senderFiles.map((file, index) => {
                  const isFirst = index === 0;
                  return (
                    <Row
                      key={file.name}
                      justify="space-between"
                      align="middle"
                      style={{
                        height: 60,
                        borderBottom: "1px solid #d3caca",
                        borderTop: isFirst ? "1px solid #d3caca" : "none",
                      }}
                    >
                      <Typography.Link
                        href={file.location}
                        rel="noreferrer"
                        target="_blank"
                      >
                        {file.name}
                      </Typography.Link>

                      {allowWrite && (
                        <Button
                          danger
                          onClick={() => {
                            onRemoveFile(file);
                          }}
                        >
                          Remove
                        </Button>
                      )}
                    </Row>
                  );
                })}
              </Descriptions.Item>
            </Descriptions>
          </>
        )}

        <div
          className="col"
          style={{
            marginBottom: 16,
          }}
        >
          <Title level={5}>Comments</Title>
          <TextArea
            value={commentText}
            onChange={(e) => setCommentText(e.target.value)}
            rows={6}
          />
        </div>
        <br />

        {allowWrite && (
          <div>
            <Button type="default" onClick={onCommentUpdate}>
              Update
            </Button>
            <Button
              disabled={isLoading || sender?.status === "rejected"}
              danger
              style={{ margin: "0 16px 0 0", float: "right" }}
              onClick={() => rejectSender(sender_id)}
            >
              Reject
            </Button>

            <Button
              disabled={isLoading || sender?.status === "approved"}
              style={{
                background: "#87d068",
                borderColor: "green",
                margin: "0 16px 0 0",
                float: "right",
              }}
              onClick={() => approveSender(sender_id)}
            >
              Approve sender
            </Button>
          </div>
        )}
        <br />
        <br />

        <Table
          title={() => <Title level={2}>Funding</Title>}
          columns={fundingColumns(setDocToView)}
          dataSource={funding}
        />

        <Table
          title={() => <Title level={2}>Sanction Checks</Title>}
          columns={sanctionChecksColumns(setDocToView)}
          dataSource={sanctionChecks}
          pagination={false}
        />

        <br />
      </>
    </div>
  );
};

export default SenderDetails;
