import moment from "moment";
import dayjs from "dayjs";
import { FC, useState } from "react";
import {
  Table,
  Typography,
  Button,
  DatePicker,
  DatePickerProps,
  Modal,
  message,
  Input,
} from "antd";
import "./ReportBalances.css";
import { Link } from "react-router-dom";
import { Firebase } from "services";
import { DATE_FORMAT_REVERSED } from "settings/variables";

const { Title } = Typography;

const balancesColumns = [
  {
    title: "Entity ID",
    dataIndex: "entityId",
    key: "entityId",
    render: (text, record) => {
      return (
        <span>
          <Link to={"/app/entity-detail/" + record.entityId}>{text}</Link>
        </span>
      );
    },
  },
  {
    title: "OnBehalfOf ID",
    dataIndex: "onBehalfOfId",
    key: "onBehalfOfId",
    render: (text, record) => {
      return (
        <span>
          <Link to={"/app/entity-detail/" + record.entityId}>{text}</Link>
        </span>
      );
    },
  },
  {
    title: "As at date",
    dataIndex: "asAtDate",
    key: "asAtDate",
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
  },
];

const ReportBalances: FC = () => {
  const [asAtDateSelected, setAsAtDateSelected] = useState<string>(
    dayjs().subtract(1, "day").format(DATE_FORMAT_REVERSED)
  );
  const [asAtTime, setAsAtTime] = useState<string>("16:30");
  const [fetchedAsAtDate, setFetchedAsAtDate] = useState<string>(
    dayjs().subtract(1, "day").format(DATE_FORMAT_REVERSED)
  );
  const [fetchedAsAtTime, setFetchedAsAtTime] = useState<string>("16:30");
  const [balances, setBalances] = useState();
  const [balanceAsCsv, setBalanceAsCsv] = useState<string>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOkAndCancel = () => {
    setIsModalOpen(false);
  };

  const getBalances = async (asAtDate: string, asCsv = false) => {
    try {
      const asAtDateTime = `${asAtDate}T${asAtTime}:00`;
      if (asCsv) {
        await Firebase.getBalancesReportAsCsv(asAtDateTime).then((data) => {
          if (data) {
            setBalanceAsCsv(data);
            setIsModalOpen(true);
          }
        });
      } else {
        await Firebase.getBalancesReport(asAtDateTime).then((data) => {
          if (data) {
            setFetchedAsAtDate(asAtDate);
            setFetchedAsAtTime(asAtTime);
            setBalances(data);
          }
        });
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const datePickerOnChange: DatePickerProps["onChange"] = (
    date,
    dateString
  ) => {
    setAsAtDateSelected(dateString);
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", gap: "8px" }}>
        <DatePicker
          defaultValue={moment(asAtDateSelected)}
          format={DATE_FORMAT_REVERSED}
          onChange={datePickerOnChange}
        />
        <Input
          prefix="Time:"
          placeholder="16:30"
          style={{ width: "100px" }}
          value={asAtTime}
          onChange={(e) =>
            setAsAtTime(e.target.value ? e.target.value : "16:30")
          }
        />
        <Button onClick={() => getBalances(asAtDateSelected)}>
          Get Balances
        </Button>
        <Button onClick={() => getBalances(asAtDateSelected, true)}>
          Get Balances as CSV
        </Button>
      </div>

      <br />

      {balances && (
        <Table
          title={() => (
            <Title level={2}>
              Balances as at {fetchedAsAtDate} {fetchedAsAtTime}
            </Title>
          )}
          columns={balancesColumns}
          dataSource={balances}
        />
      )}

      <Modal
        title="Balances"
        open={isModalOpen}
        closable={true}
        onOk={handleOkAndCancel}
        onCancel={handleOkAndCancel}
        width={800}
        bodyStyle={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}
      >
        <Button
          onClick={() => {
            navigator.clipboard.writeText(balanceAsCsv ?? "");
          }}
        >
          Copy to clipboard
        </Button>
        <br />
        <br />
        <pre>{balanceAsCsv}</pre>
      </Modal>
    </>
  );
};

export default ReportBalances;
