import { FC } from "react";
import { Tag } from "antd";

const FundingStatus: FC<{ status: string }> = ({ status }) => {
  const isApproved = status === "approved";
  const isRejected = status === "rejected";
  const isProcessed = status === "processed";
  const isInitiated = status === "initiated";
  const isOnHold = status === "onHold";
  const isReviewRequired = status === "requiresApproval";
  const isOther =
    !isApproved &&
    !isRejected &&
    !isProcessed &&
    !isInitiated &&
    !isOnHold &&
    !isReviewRequired;

  return (
    <>
      {isApproved && <Tag color="gold">APPROVED</Tag>}
      {isRejected && <Tag color="red">REJECTED</Tag>}
      {isProcessed && <Tag color="green">PROCESSED</Tag>}
      {isInitiated && <Tag color="grey">Initiated</Tag>}
      {isOnHold && <Tag color="magenta">On Hold</Tag>}
      {isReviewRequired && <Tag color="volcano">Requires Approval</Tag>}
      {isOther && status}
    </>
  );
};

export default FundingStatus;
