import { FC, useState } from "react";
import {
  Form,
  Input,
  Button,
  Drawer,
  Col,
  Row,
  Switch,
  message,
} from "antd";

import "antd/dist/antd.css";

import { Firebase } from "services";

interface OwnProps {
  entityId: string;
  isVisible: boolean;
  onClose: (...args: any) => void;
}

const CreateOnboardingRecord: FC<OwnProps> = ({
  isVisible,
  entityId,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const form = Form.useForm()[0];

  const onUpdateOnboardingData = async ({
    name,
    email,
    dob,
    isPsc,
    role,
    requiresVerification,
    address,
    description,
    }) => {
    setIsLoading(true);

    try {
      const response = await Firebase.executeActionOnOnboarding({
        entityId,
        onboardingId: "new",
        action: "create",
        updateData: {
          name,
          email,
          dob,
          isPsc,
          role,
          requiresVerification,
          address,
          description,
        },
      });

      if (response.data.success) {
        message.success(
          `Created onboarding with ID ${response.data.id} successfully`
        );
        form.resetFields();
        onClose();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      title={`Create an onboarding record`}
      width={720}
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      open={isVisible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={async ({ fileUpload, ...values }) => {
          onUpdateOnboardingData(values as any);
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="name" label="Name">
              <Input style={{ width: "100%" }} placeholder="Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="email" label="Email">
              <Input style={{ width: "100%" }} placeholder="Email" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="dob" label="Date of birth">
              <Input style={{ width: "100%" }} placeholder="yyyy-mm-dd" />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item name="role" label="Role">
              <Input style={{ width: "100%" }} placeholder="Role" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="isPsc" label="Is PSC" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="requiresVerification"
              label="Requires Verification"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name={["address", "addressLine1"]} label="Address">
              <Input style={{ width: "100%" }} placeholder="Address" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["address", "city"]} label="City">
              <Input style={{ width: "100%" }} placeholder="Address" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name={["address", "postalCode"]} label="Post Code">
              <Input style={{ width: "100%" }} placeholder="N1 1AB" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["address", "country"]} label="Country code">
              <Input style={{ width: "100%" }} placeholder="e.g. gb" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input.TextArea
                rows={4}
                placeholder="Optionally enter any notes or description here"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <div
            style={{
              textAlign: "right",
              marginTop: 16,
            }}
          >
            <Button
              onClick={() => {
                form.resetFields();
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button disabled={isLoading} htmlType="submit" type="primary">
              Create
            </Button>
          </div>
        </Form.Item>
      </Form>

    </Drawer>
  );
};

export default CreateOnboardingRecord;
