import { action, Action, computed, Computed, thunk, Thunk } from "easy-peasy";
import { SIDEBAR_ROUTES } from "routes/variables";

import { Firebase } from "services";
import { StateModel } from "state";
import {
  ICountry,
  IPotentialSavings,
  IPotentialSavingsWithDate,
  IRateAnalyses,
  IRateAnalysesStable,
  IRatings,
} from "types";
import { checkPermissions, TRoleBasedAdminPageAccess } from "utils";

export interface ReferenceDataStateModel {
  roleBasedAdminPageAccess: TRoleBasedAdminPageAccess | null;
  nonTradingDays: {
    [key: string]: string[];
  };
  countries: {
    [key: string]: ICountry;
  };
  rateAnalyses: IRateAnalyses | null;
  rateAnalysesTable: IRateAnalysesStable | null;
  potentialSavings: IPotentialSavings | null;
  potentialSavingsWithDate: IPotentialSavingsWithDate | null;
  ratings: IRatings | null;
  setState: Action<ReferenceDataStateModel, [string, any]>;
  getNonTradingDays: Thunk<ReferenceDataStateModel>;
  getCountries: Thunk<ReferenceDataStateModel>;
  getRateAnalyses: Thunk<
    ReferenceDataStateModel,
    Firebase.GetRateAnalysisParams
  >;
  getRateAnalysesTable: Thunk<
    ReferenceDataStateModel,
    Firebase.GetRateAnalysisTableParams
  >;
  getPotentialSavings: Thunk<
    ReferenceDataStateModel,
    Firebase.GetPotentialSavingsParams
  >;
  getPotentialSavingsWithDate: Thunk<
    ReferenceDataStateModel,
    Firebase.GetPotentialSavingsWithDateParams
  >;
  getRatings: Thunk<ReferenceDataStateModel, Firebase.GetRatingsParams>;
  countryByCode: Computed<
    ReferenceDataStateModel,
    (countryCode: ICountry["alpha2"]) => ICountry
  >;
  nonTradingDaysByCurrencyPair: Computed<
    ReferenceDataStateModel,
    (currencyPair: string) => string[]
  >;
  setRoleBasedAdminPageAccess: Action<
    ReferenceDataStateModel,
    TRoleBasedAdminPageAccess
  >;
  routesData: Computed<
    ReferenceDataStateModel,
    {
      defaultPageKey?: string;
      defaultSubPageKey?: string;
      defaultPath?: string;
    },
    StateModel
  >;
}

export const ReferenceDataState: ReferenceDataStateModel = {
  roleBasedAdminPageAccess: null,
  nonTradingDays: {},
  countries: {},
  rateAnalyses: null,
  rateAnalysesTable: null,
  potentialSavings: null,
  potentialSavingsWithDate: null,
  ratings: null,
  setState: action((state, payload) => {
    const [prop, to] = payload;
    state[prop] = to;
  }),
  setRoleBasedAdminPageAccess: action((state, payload) => {
    state.roleBasedAdminPageAccess = payload;
  }),
  getNonTradingDays: thunk(async (actions) => {
    const data = await Firebase.getNonTradingDays();

    if (data) {
      actions.setState(["nonTradingDays", data]);
    }
  }),
  getCountries: thunk(async (actions) => {
    const data = await Firebase.getCountries();

    if (data) {
      actions.setState(["countries", data]);
    }
  }),
  nonTradingDaysByCurrencyPair: computed(
    (state) => (currencyPair) => state.nonTradingDays?.[currencyPair] || []
  ),
  countryByCode: computed(
    (state) => (countryCode) =>
      state.countries[countryCode.toUpperCase()] || null
  ),
  getRateAnalyses: thunk(async (actions, payload) => {
    const data = await Firebase.getRateAnalysis(payload);

    if (data) {
      actions.setState(["rateAnalyses", data]);
    }
  }),
  getRateAnalysesTable: thunk(async (actions, payload) => {
    const data = await Firebase.getRateAnalysisTable(payload);

    if (data) {
      actions.setState(["rateAnalysesTable", data]);
    }
  }),
  getPotentialSavings: thunk(async (actions, payload) => {
    const data = await Firebase.getPotentialSavings(payload);

    if (data) {
      actions.setState(["potentialSavings", data]);
    }
  }),
  getPotentialSavingsWithDate: thunk(async (actions, payload) => {
    const data = await Firebase.getPotentialSavingsWithDate(payload);

    if (data) {
      actions.setState(["potentialSavingsWithDate", data]);
    }
  }),
  getRatings: thunk(async (actions, payload) => {
    const data = await Firebase.getRatings(payload);

    if (data) {
      actions.setState(["ratings", data]);
    }
  }),
  routesData: computed(
    [(state, storeState) => ({ state, storeState })],
    ({ state, storeState }) => {
      const user = storeState.UserState.user;
      const roleBasedAdminPageAccess = state.roleBasedAdminPageAccess;

      const getRoutesData = () => {
        if (!user?.roles || !roleBasedAdminPageAccess) {
          return {
            defaultPageKey: undefined,
            defaultSubPageKey: undefined,
          };
        }

        const route = SIDEBAR_ROUTES.find((sidebarRoute) => {
          const { allowRender } = checkPermissions({
            pageKey: sidebarRoute.pageKey,
            roles: user.roles,
            roleBasedAdminPageAccess,
          });

          return allowRender;
        });

        return {
          defaultPageKey: route?.pageKey,
          defaultSubPageKey: route?.subPages?.[0].subPage,
        };
      };

      const { defaultPageKey, defaultSubPageKey } = getRoutesData();

      return {
        defaultPageKey,
        defaultSubPageKey,
        defaultPath: `${defaultPageKey}${
          defaultSubPageKey ? `/${defaultSubPageKey}` : ""
        }`,
      };
    }
  ),
};
