import {
  IResponse,
  ILedgerTransactionUpdate,
  ILedgerTransactionInput,
} from "types";
import { AxiosPrivateFirebaseInstance } from "settings";

export const createLedgerTransaction = async (
  newLedgerTransaction: Partial<ILedgerTransactionInput>
) =>
  AxiosPrivateFirebaseInstance.post<IResponse>(
    `/admin_actions/ledger-transactions`,
    newLedgerTransaction
  );

export interface UpdateLedgerTransactionParams {
  ledgerTransactionId: string;
  updateData: ILedgerTransactionUpdate;
}

export const updateLedgerTransaction = async ({
  ledgerTransactionId,
  updateData,
}: UpdateLedgerTransactionParams) =>
  AxiosPrivateFirebaseInstance.put<IResponse>(
    `/admin_actions/ledger-transactions/${ledgerTransactionId}`,
    updateData
  );
