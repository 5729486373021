import { FC, useState, useEffect } from "react";
import {
  Form,
  Button,
  Drawer,
  Input,
  Typography,
  message,
  Switch,
} from "antd";

import "antd/dist/antd.css";

import { Firebase } from "services";
import { ISanctionResult, ISanctionResultUpdate } from "types";
import { JSONstringifyOrder } from "utils";

const { Title } = Typography;


interface OwnProps {
  recipientId: string;
  sanctionResult: ISanctionResult;
  isVisible: boolean;
  onClose: (...args: any) => void;
}

const EditOnboardingRecord: FC<OwnProps> = ({
  recipientId,
  sanctionResult,
  isVisible,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [comments, setComments] = useState<string | null>(null);
  const [resolved, setResolved] = useState<boolean>();

  const form = Form.useForm()[0];

  useEffect(() => {
    if (sanctionResult && isVisible) {
      form.setFieldsValue(sanctionResult);
      setComments(sanctionResult.comments);
      setResolved(sanctionResult.resolved);
    }
  }, [form, sanctionResult, isVisible]);

  const onUpdateSanctionResult = async () => {
    setIsLoading(true);

    try {
      if (!comments) {
        message.error("You need to provide a comment to update the Sanction Result");
        setIsLoading(false);
        return;
      }
      const updateData: ISanctionResultUpdate = {
        comments,
        resolved
      };
      const response = await Firebase.updateRecipientOnboardingRecord({
        recipientId,
        onboardingRecordId: sanctionResult.id,
        updateData,
      });

      if (response.data.success) {
        message.success(`Sanction Result ${sanctionResult.id} updated successfully`);
        onClose();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      title={`Comment on and Resolve a Sanction Check`}
      width={720}
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      visible={isVisible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        onFinish={async ({ fileUpload, ...values }) => {
          // submit logic here
          onUpdateSanctionResult();
        }}
      >
        <Title level={5}>Comments</Title>
        <Input.TextArea rows={6} value={comments} onChange={(e) => setComments(e.target.value)} />

        <Title level={5}>Resolved</Title>
        <Switch checked={resolved} onChange={(e) => setResolved(e)} />

        <Form.Item>
          <div
            style={{
              textAlign: "right",
              marginTop: 16,
            }}
          >
            <Button
              onClick={() => {
                form.resetFields();
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button disabled={isLoading} htmlType="submit" type="primary">
              Update
            </Button>
          </div>
        </Form.Item>
      </Form>

      {sanctionResult && 
        <>
          <Title level={5}>Sanction Result</Title>
          <pre>{JSONstringifyOrder(sanctionResult, 2)}</pre>
        </>
      }

    </Drawer>
  );
};

export default EditOnboardingRecord;
