import { AxiosPrivateFirebaseInstance } from 'settings';
import { IFollowedCurrencyPair, IResponse } from 'types';
import { openQuery, openQueryWithTimestamp } from 'utils';

import db from 'services/firestore';

export const getCurrencies = async () => {
  try {
    const data = await db.collection('currencies').get();

    if (data) {
      return openQuery(data);
    }
  } catch (error) {
    console.warn(error.message);
  }
};

export interface SubscribeToFollowedCurrenciesParams {
  uid: string;
  callback: (transfers: IFollowedCurrencyPair[]) => void;
}

export const subscribeToFollowedCurrencies = ({
  uid,
  callback,
}: SubscribeToFollowedCurrenciesParams) => {
  try {
    return db
      .collection('users')
      .doc(uid)
      .collection('followedCurrencyPairs')
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ['_created']))
      );
  } catch (error) {
    console.warn(error);
  }
};

export const addFollowedCurrencyPair = async (
  params: Omit<IFollowedCurrencyPair, 'id'>
) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.post<IResponse>(
      '/followed_currencies',
      params
    );

    return data;
  } catch (error) {
    console.warn(error);
  }
};

export const deleteFollowedCurrencyPair = async (
  followedCurrencyId: IFollowedCurrencyPair['id']
) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.delete<IResponse>(
      `/followed_currencies/${followedCurrencyId}`
    );

    return data;
  } catch (error) {
    console.warn(error);
  }
};

export const updateFollowedCurrencyPair = async ({
  followedCurrencyData,
  followedCurrencyId,
}: {
  followedCurrencyId: IFollowedCurrencyPair['id'];
  followedCurrencyData: Omit<IFollowedCurrencyPair, 'id'>;
}) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.put<IResponse>(
      `/followed_currencies/${followedCurrencyId}`,
      followedCurrencyData
    );

    return data;
  } catch (error) {
    console.warn(error);
  }
};
