import { FC, useState, useEffect } from "react";
import { Button, Form, message, Modal, Table, Typography } from "antd";
import firebase from "firebase/app";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import ace from "brace";
import "brace/mode/json";
import "brace/theme/github";

import "./RiskAnalysis.css";
import dayjs from "dayjs";
import { AxiosPrivateFirebaseInstance } from "settings";

import db from "services/firestore";
import { TCustomRouteComponentProps } from "utils";

const subscribeToRiskAnalysis = (callback) => {
  try {
    const unsubscribe = db
      .collection("referenceData")
      .doc("riskAnalysis")
      .onSnapshot((doc) => {
        const docData = doc.data();

        const docDataResult = {};

        for (const key in docData) {
          const value = docData[key];

          docDataResult[key] = { ...value, lastRun: value.lastRun?.toDate() };
        }

        callback(docDataResult);
      });

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const { Title } = Typography;

const INITIAL_SPOT_RATES = {
  dateFrom: "2021-10-30",
  dateTo: "2021-12-30",
  currencies: ["GBP", "EUR"],
};

const RiskAnalysis: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [riskAnalysis, setRiskAnalysis] = useState(null);
  const [isShowAddModal, setIsShowAddModal] = useState(false);
  const [isShowSpotRatesModal, setIsShowSpotRatesModal] = useState(false);
  const [jsonSpotRatesValue, setJsonSpotRatesValue] =
    useState<any>(INITIAL_SPOT_RATES);
  const [jsonValue, setJsonValue] = useState<any>({
    currencyCode: "",
    code: "",
  });

  useEffect(() => {
    const unsubscribe = subscribeToRiskAnalysis((data) => {
      const parsedData = Object.entries(data).map(([key, value]) => ({
        id: key,
        // @ts-ignore
        ...value,
      }));
      const parsedDataSortedById = parsedData.sort((a, b) =>
        a.id > b.id ? 1 : -1
      );

      setRiskAnalysis(parsedDataSortedById);
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const addRiskAnalysisCcy = async ({ currencyCode, code, usdMultiplier }) => {
    try {
      const updateDoc: {
        [key: string]: { code: string; lastRun: any; usdMultiplier?: number };
      } = {
        [currencyCode]: {
          code,
          lastRun: firebase.firestore.FieldValue.serverTimestamp(),
        },
      };
      if (usdMultiplier) {
        updateDoc[currencyCode].usdMultiplier = usdMultiplier;
      }
      await db
        .collection("referenceData")
        .doc("riskAnalysis")
        .update(updateDoc);

      message.success("New risk analysis currency was added.");
    } catch (error) {
      console.log(error, "error");
    }
  };

  const updateBoeData = async () => {
    try {
      await AxiosPrivateFirebaseInstance.post("/refdata_loader/updateboedata", {
        ...jsonSpotRatesValue,
      });

      message.success("Boe data was updated.");
    } catch (error) {
      console.log(error, "error");
    }
  };

  const updateAllBoeData = async () => {
    try {
      await AxiosPrivateFirebaseInstance.get(
        "/refdata_loader/updateallboedata"
      );

      message.success("All boe data was updated.");
    } catch (error) {
      console.log(error, "error");
    }
  };

  const riskAnalysisColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "USD Multiplier",
      dataIndex: "usdMultiplier",
      key: "usdMultiplier",
    },
    {
      title: "Last run",
      dataIndex: "lastRun",
      key: "lastRun",
      render: (text) => {
        return <span>{dayjs(text).format("DD MM YYYY")}</span>;
      },
    },
  ];

  return (
    <div>
      <Title>Risk analysis reference data</Title>

      {allowWrite && (
        <>
          <Button
            type="primary"
            style={{
              margin: "16px 0",
            }}
            onClick={() => setIsShowAddModal(!isShowAddModal)}
          >
            + Add risk analysis currency
          </Button>

          <Button
            type="primary"
            style={{
              margin: 16,
            }}
            onClick={() => setIsShowSpotRatesModal(!isShowSpotRatesModal)}
          >
            Run spot rates update
          </Button>
        </>
      )}

      <Table columns={riskAnalysisColumns} dataSource={riskAnalysis} />

      <Modal
        title="Add risk analysis currency"
        centered
        visible={isShowAddModal}
        onCancel={() => setIsShowAddModal(false)}
        footer={null}
      >
        <Form
          layout="vertical"
          onFinish={async () => {
            if (!jsonValue.error) {
              await addRiskAnalysisCcy(jsonValue);
              setIsShowAddModal(false);
            }
          }}
        >
          <Form.Item label="Risk analysis currency data">
            <Editor
              ace={ace}
              theme="ace/theme/github"
              value={jsonValue}
              mode="code"
              onChange={(data) => {
                setJsonValue(data);
              }}
            />
          </Form.Item>

          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form>
      </Modal>

      <Modal
        title="Run spot rates update"
        centered
        visible={isShowSpotRatesModal}
        onCancel={() => setIsShowSpotRatesModal(false)}
        footer={null}
      >
        <Form
          layout="vertical"
          onFinish={async () => {
            await updateBoeData();
            setJsonSpotRatesValue(INITIAL_SPOT_RATES);
          }}
        >
          <Form.Item label="Risk analysis spot rates data">
            <Editor
              ace={ace}
              theme="ace/theme/github"
              value={jsonSpotRatesValue}
              mode="code"
              onChange={(data) => {
                setJsonSpotRatesValue(data);
              }}
            />
          </Form.Item>

          <Button htmlType="submit" type="primary">
            Submit
          </Button>
          <Button
            type="primary"
            style={{
              marginLeft: 16,
            }}
            onClick={updateAllBoeData}
          >
            Run for all currencies
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default RiskAnalysis;
