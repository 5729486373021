import { FC, useState, useEffect } from "react";
import { Table, message, Typography } from "antd";
import {
  getAdminUsers,
  IUpdateAdminUserRoles,
  updateAdminUserRoles,
} from "services/firebase";
import { adminUsersColumns } from "./tableColumns";
import { TCustomRouteComponentProps } from "utils";

const { Title, Paragraph } = Typography;

const AdminUserRoles: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [adminUsers, setAdminUsers] = useState([]);

  useEffect(() => {
    const getAdminUsersHandler = async () => {
      setIsLoading(true);
      try {
        const { data } = await getAdminUsers();
        if (data.success) {
          setAdminUsers(data.data || []);
        } else {
          message.error(data.message);
        }
      } catch (error) {
        message.error(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    getAdminUsersHandler();
  }, []);

  const updateAdminUserRolesHandler = async (
    payload: IUpdateAdminUserRoles
  ) => {
    try {
      await updateAdminUserRoles(payload);
      message.success("Roles were updated.");
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div>
      <Title>User Roles</Title>
      {isLoading && <Paragraph>Loading...</Paragraph>}
      {!isLoading && (
        <>
          <Table
            dataSource={adminUsers}
            columns={adminUsersColumns({
              allowWrite,
              isLoading,
              updateAdminUserRolesHandler,
            })}
          />
        </>
      )}
    </div>
  );
};

export default AdminUserRoles;
