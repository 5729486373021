import { FC, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Drawer,
  Col,
  Row,
} from "antd";

import "antd/dist/antd.css";

interface OwnProps {
  ddApprovedDate: string;
  ddNextDate: string;
  isVisible: boolean;
  onClose: (...args: any) => void;
  updateDdDates: (ddApprovedDate: string, ddNextDate: string) => Promise<void>;
}

const UpdateDdInfo: FC<OwnProps> = ({
  ddApprovedDate,
  ddNextDate,
  isVisible,
  onClose,
  updateDdDates,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const ddDatesForm = Form.useForm()[0];

  useEffect(() => {
    if (ddApprovedDate && ddNextDate) {
      ddDatesForm.setFieldsValue({ ddApprovedDate, ddNextDate });
    }
  }, [ddDatesForm, ddApprovedDate, ddNextDate]);

  return (
    <Drawer
      title={`Update DD Dates`}
      width={720}
      open={isVisible}
      onClose={() => {
        ddDatesForm.resetFields();
        onClose();
      }}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        form={ddDatesForm}
        layout="vertical"
        requiredMark={false}
        onFinish={async (values) => {
          try {
            setIsLoading(true);
            await updateDdDates(values.ddApprovedDate, values.ddNextDate);

            ddDatesForm.resetFields();
            onClose();
          } catch (error) {
            console.warn(error);
          } finally {
            setIsLoading(false);
          }
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="ddApprovedDate"
              label="DD Completed On"
              rules={[{ required: true}]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
          <Form.Item
              name="ddNextDate"
              label="Next DD Due On"
              rules={[{ required: true}]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <div
            style={{
              textAlign: "right",
              marginTop: 16,
            }}
          >
            <Button
              onClick={() => {
                ddDatesForm.resetFields();
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button disabled={isLoading} htmlType="submit" type="primary">
              Update
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UpdateDdInfo;
