import { FC, useState, useEffect } from "react";
import { Button, Table, Tag, Typography } from "antd";

import dayjs from "dayjs";
import { Link } from "react-router-dom";
import "./RecipientsList.css";
import { openQueryWithTimestamp } from "utils";

import db from 'services/firestore';
import { IRecipient, ITransfer, RECIPIENT_STATUS, TRANSFER_STATUS } from "types";
import CreateMasterRecipientForm from "./components/CreateMasterRecipientForm";

const getMasterRecipients = async () => {
  try {
    const recipients = await db
      .collection("recipients")
      .where("_owner", "==", "house")
      .get()
      .then((query) => openQueryWithTimestamp<IRecipient>(query, ["_created"]));

    return recipients;
  } catch (error) {
    console.log(error);
  }
};


const getRecipientsRequiringReview = async () => {
  try {
    const recipients = await db
      .collection("recipients")
      .where("status", "==", RECIPIENT_STATUS.requiresReview)
      .get()
      .then((query) => openQueryWithTimestamp<IRecipient>(query, ["_created"]));

    return recipients;
  } catch (error) {
    console.log(error);
  }
};

const getApprovedRecipients = async () => {
  try {
    let recipients = await db
      .collection("recipients")
      .where("lastReviewed", "!=", null)
      .get()
      .then((query) => openQueryWithTimestamp<IRecipient>(query, ["_created"]));

    recipients = recipients.filter(
      (recipient) => recipient.status !== RECIPIENT_STATUS.requiresReview && recipient._owner !== "house"
    );

    return recipients;
  } catch (error) {
    console.log(error);
  }
};

const getRecipientIdsWithUnapprovedTransfers = async () => {
  try {
    const transfers = await db
      .collection("transfers")
      .where("status", "in", [TRANSFER_STATUS.initiated, TRANSFER_STATUS.awaitingPayment, TRANSFER_STATUS.fundingInitiated, TRANSFER_STATUS.funded])
      .get()
      .then((query) => openQueryWithTimestamp<ITransfer>(query, ["_created"]));

    // get unique transfer.recipientIds
    const recipientIds = transfers.map((transfer) => transfer.recipientId); 
    const uniqueRecipientIds = [...new Set(recipientIds)];
    
    return uniqueRecipientIds;
  } catch (error) {
    console.log(error);
  }
};

const { Title } = Typography;

const recipientsColumns = (isMaster?: boolean) => {
  const columnsToReturn: {title: string, dataIndex: string, key: string, render?: any}[] = [
    {
      title: "Created",
      dataIndex: "_created",
      key: "_created",
      render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Name",
      dataIndex: "recipientName",
      key: "name",
      render: (text, record) => {
        return (
          <span>
            <Link to={"/app/recipient-detail/" + record.id}>{text}</Link>
          </span>
        );
      },
    }
  ];

  if (isMaster) {
    columnsToReturn.push({
      title: "Bank Name",
      dataIndex: "bankName",
      key: "bankName",
    });
  } else {
    columnsToReturn.push({
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      key: "paymenttype",
    },
    {
      title: "Type",
      dataIndex: "recipientEntityType",
      key: "type",
    },
    {
      title: "Account Country",
      dataIndex: "accountCountry",
      key: "country",
    });
  }

  columnsToReturn.push({
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text, record) => {
      const isApproved = record.status === "approved";
      const isRejected = record.status === "rejected";
      const isDraft = record.status === "draft";
      const isReviewRequired = record.status === "requires_review";
      const isOther = !isApproved && !isRejected && !isDraft && !isReviewRequired;

      return (
        <>
          {isReviewRequired && <Tag color="volcano">Requires Review</Tag>}
          {isRejected && <Tag color="red">REJECTED</Tag>}
          {isApproved && <Tag color="green">APPROVED</Tag>}
          {isDraft && <Tag color="grey">DRAFT</Tag>}
          {isOther && record.status}
        </>
      );
    },
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  });

  return columnsToReturn;
}

const RecipientsList: FC = () => {
  const [recipientsWithPendingTransfers, setRecipientsWithPendingTransfers] = useState<IRecipient[]>([]);
  const [recipientsWithNoPendingTransfers, setRecipientsWithNoPendingTransfers] = useState<IRecipient[]>([]);
  const [recipientsAsMaster, setRecipientsAsMaster] = useState<IRecipient[]>([]);
  const [previouslyReviewedRecipients, setPreviouslyReviewedRecipients] = useState<IRecipient[]>([]);

  const [showCreateMasterRecipient, setShowCreateMasterRecipient] = useState(false);

  useEffect(() => {
    getRecipientIdsWithUnapprovedTransfers().then(recipientIdsWithPendingTransfers => 
      getRecipientsRequiringReview().then((recipients) => {
        if (recipients) {
          const foundRecipientsWithPendingTransfers = recipients.filter((recipient) =>
            recipientIdsWithPendingTransfers.includes(recipient.id) && recipient._owner !== "house"
          );
          setRecipientsWithPendingTransfers(foundRecipientsWithPendingTransfers);

          const foundRecipientsWithNoPendingTransfers = recipients.filter((recipient) =>
            !recipientIdsWithPendingTransfers.includes(recipient.id) && recipient._owner !== "house"
          );
          setRecipientsWithNoPendingTransfers(foundRecipientsWithNoPendingTransfers);
        }
      })
    );
  }, []);

  useEffect(() => {
    getApprovedRecipients().then((res) => {
      if (res) {
        setPreviouslyReviewedRecipients(res);
      }
    });
  }, []);

  useEffect(() => {
    getMasterRecipients().then((res) => {
      if (res) {
        setRecipientsAsMaster(res);
      }
    });
  }, []);

  return (
    <div>
      {showCreateMasterRecipient && (
        <CreateMasterRecipientForm
          onClose={() => setShowCreateMasterRecipient(false)}
          isVisible={!!showCreateMasterRecipient}
        />
      )}
      <div>
        <Table
          title={() => <Title level={2}>Recipients WITH pending Transfers Requiring Review</Title>}
          columns={recipientsColumns()}
          dataSource={recipientsWithPendingTransfers}
        />
      </div>
      <div>
        <Table
          title={() => <Title level={2}>Recipients Requiring Review</Title>}
          columns={recipientsColumns()}
          dataSource={recipientsWithNoPendingTransfers}
        />
      </div>
      <div>
        <Table
          title={() =>
          <Title level={2}>
            MASTER Recipients for Sanction Reviews
            <Button
              type="primary"
              style={{ margin: "8px 0 0 0", float: "right" }}
              onClick={() => setShowCreateMasterRecipient(true)}
            >
              Create a MASTER Recipient
            </Button>
          </Title>          
        }
          columns={recipientsColumns(true)}
          dataSource={recipientsAsMaster}
        />

      </div>
      <div>
        <Table
          title={() => <Title level={2}>Recipients Previously Reviewed</Title>}
          columns={recipientsColumns()}
          dataSource={previouslyReviewedRecipients}
        />
      </div>
    </div>
  );
};

export default RecipientsList;
