import { FC, useState, useEffect, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Drawer,
  Col,
  Row,
  Typography,
  message,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

import "antd/dist/antd.css";

import db from 'services/firestore';
import { Firebase } from "services";
import { ICheck } from "types";
import { openQueryWithTimestamp } from "utils";

const { Option } = Select;
const { Text } = Typography;


const getCheckFilesData = async (entityId, checkId) => {
  const data = await db
    .collection("entities")
    .doc(entityId)
    .collection("onboardingFiles")
    .where("parentId", "==", checkId)
    .get()
    .then((query) => openQueryWithTimestamp(query, ["_created"]));

  return data;
};

interface OwnProps {
  check: ICheck;
  entityId: string;
  isVisible: boolean;
  onClose: (...args: any) => void;
  updateCheck: (check: ICheck, values: any, files: any) => Promise<void>;
}

const EditCheck: FC<OwnProps> = ({
  check,
  isVisible,
  entityId,
  onClose,
  updateCheck,
}) => {
  const [existingFiles, setExistingFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const checkForm = Form.useForm()[0];
  const fileInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (check) {
      checkForm.setFieldsValue({ ...check });
    }
  }, [checkForm, check]);

  useEffect(() => {
    if (check) {
      getCheckFilesData(entityId, check.id).then((data) =>
        setExistingFiles(
          data.map((item) => ({ ...item, url: item.location, status: "done" }))
        )
      );
    }
  }, [check, entityId]);

  return (
    <Drawer
      title={`Edit check`}
      width={720}
      onClose={() => {
        existingFiles
          .filter((item) => item.forUpload)
          .forEach((item) => {
            Firebase.removeCompanyFile({
              entityId: entityId,
              // @ts-ignore
              filename: item.name,
            });
          });

        setExistingFiles([]);
        checkForm.resetFields();
        onClose();
      }}
      open={isVisible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        form={checkForm}
        layout="vertical"
        requiredMark={false}
        onFinish={async ({ fileUpload, ...values }) => {
          try {
            setIsLoading(true);
            await updateCheck(
              check,
              values,
              existingFiles.filter((item) => item.forUpload || item.forDelete)
            );

            setExistingFiles([]);
            checkForm.resetFields();
            onClose();
          } catch (error) {
            console.warn(error);
          } finally {
            setIsLoading(false);
          }
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="_source"
              label="Type of Check"
              rules={[{ required: true, message: "Please choose the type" }]}
            >
              <Select placeholder="Please choose the type">
                <Option value="onfido_id">Onfido ID Check</Option>
                <Option value="sanction">Sanction Check</Option>
                <Option value="pep">PEP Check</Option>
                <Option value="sanction_pep">Sanction+Pep Check</Option>
                <Option value="adverse_media">Adverse Media</Option>
                <Option value="dd_refresh">DD Refresh</Option>
                <Option value="other">Other</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="runType"
              label="How is the check run"
              rules={[{ required: true, message: "Please select run type" }]}
            >
              <Select placeholder="Please select type of run">
                <Option value="manual">Manually</Option>
                <Option value="auto">Run from here</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="_resultsUrl"
              label="Url to Check or Report"
              rules={[{ required: false, message: "Please enter url" }]}
            >
              <Input
                style={{ width: "100%" }}
                placeholder="Please enter a url to the check/report or add them as attachments below"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="_status"
              label="Status"
              rules={[{ required: true, message: "Please select the status" }]}
            >
              <Select placeholder="Please select the status">
                <Option value="unfinished">Unfinished</Option>
                <Option value="clear">Clear</Option>
                <Option value="consider">Consider</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input.TextArea
                rows={4}
                placeholder="Optionally enter any notes or description here"
              />
            </Form.Item>
          </Col>
        </Row>

        <Button
          style={{
            marginBottom: 16,
          }}
          icon={<UploadOutlined />}
          onClick={() => {
            fileInputRef.current.click();
          }}
        >
          Upload new file
        </Button>
        <input
          ref={fileInputRef}
          type="file"
          multiple
          hidden
          onChange={async (event) => {
            const files = event.target.files;

            Array.from(files).forEach((file) => {
              const isFileExists =
                existingFiles.findIndex(
                  // @ts-ignore
                  (item) => item.name === file.name
                ) >= 0;

              if (isFileExists) {
                message.error(
                  `File with this name ${file.name} is already exists`
                );
                return;
              }

              setExistingFiles((prevState) => [
                ...prevState,
                { name: file.name, value: file, forUpload: true },
              ]);
            });

            event.target.value = null;
          }}
        />

        {existingFiles
          .filter((item) => !item.forDelete)
          .map((file, index) => {
            const isFirst = index === 0;
            return (
              <Row
                key={file.name}
                justify="space-between"
                align="middle"
                style={{
                  height: 60,
                  borderBottom: "1px solid #d3caca",
                  borderTop: isFirst ? "1px solid #d3caca" : "none",
                }}
              >
                <Text>{file.name}</Text>

                <Button
                  onClick={async () => {
                    setExistingFiles((prevState) =>
                      prevState.map((item) => {
                        if (item.name === file.name) {
                          return { ...item, forDelete: true };
                        }

                        return item;
                      })
                    );
                  }}
                  danger
                >
                  Remove
                </Button>
              </Row>
            );
          })}

        <Form.Item>
          <div
            style={{
              textAlign: "right",
              marginTop: 16,
            }}
          >
            <Button
              onClick={() => {
                setExistingFiles([]);
                checkForm.resetFields();
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button disabled={isLoading} htmlType="submit" type="primary">
              Update
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default EditCheck;
