import { message } from "antd";
import { AxiosPrivateFirebaseInstance } from "settings";
import db from "services/firestore";
import { IWriteEventLog } from "pages/WriteLogDetails/interfaces";
import { openQueryWithTimestamp } from "utils";

const COLLECTION_NAME = "writeEvents";
const SUB_COLLECTION_NAME = "allAttempts";

export const toggleRootActionIgnore = async ({
  entityId, actionId, ignore
}:{
  entityId: string, actionId: string, ignore: boolean
}) => {
  await AxiosPrivateFirebaseInstance.put(
    `/admin_actions/integrations/${entityId}/write-retries/${actionId}`,
    {
      ignore,
    }
  )
    .then((res: any) => {
      if (res?.data?.success) {
        message.success(
          "Success"
        );
      } else {
        message.error(res?.data?.error);
      }
    })
    .catch((err) => {
      console.log(err);
      message.error(err.message);
    });
};

export const toggleSubActionIgnore = async ({
  entityId, parentActionId, ignore, docId
} : {
  entityId: string, parentActionId: string, docId: string, ignore: boolean
}) => {
  await AxiosPrivateFirebaseInstance.put(
    `/admin_actions/integrations/${entityId}/write-retries/${parentActionId}/sub-actions/${docId}`,
    {
      ignore,
    }
  )
    .then((res: any) => {
      if (res?.data?.success) {
        message.success(
          "Success"
        );
      } else {
        message.error(res?.data?.error);
      }
    })
    .catch((err) => {
      console.log(err);
      message.error(err.message);
    });
};

export const retryWriteAction = async ({
  entityId, actionId, correlationId
}:{
  entityId: string, actionId: string, correlationId?: string
}) => {
  await AxiosPrivateFirebaseInstance.get(
    `/admin_actions/integrations/${entityId}/write-retries/${actionId}/retry`, {headers: {correlationId}}
  )
    .then((res: any) => {
      if (res?.data?.success) {
        message.success(
          "Success"
        );
      } else {
        message.error(res?.data?.error);
      }
    })
    .catch((err) => {
      console.log(err);
      message.error(err.message);
    });
};

export const retryWriteSubAction = async ({
  entityId, parentActionId, docId
}:{
  entityId: string, parentActionId: string, docId: string
}) => {
  await AxiosPrivateFirebaseInstance.get(
    `/admin_actions/integrations/${entityId}/write-retries/${parentActionId}/sub-actions/${docId}/retry`
  )
    .then((res: any) => {
      if (res?.data?.success) {
        message.success(
          "Success"
        );
      } else {
        message.error(res?.data?.error);
      }
    })
    .catch((err) => {
      console.log(err);
      message.error(err.message);
    });
};

export const subscribeToEntityWritePreviousAttemptLogs = ({docId, callback }:{ docId: string, callback: (data: IWriteEventLog[]) => void}) => {
  try {
    const subscribe = db
      .collection(COLLECTION_NAME)
      .doc(docId)
      .collection(SUB_COLLECTION_NAME)
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ["_created"]))
      )

    return subscribe
  } catch (error) {
    console.log(error);
  }
};

export const subscribeToEntityWriteLogs = ({entityId, callback, showIgnored, showOnlyErrors }:{ showIgnored: boolean, showOnlyErrors: ('other' | 'permission' | 'notImplemented')[], entityId?: string, callback: (data: IWriteEventLog[]) => void}) => {
  try {
    let subscribe: any = db.collection(COLLECTION_NAME)

    if(entityId !== undefined){
      subscribe = subscribe.where('entityId', '==', entityId)
    }

    if (showIgnored === false){
      subscribe = subscribe.where("ignore", "==", false)
    }

    if (showOnlyErrors.includes('other') && showOnlyErrors.includes('permission')){
      subscribe = subscribe.where("status", 'in', ['error', 'permission-error'])
    } else if (showOnlyErrors.includes('other')){
      subscribe = subscribe.where("status", "==", 'error')
    } else if (showOnlyErrors.includes('permission')){
      subscribe = subscribe.where("status", "==", 'permission-error')
    }

    if (showOnlyErrors.includes('notImplemented')){
      subscribe = subscribe.where("errorCode", "==", 'UNSUPPORTED_ERROR')
    } else {
      subscribe = subscribe.where("errorCode", "!=", 'UNSUPPORTED_ERROR')
    }

    subscribe = subscribe.onSnapshot((query) =>
      callback(openQueryWithTimestamp(query, ["_created"]))
    )

    return subscribe
  } catch (error) {
    console.log(error);
  }
};
