import { Button, Popover } from "antd"
import { useState } from "react"
import { Firebase } from "services"

export const HideShowStringifiedObject: React.FC<{data?: any}> = ({data}) => {
  if(data?.connection){
    delete data.connection
  }

  const stringifiedData = JSON.stringify(data) || '';
  const minimalData = stringifiedData.length < 10 ? stringifiedData : stringifiedData.slice(0, 10) + '...';
  const [showFull, setShowFull] = useState(false);

  return (
    <>
      {showFull ? stringifiedData : minimalData}
      <Button style={{marginLeft: 5}}onClick={() => setShowFull(!showFull)}>{showFull ? 'hide' : 'show'}</Button>
    </>
  )
}

export const writeEventLogColumns = [
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
  },
  {
    title: "Attempts",
    dataIndex: ["message","attemptNumber"],
    key: "message.attemptNumber",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status, record) => status !== 'error' ? status : <Popover content={JSON.stringify(record.error, null, 2)} title="Error object">
      <Button>error</Button>
    </Popover>
  },
  {
    title: "errorCode",
    dataIndex: "errorCode",
    key: "errorCode",
    render: (errorCode) => errorCode ?? ''
  },
  {
    title: "eventId",
    dataIndex: "eventId",
    key: "eventId",
    render: (eventId) => eventId
  },
  {
    title: "Instructions",
    dataIndex: "instructions",
    key: "instructions",
    render: (data) => {
      return <HideShowStringifiedObject data={data} />
    }
  },
  {
    title: "Message",
    dataIndex: "message",
    key: "message",
    render: (data) => {
      return <HideShowStringifiedObject data={data} />
    }
  },
  {
    title: "Requestee",
    dataIndex: "requesteeEmail",
    key: "requesteeEmail",
  },
  {
    title: "ignored",
    dataIndex: "ignore",
    key: "ignore",
    render: (ignore) => ignore === false ? 'false' : 'true',
  },
  {
    title: "actions",
    key: "actions",
    render: (record) => (
      <>
        <Button style={{marginLeft: "10px"}} onClick={() => Firebase.toggleSubActionIgnore({entityId: record.entityId, parentActionId: record.parentActionId, docId: record.id, ignore: !!!record.ignore})}>Toggle ignore</Button>
        <Button style={{marginLeft: "10px"}} onClick={() => Firebase.retryWriteSubAction({entityId: record.entityId, parentActionId: record.parentActionId, docId: record.id})} >Re run</Button>
      </>
    )
  }
]