import { FC, useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Drawer,
  Col,
  Row,
  Typography,
  Switch,
  message,
} from "antd";

import "antd/dist/antd.css";

import { Firebase } from "services";

const { Option } = Select;
const { Title } = Typography;


interface OwnProps {
  onboardingItem: any;
  entityId: string;
  isVisible: boolean;
  onClose: (...args: any) => void;
}

const EditOnboardingRecord: FC<OwnProps> = ({
  onboardingItem,
  isVisible,
  entityId,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [action, setAction] = useState(null);

  const form = Form.useForm()[0];

  useEffect(() => {
    if (onboardingItem) {
      form.setFieldsValue({ ...onboardingItem });
    }
  }, [form, onboardingItem]);

  const onExecuteAction = async () => {
    if (!action) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await Firebase.executeActionOnOnboarding({
        entityId,
        onboardingId: onboardingItem.id,
        action,
      });

      if (response.data.success) {
        message.success(`Action "${action}" executed successfully`);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onUpdateOnboardingData = async ({
    name,
    email,
    dob,
    isPsc,
    role,
    requiresVerification,
    address,
    isDeprecated,
    description,
  }) => {
    setIsLoading(true);

    try {
      const response = await Firebase.executeActionOnOnboarding({
        entityId,
        onboardingId: onboardingItem.id,
        action: "update",
        updateData: {
          name,
          email,
          dob,
          isPsc,
          role,
          requiresVerification,
          address,
          isDeprecated,
          description,
        },
      });

      if (response.data.success) {
        message.success(
          `Updated onboarding with ID ${onboardingItem.id} successfully`
        );
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      title={`Edit onboarding record details`}
      width={720}
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      open={isVisible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={async ({ fileUpload, ...values }) => {
          onUpdateOnboardingData(values);
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="name" label="Name">
              <Input style={{ width: "100%" }} placeholder="Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="email" label="Email">
              <Input style={{ width: "100%" }} placeholder="Email" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="dob" label="Date of birth">
              <Input style={{ width: "100%" }} placeholder="yyyy-mm-dd" />
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item name="role" label="Role">
              <Input style={{ width: "100%" }} placeholder="Role" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={9}>
            <Form.Item name="isPsc" label="Is PSC" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Form.Item
              name="requiresVerification"
              label="Requires Verification"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="isDeprecated" label="Deprecated" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name={["address", "addressLine1"]} label="Address">
              <Input style={{ width: "100%" }} placeholder="Address" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["address", "city"]} label="City">
              <Input style={{ width: "100%" }} placeholder="Address" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name={["address", "postalCode"]} label="Post Code">
              <Input style={{ width: "100%" }} placeholder="N1 1AB" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={["address", "country"]} label="Country code">
              <Input style={{ width: "100%" }} placeholder="e.g. gb" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input.TextArea
                rows={4}
                placeholder="Optionally enter any notes or description here"
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <div
            style={{
              textAlign: "right",
              marginTop: 16,
            }}
          >
            <Button
              onClick={() => {
                form.resetFields();
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button disabled={isLoading} htmlType="submit" type="primary">
              Update
            </Button>
          </div>
        </Form.Item>
      </Form>

      <Title level={5}>Actions</Title>

      <Row justify="space-between">
        <Select
          placeholder="Choose desired action"
          style={{
            flex: 1,
            marginRight: 16,
          }}
          onChange={(value) => setAction(value)}
        >
          <Option value="resend">Re-send ID Verification email</Option>
          <Option value="newid">
            Create a new ID-check (+send ID Verification email)
          </Option>
          <Option value="newidphoto">
            Create a new ID-check and Photo-check (+send ID Verification email)
          </Option>
        </Select>

        <Button
          disabled={isLoading || !action}
          type="primary"
          onClick={onExecuteAction}
        >
          Execute
        </Button>
      </Row>
    </Drawer>
  );
};

export default EditOnboardingRecord;
