import { FC, useState } from "react";
import {
  Form,
  Button,
  Drawer,
  message,
} from "antd";

import "antd/dist/antd.css";

import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import ace from "brace";
import "brace/mode/json";
import "brace/theme/github";

import { Firebase } from "services";

interface OwnProps {
  onBehalfOfId: string;
  isVisible: boolean;
  onClose: (...args: any) => void;
}

const DemoFunding: FC<OwnProps> = ({
  isVisible,
  onBehalfOfId,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [demoFundingInstructions, setDemoFundingInstructions] = useState({
    onBehalfOfId: onBehalfOfId,
    currency: 'GBP',
    amount: 1000,
    sender: {
      sender_name: 'Vertex',
      sender_address: '123 Doe Drive',
      sender_country: 'US',
      sender_reference: 'Inv006',
      sender_account_number: 'IB325343534kl353X4',
      sender_routing_code: '1357908643',
    },
    action: 'approve',
  });

  const form = Form.useForm()[0];

  const onSendFakeFunding = async () => {
    setIsLoading(true);

    try {
      const response = await Firebase.createFakeFunding({
        fakeFunding: demoFundingInstructions
      });

      if (response.success) {
        message.success(
          `Created fake funding successfully`
        );
        form.resetFields();
        onClose();
      } else {
        message.error(response.message);
      }
    } catch (error) {
      message.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      title={`Create a Fake Funding`}
      width={720}
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      visible={isVisible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        onFinish={async ({ ...values }) => {
          onSendFakeFunding();
        }}
      >
        <span style={{color: "red"}}>sender_account_number should always be different</span>
            <Form.Item>
              <Editor
                ace={ace}
                theme="ace/theme/github"
                value={demoFundingInstructions}
                mode="code"
                onChange={(data) => {
                  setDemoFundingInstructions(data);
                }}
              />
            </Form.Item>

        <Form.Item>
          <div
            style={{
              textAlign: "right",
              marginTop: 16,
            }}
          >
            <Button
              onClick={() => {
                form.resetFields();
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button disabled={isLoading} htmlType="submit" type="primary">
              Create
            </Button>
          </div>
        </Form.Item>
      </Form>

    </Drawer>
  );
};

export default DemoFunding;
