import { FC, useState, useEffect, Fragment } from "react";
import { Button, message, Table, Typography } from "antd";

import { openQueryWithTimestamp, toCurrencyNumber } from "utils";

import db from "services/firestore";
import {
  AmountText,
  AmountWithAvailableAmountText,
  BalancesContentWrapper,
  LedgerAmountText,
  Separator,
} from "./Balances.styles";
import { Link } from "react-router-dom";
import { refreshBalances } from "services/firebase";
import { ICurrentBalanceDoc } from "types/balances";

const subscribeToBalances = (entityId: string, callback) => {
  try {
    const subscribe = db
      .collection("currentBalances")
      .where("_owner", "==", entityId)
      .onSnapshot((query) =>
        callback(
          openQueryWithTimestamp(query, [
            "_created",
            "_updated",
          ]) as ICurrentBalanceDoc[]
        )
      );

    return subscribe;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

const { Title } = Typography;

interface IOwnProps {
  entityId?: string;
}

const Balances: FC<IOwnProps> = ({ entityId }) => {
  const [balances, setBalances] = useState<ICurrentBalanceDoc[]>([]);
  const [isRefreshingBalances, setIsRefreshingBalances] = useState(false);

  useEffect(() => {
    if (entityId) {
      const unsubscribeToBalances = subscribeToBalances(entityId, (balances) =>
        setBalances(
          balances.map((balance) => ({
            ...balance,
            _created: balance._created.toISOString(),
          }))
        )
      );

      return () => {
        unsubscribeToBalances && unsubscribeToBalances();
      };
    }
  }, [entityId]);

  const onRefreshBalances = async () => {
    try {
      setIsRefreshingBalances(true);

      await refreshBalances(entityId);
    } catch (error) {
      console.warn("Failed to refresh balances. Error: ", error);
      message.error(error);
    } finally {
      setIsRefreshingBalances(false);
    }
  };

  const currencyCloudBalances = balances.filter(
    (balance) => balance.source === "currencyCloud"
  );
  const nonCurrencyCloudBalances = balances.filter(
    (balance) => balance.source !== "currencyCloud"
  );

  return (
    <>
      <Title level={3}>Balances (CurrencyCloud)</Title>
      <BalancesContentWrapper>
        {currencyCloudBalances.length ? (
          currencyCloudBalances.map((balance, index) => {
            if (balance.availableAmount !== undefined) {
              const actualAmountFromLedger = balance.actualAmount || 0;
              const isDiscrepancyInActualAmounts =
                Math.abs(balance.amount - actualAmountFromLedger) > 0.01;
              const discrepancyStyle = isDiscrepancyInActualAmounts
                ? { color: "red" }
                : {};
              return (
                <Fragment key={balance.id}>
                  {index > 0 && <Separator />}
                  <div>
                    <span style={discrepancyStyle}>
                      <AmountWithAvailableAmountText>
                        {balance.currency} {toCurrencyNumber(balance.amount)}
                      </AmountWithAvailableAmountText>
                    </span>
                    <LedgerAmountText>
                      Available {toCurrencyNumber(balance.availableAmount)}
                    </LedgerAmountText>
                    <span style={discrepancyStyle}>
                      <LedgerAmountText>
                        Physical {toCurrencyNumber(balance.actualAmount || 0)}
                      </LedgerAmountText>
                    </span>
                  </div>
                </Fragment>
              );
            } else {
              return (
                <Fragment key={balance.id}>
                  {index > 0 && <Separator />}

                  <AmountText>
                    {balance.currency} {toCurrencyNumber(balance.amount)}
                  </AmountText>
                </Fragment>
              );
            }
          })
        ) : (
          <h5>Balances are empty</h5>
        )}
        <Button style={{ marginLeft: "30px" }} onClick={onRefreshBalances}>
          {isRefreshingBalances ? "Loading..." : "Refresh balances"}
        </Button>
        <Separator />
        <Link to={`/app/ledger/${entityId}`}>Show ledger</Link>
      </BalancesContentWrapper>

      <Table
        dataSource={nonCurrencyCloudBalances}
        title={() => <Title level={4}>Other balances</Title>}
      >
        <Table.Column<ICurrentBalanceDoc>
          title="Currency"
          dataIndex="currency"
          sorter={(a, b) => a.currency.localeCompare(b.currency)}
        />
        <Table.Column<ICurrentBalanceDoc>
          title="Amount"
          dataIndex="amount"
          sorter={(a, b) => a.amount - b.amount}
        />
        <Table.Column title="Account name" dataIndex="name" />
        <Table.Column<ICurrentBalanceDoc>
          title="Source"
          dataIndex="source"
          sorter={(a, b) => a.source.localeCompare(b.source)}
        />
        <Table.Column title="Source system id" dataIndex="sourceSystemId" />
        <Table.Column<ICurrentBalanceDoc>
          title="Created at"
          dataIndex="_created"
          sorter={(a, b) => a._created.localeCompare(b._created)}
        />
      </Table>
    </>
  );
};

export default Balances;
