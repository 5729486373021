import { IAdminUser } from "./../../types/index";
import { IResponse } from "types";
import { AxiosPrivateFirebaseInstance } from "settings";

export interface IUpdateAdminUserRoles {
  userId: IAdminUser["uid"];
  roles: IAdminUser["customClaims"];
}
export const updateAdminUserRoles = async (payload: IUpdateAdminUserRoles) =>
  AxiosPrivateFirebaseInstance.post<IResponse>(
    "/admin_actions/users/update_roles",
    payload
  );

export const getAdminUsers = async () =>
  AxiosPrivateFirebaseInstance.get<IResponse<IAdminUser[]>>(
    `/admin_actions/users`
  );
