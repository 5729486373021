import dayjs from 'dayjs';

import { Button } from 'antd';
import 'antd/dist/antd.css';

import { Link } from 'react-router-dom';
import { Firebase } from 'services';

export const getIntegrationColumns = (entity_id: string) => [
  {
    title: "System",
    dataIndex: "system",
    key: "system",
    render: (text, record) => {
      return (
        <span>
          {text}{record.environmentName && ` (${record.environmentName})`}
        </span>
      );
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (_, record) => {
      return (
        <>
          {record.status}
        </>
      )
    }
  },
  {
    title: "Doc Last Updated",
    dataIndex: "_updated.seconds",
    key: "_updated.seconds",
    render: (_, record) => {
      return (
        <>
        {record._updated?._seconds && 
          dayjs.unix(record._updated._seconds).format("DD MMM YYYY HH:mm:ss")}
        </>
      );
    }
  },
  {
    title: "Access Token Next Expiry",
    dataIndex: "accessCodeExpiryTimestamp",
    key: "accessCodeExpiryTimestamp"
  },
  {
    title: '',
    dataIndex: '',
    key: 'loadData',
    render: (text, record: Firebase.IIntegrationBase) => {
      return (
        <>
          <Button style={{marginLeft: "10px"}} onClick={() => Firebase.reloadIntegrationData(entity_id)} >Refresh data</Button>
          {' '}
          <Link to={`/app/integration-detail/${entity_id}`}>See integration settings</Link>
        </>
      );
    },
  },
];

