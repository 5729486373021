import { TRoles } from "types";

export type TAdminRole = "owner" | "admin" | "itadmin" | "compliance";

export type TAdminPageKey =
  // all uses to give full access
  | "all"
  | "overview"
  | "house-account"
  | "entities"
  | "entity-detail"
  | "integration-detail"
  | "ledger"
  | "onboarding"
  | "onboarding-detail"
  | "write-log-detail"
  | "recipients"
  | "recipient-detail"
  | "senders"
  | "sender-detail"
  | "funding"
  | "tracking"
  | "asset-view"
  | "users"
  | "users-logs"
  | "contract"
  | "transfer"
  | "bulk-payment"
  | "reports"
  | "reference-data"
  | "feature-flags"
  | "ob-data"
  | "settings";

export interface IPageAccess {
  full?: TAdminRole[];
  read?: TAdminRole[];
}

export type TRoleBasedAdminPageAccess = Record<TAdminPageKey, IPageAccess>;

export const checkPermissions = ({
  pageKey,
  roles,
  roleBasedAdminPageAccess,
}: {
  pageKey: TAdminPageKey;
  roles?: TAdminRole[];
  roleBasedAdminPageAccess?: TRoleBasedAdminPageAccess;
}) => {
  let globalFullPermission = false,
    globalReadPermission = false,
    fullPermissionPerPage = false,
    readPermissionPerPage = false;

  roles?.forEach((role) => {
    if (roleBasedAdminPageAccess?.["all"]?.full?.includes(role)) {
      globalFullPermission = true;
    }
    if (roleBasedAdminPageAccess?.["all"]?.read?.includes(role)) {
      globalReadPermission = true;
    }
    if (roleBasedAdminPageAccess?.[pageKey]?.full?.includes(role)) {
      fullPermissionPerPage = true;
    }
    if (roleBasedAdminPageAccess?.[pageKey]?.read?.includes(role)) {
      readPermissionPerPage = true;
    }
  });

  return {
    allowRender:
      globalFullPermission ||
      globalReadPermission ||
      fullPermissionPerPage ||
      readPermissionPerPage,
    allowWrite: globalFullPermission || fullPermissionPerPage,
  };
};

export const getRolesFromClaims = (claims?: any): TRoles => {
  const rolesToReturn = [];

  if (claims?.owner) {
    rolesToReturn.push("owner");
  }

  if (claims?.admin) {
    rolesToReturn.push("admin");
  }

  if (claims?.itadmin) {
    rolesToReturn.push("itadmin");
  }

  if (claims?.compliance) {
    rolesToReturn.push("compliance");
  }

  return rolesToReturn;
};

export const validateUserClaims = (claims: any) => {
  const isValidDomain = claims.email.endsWith("@hedgeflows.com");
  const isValidClaims =
    claims.owner || claims.admin || claims.itadmin || claims.compliance;

  return isValidDomain && isValidClaims;
};
