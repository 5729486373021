import moment from 'moment';
import { Button, DatePicker, DatePickerProps, Modal } from 'antd';
import { useState } from 'react';
import { Firebase } from 'services';
import { message } from "antd";


interface IOwnProps {
  bulkPaymentId: string;
  entityId: string; 
  currentDate: string;
}
export const BulkPaymentChangeScheduledPaymentDate: React.FC<IOwnProps> = ({bulkPaymentId, entityId, currentDate}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [date, setDate] = useState<string>()
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onEditPaymentDateHandler = () => {
    setIsModalOpen(true);
  }

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };


  const onSubmitHandler = async () => {
    try{
      // call be with new date
      setIsSubmitting(true);
      await Firebase.updateBulkPaymentScheduledPaymentDate({entityId, date: date, bulkPaymentId});
      message.success(`Payment date changed to ${date} for bulk payment ${bulkPaymentId}`)
      setIsModalOpen(false);
    }catch(error){
      message.error(error.message);
    }finally{
      setIsSubmitting(false);
    }
  }

  const onDateChange: DatePickerProps['onChange'] = (_, dateString) => {
    setDate(
      dateString
    )
  }

  return (
    <>
      <Button onClick={onEditPaymentDateHandler}>Change Scheduled Payment Date</Button>
      <Modal title={`Select new scheduled payment date`} visible={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={800} footer={[
        <Button key="back" onClick={handleCancel}>
          Return
        </Button>,
        <Button key="submit" type="primary" loading={isSubmitting} onClick={onSubmitHandler}>
          Submit
        </Button>,
        ]}>
        <DatePicker onChange={onDateChange} defaultValue={moment(currentDate)}/>
      </Modal>
    </>
  )

}