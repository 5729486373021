import { FC, useState, useEffect } from "react";
import {
  Form,
  Button,
  Select,
  Drawer,
  Row,
  Typography,
  message,
} from "antd";

import "antd/dist/antd.css";

import { Firebase } from "services";
import { IBulkPayment, BULK_PAYMENT_STATUS } from "types";

const { Option } = Select;
const { Title } = Typography;

interface OwnProps {
  bulkPayment: IBulkPayment;
  isVisible: boolean;
  onClose: (...args: any) => void;
}

const EditBulkPayment: FC<OwnProps> = ({
  bulkPayment,
  isVisible,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(null);

  const form = Form.useForm()[0];

  useEffect(() => {
    if (bulkPayment && isVisible) {
      form.setFieldsValue(bulkPayment);
      setStatus(bulkPayment.status);
    }
  }, [form, bulkPayment, isVisible]);

  const onUpdateBulkPayment = async ({ expiryDate }) => {
    setIsLoading(true);

    try {
      const updateData: Partial<IBulkPayment> = {};
      if (status && bulkPayment.status !== status) {
        updateData.status = status;
      }
      const response = await Firebase.updateBulkPayment({
        bulkPaymentId: bulkPayment.id,
        updateData,
      });

      if (response.data.success) {
        message.success(`Bulk Payment ${bulkPayment.id} updated successfully`);
        onClose();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      title={`Update Bulk Payment Details`}
      width={720}
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      visible={isVisible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        onFinish={async ({ fileUpload, ...values }) => {
          // submit logic here
          onUpdateBulkPayment(values);
        }}
      >
        <Title level={5}>Status</Title>
        <Row justify="space-between">
          <Select
            value={status}
            placeholder="Choose desired action"
            style={{
              flex: 1,
              marginRight: 16,
            }}
            onChange={(value) => setStatus(value)}
          >
            <Option value={BULK_PAYMENT_STATUS.awaitingPayment}>Awaiting Payment</Option>
            <Option value={BULK_PAYMENT_STATUS.fundingInitiated}>Funding Initiated</Option>
            <Option value={BULK_PAYMENT_STATUS.funded}>Funded</Option>
            <Option value={BULK_PAYMENT_STATUS.processing}>Processing</Option>
            <Option value={BULK_PAYMENT_STATUS.completed}>Completed</Option>
            <Option value={BULK_PAYMENT_STATUS.cancelled}>Cancelled</Option>
            <Option value={BULK_PAYMENT_STATUS.transfersFailed}>Transfers Failed</Option>
          </Select>
        </Row>

        <Form.Item>
          <div
            style={{
              textAlign: "right",
              marginTop: 16,
            }}
          >
            <Button
              onClick={() => {
                form.resetFields();
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button disabled={isLoading} htmlType="submit" type="primary">
              Update
            </Button>
          </div>
        </Form.Item>
      </Form>

    </Drawer>
  );
};

export default EditBulkPayment;
