import { IContract } from "types";
import { openQueryWithTimestamp } from "utils";

import db from 'services/firestore';

export interface SubscribeToEventsParams {
  callback: (rateContracts: IContract[]) => void;
}

export const subscribeToEvents = ({ callback }: SubscribeToEventsParams) => {
  try {
    return db
      .collection("events")
      .limit(100)
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ["_created"]))
      );
  } catch (error) {
    console.warn(error);
  }
};
