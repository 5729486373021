import { action, Action, thunk, Thunk, computed, Computed } from "easy-peasy";

import { Firebase } from "services";
import { ICurrency, IContract, IResponse } from "types";

export interface RateContractsStateModel {
  rateContracts: IContract[];
  rateContractsByCurrencyPair: Computed<
    RateContractsStateModel,
    (
      sellCurrencyCode: ICurrency["code"],
      buyCurrencyCode: ICurrency["code"]
    ) => IContract[]
  >;
  rateContractById: Computed<
    RateContractsStateModel,
    (id: IContract["id"]) => IContract | null
  >;
  setState: Action<RateContractsStateModel, [string, any]>;
  subscribeToRateContracts: Thunk<
    RateContractsStateModel,
    null,
    null,
    null,
    () => void
  >;
  createRateContract: Thunk<
    RateContractsStateModel,
    Firebase.CreateRateContractPayload,
    null,
    null,
    Promise<IResponse>
  >;
}

export const RateContractsState: RateContractsStateModel = {
  rateContracts: [],
  rateContractsByCurrencyPair: computed(
    (state) => (sellCurrencyCode, buyCurrencyCode) =>
      state.rateContracts.filter(
        ({ sellCurrency, buyCurrency }) =>
          sellCurrency === sellCurrencyCode && buyCurrency === buyCurrencyCode
      ) || []
  ),
  rateContractById: computed((state) => (id) =>
    state.rateContracts.find((item) => item.id === id) || null
  ),
  setState: action((state, payload) => {
    const [prop, to] = payload;
    state[prop] = to;
  }),
  subscribeToRateContracts: thunk(({ setState }, payload) => {
    const subscriber = Firebase.subscribeToRateContracts({
      callback: (rateContracts) => setState(["rateContracts", rateContracts]),
    });

    return subscriber;
  }),
  createRateContract: thunk(async (actions, payload) => {
    const data = await Firebase.createRateContract(payload);

    return data;
  }),
};
