import { FC, useState, useEffect, useRef } from "react";
import { Button, message, Table, Typography, Modal, Form } from "antd";
import { JsonEditor as Editor } from "jsoneditor-react";
import _omit from "lodash/omit";
import "jsoneditor-react/es/editor.min.css";
import ace from "brace";
import "brace/mode/json";
import "brace/theme/github";

import {
  openQuery,
  JSONstringifyOrder,
  TCustomRouteComponentProps,
} from "utils";
import "./Markups.css";

import db from "services/firestore";

const subscribeToMarkups = (callback) => {
  try {
    const unsubscribe = db
      .collection("referenceData")
      .doc("collections")
      .collection("markups")
      .onSnapshot((query) => callback(openQuery(query)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const { Title } = Typography;

const Markups: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [markups, setMarkups] = useState([]);
  const [jsonValue, setJsonValue] = useState();
  const [isShowAddModal, setIsShowAddModal] = useState(false);
  const [isShowEditModal, setIsShowEditModal] = useState(false);
  const [selectedMarkup, setSelectedMarkup] = useState(null);
  const editEditorRef = useRef<Editor>(null);

  useEffect(() => {
    const unsubscribe = subscribeToMarkups((data) =>
      setMarkups(
        data.map((item) => {
          const values = _omit(item, [
            "_createdBy",
            "_created",
            "_updatedBy",
            "_updated",
            "_version",
            "key",
          ]);
          return JSON.parse(JSONstringifyOrder(values));
        })
      )
    );

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const addMarkup = async (values) => {
    try {
      await db
        .collection("referenceData")
        .doc("collections")
        .collection("markup")
        .doc(values.ccyPair)
        .set(values);

      message.success("New markup was added.");
    } catch (error) {
      console.log(error, "error");
    }
  };

  const updateMarkup = async (id, value) => {
    try {
      console.log("id", id);
      await db
        .collection("referenceData")
        .doc("collections")
        .collection("markups")
        .doc(id)
        .set(value, { merge: true });

      message.success(`Markup document ${id} were updated.`);
    } catch (error) {
      console.log(error, "error");
    }
  };

  const markupsColumns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    ...(allowWrite
      ? [
          {
            title: "",
            dataIndex: "",
            key: "toggleEnabledState",
            render: (record) => (
              <Button
                onClick={() => {
                  setSelectedMarkup(record);
                  setIsShowEditModal(true);
                }}
                type="primary"
              >
                Edit
              </Button>
            ),
          },
        ]
      : []),
  ];

  return (
    <div>
      <Title>Markups reference data</Title>

      <Table
        columns={markupsColumns}
        dataSource={markups.map((item) => ({ ...item }))}
        rowKey={(record) => record.id}
      />

      <Modal
        title="Add new country"
        centered
        visible={isShowAddModal}
        onCancel={() => setIsShowAddModal(false)}
        footer={null}
      >
        <Form
          layout="vertical"
          onFinish={async () => {
            await addMarkup(jsonValue);
            setIsShowAddModal(false);
          }}
        >
          <Form.Item label="Currency data">
            <Editor
              ace={ace}
              theme="ace/theme/github"
              value={jsonValue}
              mode="code"
              onChange={(data) => {
                setJsonValue(data);
              }}
            />
          </Form.Item>

          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form>
      </Modal>

      {isShowEditModal && (
        <Modal
          title="Edit markup"
          centered
          visible={isShowEditModal}
          onCancel={() => {
            setIsShowEditModal(false);
            setSelectedMarkup(null);
          }}
          footer={null}
        >
          <Form
            layout="vertical"
            onFinish={async () => {
              console.log(selectedMarkup.id);
              await updateMarkup(
                selectedMarkup.id,
                _omit(selectedMarkup, ["id", "key"])
              );
              setIsShowEditModal(false);
              setSelectedMarkup(null);
            }}
          >
            <Form.Item label={selectedMarkup?.id ?? ""}>
              <Editor
                ref={editEditorRef}
                ace={ace}
                theme="ace/theme/github"
                value={_omit(selectedMarkup, ["key"])}
                mode="code"
                onChange={(data) => {
                  setSelectedMarkup(data);
                }}
              />
            </Form.Item>

            <Button htmlType="submit" type="primary">
              Submit
            </Button>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default Markups;
