import { FC, useState, useRef } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Drawer,
  Col,
  Row,
  Typography,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";

const { Option } = Select;
const { Text } = Typography;

interface OwnProps {
  title: string;
  isVisible: boolean;
  onClose: (...args: any) => void;
  addCheck: (values: any, files: any) => Promise<void>;
}

const AddCheck: FC<OwnProps> = ({ isVisible, title, onClose, addCheck }) => {
  const [filesForUpload, setFilesForUpload] = useState([]);

  const checkForm = Form.useForm()[0];
  const fileInputRef = useRef<HTMLInputElement>();

  return (
    <Drawer
      title={title}
      width={720}
      onClose={() => {
        setFilesForUpload([]);
        checkForm.resetFields();
        onClose();
      }}
      open={isVisible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        form={checkForm}
        initialValues={{
          runType: 'manual',
        }}
        layout="vertical"
        requiredMark={false}
        onFinish={async (values) => {
          await addCheck(values, filesForUpload);

          setFilesForUpload([]);
          checkForm.resetFields();
          onClose();
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="_source"
              label="Type of Check"
              rules={[{ required: true, message: "Please choose the type" }]}
            >
              <Select placeholder="Please choose the type">
                <Option value="onfido_id">Onfido ID Check</Option>
                <Option value="sanction">Sanction Check</Option>
                <Option value="pep">PEP Check</Option>
                <Option value="sanction_pep">Sanction+Pep Check</Option>
                <Option value="adverse_media">Adverse Media</Option>
                <Option value="dd_refresh">DD Refresh</Option>
                <Option value="other">Other</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="runType"
              label="How is the check run"
              rules={[{ required: true, message: "Please select run type" }]}
            >
              <Select placeholder="Please select type of run">
                <Option value="manual">Manually</Option>
                <Option value="auto">Run from here</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="_resultsUrl"
              label="Url to Check or Report"
              rules={[{ required: false, message: "Please enter url" }]}
            >
              <Input
                style={{ width: "100%" }}
                placeholder="Please enter a url to the check/report or add them as attachments below"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="_status"
              label="Status"
              rules={[{ required: true, message: "Please select the status" }]}
            >
              <Select placeholder="Please select the status">
                <Option value="unfinished">Unfinished</Option>
                <Option value="clear">Clear</Option>
                <Option value="consider">Consider</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input.TextArea
                rows={4}
                placeholder="Optionally enter any notes or description here"
              />
            </Form.Item>
          </Col>
        </Row>

        <Button
          style={{
            marginBottom: 16,
          }}
          icon={<UploadOutlined />}
          onClick={() => {
            fileInputRef.current.click();
          }}
        >
          Upload new file
        </Button>
        <input
          ref={fileInputRef}
          type="file"
          multiple
          hidden
          onChange={async (event) => {
            const files = event.target.files;

            const filesArray = Array.from(files);
            const newFiles = filesArray.filter(
              (item) =>
                !filesForUpload.some((itemEx) => itemEx.name === item.name)
            );

            setFilesForUpload((prevState) => {
              return [...prevState, ...newFiles];
            });

            event.target.value = null;
          }}
        />

        {filesForUpload.map((file, index) => {
          const isFirst = index === 0;
          return (
            <Row
              key={file.name}
              justify="space-between"
              align="middle"
              style={{
                height: 60,
                borderBottom: "1px solid #d3caca",
                borderTop: isFirst ? "1px solid #d3caca" : "none",
              }}
            >
              <Text>{file.name}</Text>

              <Button
                danger
                onClick={() => {
                  setFilesForUpload((prevState) =>
                    prevState.filter((item) => item.name !== file.name)
                  );
                }}
              >
                Remove
              </Button>
            </Row>
          );
        })}

        <Form.Item>
          <div
            style={{
              textAlign: "right",
              marginTop: 16,
            }}
          >
            <Button
              onClick={() => {
                setFilesForUpload([]);
                checkForm.resetFields();
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button htmlType="submit" type="primary">
              Submit
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddCheck;
