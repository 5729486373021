import React, { FC, useState, useEffect } from "react";
import { Badge, Button, Descriptions, Table, Typography } from "antd";

import dayjs from "dayjs";
import "./Contract.css";
import {
  toCurrencyNumber,
  openDoc,
  openQueryWithTimestamp,
  parseRateWithPrecision,
  convertFireStoreTimestampToDateString,
  TCustomRouteComponentProps,
} from "utils";
import { useParams } from "react-router";
import { Link } from "react-router-dom";

import db from "services/firestore";
import {
  CONTRACT_STATUS,
  IContract,
  IEntity,
  IInvoice,
  INVOICE_STATUS,
  ITransfer,
  TRANSFER_STATUS,
} from "types";
import EditContract from "./components/EditContract";
import DocViewer from "pages/Overview/components/DocViewer";

const subscribeToContract = (contractId, callback) => {
  try {
    const unsubscribe = db
      .collection("rateContracts")
      .doc(contractId)
      .onSnapshot((doc) => callback(openDoc(doc)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToTransfers = (contractId, callback) => {
  try {
    const unsubscribe = db
      .collection("transfers")
      .where("contractId", "==", contractId)
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ["_created"]))
      );

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToInvoices = (contractId, callback) => {
  try {
    const unsubscribe = db
      .collection("invoices")
      .where("contractId", "==", contractId)
      .onSnapshot((query) =>
        callback(openQueryWithTimestamp(query, ["_created"]))
      );

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const subscribeToEntity = (entityId, callback) => {
  try {
    const unsubscribe = db
      .collection("entities")
      .doc(entityId)
      .onSnapshot((doc) => callback(openDoc(doc)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const { Title } = Typography;

const transfersColumns = [
  {
    title: "Created",
    dataIndex: "_created",
    key: "_created",
    render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
  },
  {
    title: "Sell",
    dataIndex: "sell",
    key: "sell",
    render: (text, record: ITransfer) => {
      return (
        <div className="currencyAmount">
          {toCurrencyNumber(record.sellAmount)}&nbsp;
          <b>{record.sellCurrency}</b>
        </div>
      );
    },
  },
  {
    title: "Buy",
    dataIndex: "buy",
    key: "buy",
    render: (text, record: ITransfer) => {
      return (
        <div className="currencyAmount">
          {toCurrencyNumber(record.buyAmount)}&nbsp;<b>{record.buyCurrency}</b>
        </div>
      );
    },
  },
  {
    title: "Funding required",
    dataIndex: "payAmount",
    key: "payAmount",
    render: (text, record: ITransfer) => {
      return (
        <div className="currencyAmount">
          {toCurrencyNumber(record.payAmount)}&nbsp;<b>{record.sellCurrency}</b>
        </div>
      );
    },
  },
  {
    title: "Pay by (GMT)",
    dataIndex: "status",
    key: "status",
    render: (text, record: ITransfer) =>
      `${record.payByDate} ${record.payByTimeGMT}`,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text, record: ITransfer) => {
      let status = record.status.toString();
      status === TRANSFER_STATUS.awaitingPayment &&
        (status = "Awaiting Payment");
      status === TRANSFER_STATUS.paymentSent && (status = "Sent");
      return <>{status}</>;
    },
  },
  {
    title: "Recipient ID",
    dataIndex: "recipientId",
    key: "recipientId",
    render: (text, record: ITransfer) => {
      return (
        <span>
          <Link to={"/app/recipient-detail/" + record.recipientId}>{text}</Link>
        </span>
      );
    },
  },
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    render: (text, record) => {
      return (
        <span>
          <Link to={"/app/transfer/" + record.id}>{text}</Link>
        </span>
      );
    },
  },
  {
    title: "Entity ID",
    dataIndex: "_owner",
    key: "_owner",
    render: (text, record) => {
      return (
        <span>
          <Link to={"/app/entity-detail/" + record._owner}>{text}</Link>
        </span>
      );
    },
  },
];

const getInvoiceColumns = (setDocToView) => {
  const InvoicesColumns = [
    {
      title: "Created",
      dataIndex: "_created",
      key: "_created",
      render: (date) => dayjs(date).format("DD MMM YYYY HH:mm:ss"),
    },
    {
      title: "Original Amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (text, record: IInvoice) => {
        return (
          <div className="currencyAmount">
            {toCurrencyNumber(record.totalAmount)}&nbsp;<b>{record.currency}</b>
          </div>
        );
      },
    },
    {
      title: "Due Amount",
      dataIndex: "amountDue",
      key: "amountDue",
      render: (text, record: IInvoice) => {
        return (
          <div className="currencyAmount">
            {toCurrencyNumber(record.amountDue)}&nbsp;<b>{record.currency}</b>
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record: IInvoice) => {
        let status = record.status.toString();
        status === INVOICE_STATUS.authorised && (status = "Authorised");
        status === INVOICE_STATUS.paymentScheduled &&
          (status = "Payment Scheduled");
        return <>{status}</>;
      },
    },
    {
      title: "Recipient ID",
      dataIndex: "contactId",
      key: "contactId",
      render: (text, record: IInvoice) => {
        return (
          <span>
            <Link to={"/app/recipient-detail/" + record.contactId}>{text}</Link>
          </span>
        );
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text, record) => {
        return (
          <span>
            <Link to="#" onClick={() => setDocToView(record)}>
              {text}
            </Link>
          </span>
        );
      },
    },
    {
      title: "Entity ID",
      dataIndex: "_owner",
      key: "_owner",
      render: (text, record) => {
        return (
          <span>
            <Link to={"/app/entity-detail/" + record._owner}>{text}</Link>
          </span>
        );
      },
    },
  ];
  return InvoicesColumns;
};

const Contract: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [entity, setEntity] = useState(null as IEntity);
  const [contract, setContractDetails] = useState(null as IContract);
  const [transfers, setTransfers] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [modifyContract, setModifyContract] = useState(false);
  const [docToView, setDocToView] = useState(null);

  // @ts-ignore
  const { contract_id } = useParams();

  useEffect(() => {
    if (contract_id) {
      const unsubscribeContract = subscribeToContract(contract_id, (data) =>
        setContractDetails(data)
      );

      const unsubscribeTransfers = subscribeToTransfers(contract_id, (data) =>
        setTransfers(data)
      );

      const unsubscribeInvoices = subscribeToInvoices(contract_id, (data) =>
        setInvoices(data)
      );

      return () => {
        unsubscribeContract?.();
        unsubscribeTransfers?.();
        unsubscribeInvoices?.();
      };
    }
  }, [contract_id]);

  useEffect(() => {
    if (contract) {
      const unsubscribe = subscribeToEntity(contract._owner, (data) =>
        setEntity(data)
      );

      return () => {
        if (unsubscribe) {
          unsubscribe();
        }
      };
    }
  }, [contract]);

  return (
    <div>
      <>
        <EditContract
          contract={contract}
          isVisible={modifyContract}
          onClose={() => setModifyContract(false)}
        />
        <DocViewer
          doc={docToView}
          isVisible={!!docToView}
          onClose={() => setDocToView(null)}
        />
      </>
      <>
        {entity && <Title>Prebook Contract Details</Title>}

        <p>
          {contract && `${contract.id} - CC ID: ${contract.externalRefs?.ccId}`}{" "}
          <br />
          {entity && (
            <>
              For{" "}
              <Link to={"/app/entity-detail/" + entity.id}>{entity.name}</Link>
            </>
          )}
        </p>
        {contract && (
          <Descriptions title="Prebook Contract Details" bordered>
            <Descriptions.Item label="Sell">
              {contract.sellCurrency} {toCurrencyNumber(contract.sellAmount)}
            </Descriptions.Item>
            <Descriptions.Item label="Number of Transfers">
              {contract.numberOfTransfers}
            </Descriptions.Item>
            <Descriptions.Item label="Flex">
              {contract.flexFeeAmount && contract.flexFeeAmount > 0
                ? `Yes (${contract.flexFeeAmount})`
                : "No"}
            </Descriptions.Item>
            <Descriptions.Item label="Notify via email">
              {contract.whenFundsArriveAction === "notifyViaEmail"
                ? "Yes"
                : "No"}
            </Descriptions.Item>
            <Descriptions.Item label="Convert and transfer">
              {contract.whenFundsArriveAction === "convertAndTransfer"
                ? "Yes"
                : "No"}
            </Descriptions.Item>
            <Descriptions.Item label="Buy">
              {contract.buyCurrency} {toCurrencyNumber(contract.buyAmount)}
            </Descriptions.Item>
            <Descriptions.Item label="Remaining Amount">
              {contract.buyCurrency}{" "}
              {toCurrencyNumber(contract.remainingBuyAmount)}
            </Descriptions.Item>
            <Descriptions.Item label="Created">
              {convertFireStoreTimestampToDateString(contract._created)}
            </Descriptions.Item>
            <Descriptions.Item label="Rate">
              {parseRateWithPrecision(contract.rate)}
            </Descriptions.Item>
            <Descriptions.Item label="Expiry Date">
              {contract.expiryDate}
            </Descriptions.Item>
            <Descriptions.Item label="Useable From">
              {contract.useableFromDate}
            </Descriptions.Item>
            <Descriptions.Item label="Status" span={3}>
              {contract.status === CONTRACT_STATUS.ready_to_use && (
                <Badge status="success" text="Ready to use" />
              )}
              {contract.status === CONTRACT_STATUS.cancelled && (
                <Badge status="error" text="Cancelled" />
              )}
              {contract.status === CONTRACT_STATUS.expired && (
                <Badge status="warning" text="Expired" />
              )}
              {contract.status !== CONTRACT_STATUS.ready_to_use &&
                contract.status !== CONTRACT_STATUS.cancelled &&
                contract.status !== CONTRACT_STATUS.expired && (
                  <Badge status="processing" text={contract.status} />
                )}
              {allowWrite && (
                <Button
                  type="primary"
                  style={{ float: "right" }}
                  onClick={() => setModifyContract(true)}
                >
                  Modify
                </Button>
              )}
            </Descriptions.Item>
          </Descriptions>
        )}

        <Table
          title={() => <Title level={2}>Transfers</Title>}
          columns={transfersColumns}
          dataSource={transfers}
        />

        <Table
          title={() => <Title level={2}>Invoices</Title>}
          columns={getInvoiceColumns(setDocToView)}
          dataSource={invoices}
        />
      </>
    </div>
  );
};

export default Contract;
