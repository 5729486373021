import { IPaymentsCounts } from "pages/EntityDetails/PaymentsCounts";
import { AxiosPrivateFirebaseInstance } from "settings";
import { IResponse } from "types";

export const getPaymentsCounts = async ({
  entityId,
  date,
}: {
  entityId: string;
  date: string;
}) =>
  AxiosPrivateFirebaseInstance.get<IResponse<IPaymentsCounts[]>>(
    `/admin_actions/payments-count/${entityId}/${date}`
  );
