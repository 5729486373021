import { FC, useState, useEffect } from "react";
import { Firebase } from "services";
import { Typography } from "antd";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import ace from "brace";
import "brace/mode/json";
import "brace/theme/github";
import { useParams } from "react-router";
import "./IntegrationDetails.css";
import { openDoc } from "utils";
import db from 'services/firestore';

const subscribeToEntity = (entityId, callback) => {
  try {
    const unsubscribe = db
      .collection("entities")
      .doc(entityId)
      .onSnapshot((doc) => callback(openDoc(doc)));

    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};

const { Title } = Typography;

const IntegrationDetails: FC = () => {
  // @ts-ignore
  const { entity_id } = useParams();
  const [entity, setEntity] = useState(null);

  const [entityIntegrationDetails, setIntegrationDetails] = useState(null);

  useEffect(() => {
    if (entity_id) {
      const unsubscribeEntity = subscribeToEntity(entity_id, (data) => {
        setEntity(data);
      });

      return () => {
        if (unsubscribeEntity) {
          unsubscribeEntity();
        }
      }
    }
  }, [entity_id]);

  useEffect(() => {
    if(entity_id){
      Firebase.getIntegrationSettings(entity_id).then((data) => setIntegrationDetails(data));
    }
  }, [entity_id]);

  return (
    <div>
      <Title>{entity?.name} integration settings</Title>

      {entityIntegrationDetails ? (<Editor
        ace={ace}
        theme="ace/theme/github"
        value={entityIntegrationDetails}
        mode="code"
        onChange={(data) => {
          if (!data) {
            setIntegrationDetails({});
            return;
          }
          
          setIntegrationDetails(data);
        }}
      />) : null}

    </div>
  );
};

export default IntegrationDetails;
