import { FC, useState, useEffect } from "react";
import { useParams } from "react-router";
import { Table, Button } from "antd";
import dayjs from "dayjs";
import Controls from "./components/Controls";
import PreviousAttemptsTable from "./components/PreviousAttemptsTable";
import _groupBy from "lodash/groupBy";

import { TCustomRouteComponentProps } from "utils";
import "antd/dist/antd.css";

import { Firebase } from "services";

import { IComputedRootWriteEventRecord, IWriteEventLog } from "./interfaces";
import {
  writeEventLogColumns,
  HideShowStringifiedObject,
} from "./tableColumns";
import { subscribeToEntityWriteLogs } from "services/firebase";

const computeStatus = (data: IWriteEventLog[]) => {
  const allPending = data.every((item) => item.status === "pending");
  const allSuccess = data.every((item) => item.status === "success");
  const allPermissionErrors = data.every(
    (item) => item.status === "permission-error"
  );
  const allErrors = data.every(
    (item) => item.status === "error" || item.status === "permission-error"
  );

  if (allPending) {
    return "all pending";
  } else if (allPermissionErrors) {
    return "all permission errors";
  } else if (allErrors) {
    return "all errors";
  } else if (allSuccess) {
    return "all success";
  }

  if (data.some((item) => item.status === "pending")) {
    return "some pending";
  }

  return "mixed";
};

const WriteLogDetails: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [writeEventLogs, setWriteEventLogs] =
    useState<IComputedRootWriteEventRecord[]>(null);
  const [showIsIgnored, setShowIsIgnored] = useState(false);

  /**
   * 1: show all errors
   * 2: show all errors
   * 3: show all errors without permissions errors
   */
  const [showOnlyErrors, setShowOnlyErrors] = useState<('other' | 'permission' | 'notImplemented')[]>(['other']);

  // @ts-ignore
  const { entity_id } = useParams();

  const callback = (data: IWriteEventLog[]) => {
    const grouped = _groupBy(data, (item) => item.parentActionId);

    // we need to add keys for correct toggling of expandable rows in antd table
    const rootRecords = Object.keys(grouped).map((key) => ({
      key,
      parentActionId: key,
      earliestCreatedAt: grouped[key].map(({ _created }) => _created).sort()[0],
      parentAction: grouped[key][0].parentAction,
      subActions: grouped[key].map((ele, index) => ({ ...ele, key: index })),
      instructions: grouped[key][0].parentInstructions,
      entityId: grouped[key][0].entityId,
      status: computeStatus(grouped[key]),
    }));

    setWriteEventLogs(rootRecords);
  };

  useEffect(() => {
    const unsubscribeToEntityWriteLogs = subscribeToEntityWriteLogs({
      showOnlyErrors: showOnlyErrors,
      showIgnored: showIsIgnored,
      entityId: entity_id,
      callback,
    });

    return () => {
      unsubscribeToEntityWriteLogs?.();
    };
  }, [entity_id, showIsIgnored, showOnlyErrors]);

  const outerTableColumns = (allowWrite: boolean) => [
    {
      title: "Entity Id",
      dataIndex: "entityId",
      key: "entityId",
    },
    {
      title: "Action",
      dataIndex: "parentAction",
      key: "parentAction",
    },
    {
      title: "Action Id",
      dataIndex: "parentActionId",
      key: "parentActionId",
    },
    {
      title: "earliest _created",
      dataIndex: "earliestCreatedAt",
      key: "earliestCreatedAt",
      render: (date) => {
        return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: "status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Instructions",
      dataIndex: "instructions",
      key: "instructions",
      width: 30,
      render: (instructions) => (
        <HideShowStringifiedObject data={instructions} />
      ),
    },
    ...(allowWrite
      ? [
          {
            title: "actions",
            key: "actions",
            render: (record) => (
              <>
                <Button
                  style={{ marginLeft: "10px" }}
                  onClick={() =>
                    Firebase.toggleRootActionIgnore({
                      entityId: record.entityId,
                      actionId: record.parentActionId,
                      ignore: false,
                    })
                  }
                >
                  Un-ignore all
                </Button>
                <Button
                  style={{ marginLeft: "10px" }}
                  onClick={() =>
                    Firebase.toggleRootActionIgnore({
                      entityId: record.entityId,
                      actionId: record.parentActionId,
                      ignore: true,
                    })
                  }
                >
                  Ignore all
                </Button>
                <Button
                  style={{ marginLeft: "10px" }}
                  onClick={() =>
                    Firebase.retryWriteAction({
                      entityId: record.entityId,
                      actionId: record.parentActionId,
                    })
                  }
                >
                  Re run
                </Button>
              </>
            ),
          },
        ]
      : []),
  ];

  const rerunAllOnClick = () => {
    writeEventLogs.forEach((record) => {
      Firebase.retryWriteAction({
        entityId: record.entityId,
        actionId: record.parentActionId,
      });
    });
  };

  return (
    <div>
      <Controls
        showIsIgnored={showIsIgnored}
        setShowIsIgnored={setShowIsIgnored}
        rerunAllOnClick={rerunAllOnClick}
        setShowErrors={setShowOnlyErrors}
        showErrors={showOnlyErrors}
        allowWrite={allowWrite}
      />
      {writeEventLogs && (
        <Table
          columns={outerTableColumns(allowWrite)}
          dataSource={writeEventLogs}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <Table
                  columns={writeEventLogColumns}
                  dataSource={record?.subActions}
                  expandable={{
                    expandedRowRender: (record) => (
                      <PreviousAttemptsTable record={record} />
                    ),
                    rowExpandable: () => true,
                  }}
                />
              );
            },
            rowExpandable: () => true,
          }}
        />
      )}

      <br />
      <br />
    </div>
  );
};

export default WriteLogDetails;
