import { Button, Menu } from "antd";
import Sider from "antd/lib/layout/Sider";
import SubMenu from "antd/lib/menu/SubMenu";
import { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { SIDEBAR_ROUTES } from "routes/variables";
import { Firebase } from "services";
import { useStoreState } from "state";
import { checkPermissions } from "utils";

const Sidebar: FC = () => {
  const userRoles = useStoreState((state) => state.UserState.user?.roles);
  const roleBasedAdminPageAccess = useStoreState(
    (state) => state.ReferenceDataState.roleBasedAdminPageAccess
  );
  const routesData = useStoreState(
    (state) => state.ReferenceDataState.routesData
  );
  const location = useLocation();
  return (
    <Sider
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
      }}
    >
      <div className="logo" />
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[`/app/${routesData.defaultPageKey}`]}
        selectedKeys={[location.pathname]}
      >
        {SIDEBAR_ROUTES.map(({ pageKey, title, subPages }) => {
          const { allowRender } = checkPermissions({
            pageKey,
            roles: userRoles,
            roleBasedAdminPageAccess,
          });

          if (!allowRender) {
            return null;
          }

          if (subPages) {
            return (
              <SubMenu key={`/app/${pageKey}`} title={title}>
                {subPages.map(({ name, subPage }) => (
                  <Menu.Item key={`/app/${pageKey}/${subPage}`}>
                    <NavLink
                      key={`/app/${pageKey}/${subPage}`}
                      to={`/app/${pageKey}/${subPage}`}
                    >
                      {name}
                    </NavLink>
                  </Menu.Item>
                ))}
              </SubMenu>
            );
          }

          return (
            <Menu.Item key={`/app/${pageKey}`}>
              <NavLink to={`/app/${pageKey}`}>{title}</NavLink>
            </Menu.Item>
          );
        })}

        <Menu.Item key="Log out">
          <Button onClick={() => Firebase.auth.signOut()}>Log out</Button>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
