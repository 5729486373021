import { AxiosPrivateFirebaseInstance } from "settings";
import { IBulkPayment, IResponse } from "types";

export const updateBulkPaymentScheduledPaymentDate = async ({
  entityId,
  date,
  bulkPaymentId,
}: {
  entityId: string;
  date: string; // YYYY-MM-DD
  bulkPaymentId: string;
}) =>
  await AxiosPrivateFirebaseInstance.post(
    `admin_actions/bulk-payments/${entityId}/${bulkPaymentId}/scheduledPaymentDate`,
    { scheduledPaymentDate: date }
  );

export interface UpdateBulkPaymentParams {
  bulkPaymentId: string;
  updateData: Partial<IBulkPayment>;
}

export const updateBulkPayment = async ({
  bulkPaymentId,
  updateData,
}: UpdateBulkPaymentParams) => {
  const response = await AxiosPrivateFirebaseInstance.put<IResponse>(
    `/admin_actions/bulk-payments/${bulkPaymentId}`,
    updateData
  );

  return response;
};
