import { FC, useState, useEffect } from "react";
import {
  Form,
  Button,
  Select,
  Drawer,
  Row,
  Typography,
  message,
} from "antd";

import "antd/dist/antd.css";

import { Firebase } from "services";
import { TRANSFER_STATUS, ITransfer } from "types";

const { Option } = Select;
const { Title } = Typography;

interface OwnProps {
  transfer: ITransfer;
  isVisible: boolean;
  onClose: (...args: any) => void;
}

const EditTransfer: FC<OwnProps> = ({
  transfer,
  isVisible,
  onClose,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(null);

  const form = Form.useForm()[0];

  useEffect(() => {
    if (transfer && isVisible) {
      form.setFieldsValue(transfer);
      setStatus(transfer.status);
    }
  }, [form, transfer, isVisible]);

  const onUpdateTransfer = async ({ expiryDate }) => {
    setIsLoading(true);

    try {
      const updateData: Partial<ITransfer> = {};
      if (status && transfer.status !== status) {
        updateData.status = status;
      }
      const response = await Firebase.updateTransfer({
        transferId: transfer.id,
        updateData,
      });

      if (response.data.success) {
        message.success(`Transfer ${transfer.id} updated successfully`);
        onClose();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      title={`Update Transfer Details`}
      width={720}
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      visible={isVisible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form
        form={form}
        layout="vertical"
        hideRequiredMark
        onFinish={async ({ fileUpload, ...values }) => {
          // submit logic here
          onUpdateTransfer(values);
        }}
      >
        <Title level={5}>Status</Title>
        <Row justify="space-between">
          <Select
            value={status}
            placeholder="Choose desired action"
            style={{
              flex: 1,
              marginRight: 16,
            }}
            onChange={(value) => setStatus(value)}
          >
            <Option value={TRANSFER_STATUS.initiated}>Initiated</Option>
            <Option value={TRANSFER_STATUS.awaitingPayment}>Awaiting Funding</Option>
            <Option value={TRANSFER_STATUS.funded}>Funded</Option>
            <Option value={TRANSFER_STATUS.approved}>Approved</Option>
            <Option value={TRANSFER_STATUS.processing}>Processing</Option>
            <Option value={TRANSFER_STATUS.paymentSent}>Sent</Option>
            <Option value={TRANSFER_STATUS.paymentCancelled}>Cancelled</Option>
            <Option value={TRANSFER_STATUS.paymentFailed}>Failed</Option>
          </Select>
        </Row>

        <Form.Item>
          <div
            style={{
              textAlign: "right",
              marginTop: 16,
            }}
          >
            <Button
              onClick={() => {
                form.resetFields();
                onClose();
              }}
              style={{ marginRight: 8 }}
            >
              Cancel
            </Button>
            <Button disabled={isLoading} htmlType="submit" type="primary">
              Update
            </Button>
          </div>
        </Form.Item>
      </Form>

    </Drawer>
  );
};

export default EditTransfer;
