import { message } from "antd";
import {
  AxiosPrivateFirebaseInstance,
  AxiosPublicFirebaseInstance,
} from "settings";
import { ICompanyRegistrationDetails, IResponse } from "types";
import { asyncForEach, Notify } from "utils";

import storage from "services/storage";

export interface GetVerificationCodeParams {
  email: string;
}

export const generateVerificationCode = async ({
  email,
}: GetVerificationCodeParams) => {
  try {
    const { data } = await AxiosPublicFirebaseInstance.post<IResponse>(
      "/code/generate",
      {
        email,
      }
    );

    if (data.success) {
      return data;
    } else {
      Notify.error(data.message);
    }
  } catch (error) {
    Notify.error(error.message);
  }
};

export interface VerifyVerificationCodeParams {
  email: string;
  code: string;
}

export const verifyVerificationCode = async ({
  email,
  code,
}: VerifyVerificationCodeParams) => {
  try {
    const { data } = await AxiosPublicFirebaseInstance.post<IResponse>(
      "/code/verify",
      {
        email,
        code,
      }
    );

    if (data.success) {
      return data;
    } else {
      Notify.error(data.message);
    }
  } catch (error) {
    Notify.error(error.message);
  }
};

export interface SearchCompanyPayload {
  searchString: string;
}

export const searchCompanies = async ({
  searchString,
}: SearchCompanyPayload) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.get<IResponse>(
      `/registration/company?q=${searchString}`
    );

    if (data.success) {
      return data;
    } else {
      Notify.error(data.message);
    }
  } catch (error) {
    Notify.error(error.message);
  }
};

export interface GetCompanyDetailsPayload {
  companyNumber: string;
}

export const getCompanyDetails = async ({
  companyNumber,
}: GetCompanyDetailsPayload) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.get<IResponse>(
      `/registration/company/${companyNumber}`
    );

    if (data.success) {
      return data;
    } else {
      Notify.error(data.message);
    }
  } catch (error) {
    Notify.error(error.message);
  }
};

export interface GetCompanyDirectorsPayload {
  companyNumber: string;
}

export const getCompanyDirectors = async ({
  companyNumber,
}: GetCompanyDirectorsPayload) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.get<IResponse>(
      `/registration/company/${companyNumber}/directors`
    );

    if (data.success) {
      return data;
    } else {
      Notify.error(data.message);
    }
  } catch (error) {
    Notify.error(error.message);
  }
};

export interface CheckVATNUmberPayload {
  number: string;
}

export const checkVATNumber = async ({ number }: CheckVATNUmberPayload) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.get<IResponse>(
      `/registration/vat/${number}`
    );

    if (data.success) {
      return data;
    } else {
      Notify.error(data.message);
    }
  } catch (error) {
    Notify.error(error.message);
  }
};

export const registerCompany = async (payload: ICompanyRegistrationDetails) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.post<IResponse>(
      `registration/company/register`,
      payload
    );

    if (data.success) {
      return data;
    } else {
      Notify.error(data.message);
    }
  } catch (error) {
    Notify.error(error.message);
  }
};

// ONFIDO
export interface CreateOnfidoApplicantPayload {
  firstName: string;
  lastName: string;
}

export const onfidoCreateApplicant = async (
  payload: CreateOnfidoApplicantPayload
) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.post<IResponse>(
      "/registration/eid/applicant",
      payload
    );

    console.log("CREATE ONFIDO APPLICANT DATA", data);

    return data;

    // if (data.success) {
    //   return data;
    // } else {
    //   Notify.error(data.error);
    // }
  } catch (error) {
    Notify.error(error.message);
  }
};

export interface GetOnfidoApplicantPayload {
  applicantId: string;
}

export const onfidoGetApplicant = async ({
  applicantId,
}: GetOnfidoApplicantPayload) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.get<IResponse>(
      `/registration/eid/applicant/${applicantId}`
    );

    console.log("GET ONFIDO APPLICANT DATA", data);

    return data;

    // if (data.success) {
    //   return data;
    // } else {
    //   Notify.error(data.error);
    // }
  } catch (error) {
    Notify.error(error.message);
  }
};

export interface OnfidoGenerateTokenPayload {
  applicant_id: string;
  // referrer: string;
}

export const onfidoGenerateToken = async (
  payload: OnfidoGenerateTokenPayload
) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.post<IResponse>(
      "/registration/eid/generate_token",
      payload
    );

    console.log("GENERATE ONFIDO TOKEN", data);

    return data;

    // if (data.success) {
    //   return data;
    // } else {
    //   Notify.error(data.error);
    // }
  } catch (error) {
    Notify.error(error.message);
  }
};

export interface UploadCompanyFileParams {
  entityId: string;
  fileToUpload: File;
}

export const uploadCompanyFile = async ({
  entityId,
  fileToUpload,
}: UploadCompanyFileParams) => {
  try {
    const filePathRef = storage.ref().child(`onboarding/${entityId}`);

    await filePathRef.child(fileToUpload.name).put(fileToUpload);

    const downloadUrl = await filePathRef
      .child(fileToUpload.name)
      .getDownloadURL();

    return downloadUrl;
  } catch (error) {
    console.log(error);
  }
};

export interface UploadCheckFileParams {
  entityId: string;
  checkId: string;
  fileToUpload: File;
}

export const uploadCheckFile = async ({
  entityId,
  checkId,
  fileToUpload,
}: UploadCheckFileParams) => {
  try {
    const filePathRef = storage
      .ref()
      .child(`onboarding/${entityId}/${checkId}`);

    await filePathRef.child(fileToUpload.name).put(fileToUpload);

    const downloadUrl = await filePathRef
      .child(fileToUpload.name)
      .getDownloadURL();

    return downloadUrl;
  } catch (error) {
    console.log(error);
  }
};

export interface RemoveCheckFileParams {
  checkId: string;
  entityId: string;
  filename: File["name"];
}

export const removeCheckFile = async ({
  checkId,
  entityId,
  filename,
}: RemoveCheckFileParams) => {
  try {
    const filePathRef = storage
      .ref()
      .child(`onboarding/${entityId}/${checkId}`);

    await filePathRef.child(filename).delete();
  } catch (error) {
    // Notify.error(error);
  }
};

export interface RemoveCompanyFileParams {
  entityId: string;
  filename: File["name"];
}

export const removeCompanyFile = async ({
  entityId,
  filename,
}: RemoveCompanyFileParams) => {
  try {
    const filePathRef = storage.ref().child(`onboarding/${entityId}`);

    await filePathRef.child(filename).delete();
  } catch (error) {
    // Notify.error(error);
  }
};

export interface GetFileParams {
  entityId: string;
}

export const getAllEntityFiles = async ({ entityId }: GetFileParams) => {
  try {
    const filePathRef = storage.ref().child(`onboarding/${entityId}`);

    const filesRefs = await filePathRef.listAll();

    const filesData = [];

    await asyncForEach(filesRefs.items, async (item) => {
      const url = await item.getDownloadURL();
      const metadata = await item.getMetadata();

      filesData.push({
        ...metadata,
        url,
      });
    });

    return filesData;
  } catch (error) {
    Notify.error(error);
  }
};

export interface GetFileParams {
  filePath: string;
}

export const getFile = async ({ filePath }: GetFileParams) => {
  try {
    const filePathRef = storage.ref().child(filePath);

    const url = await filePathRef.getDownloadURL();
    const metadata = await filePathRef.getMetadata();

    return {
      url,
      metadata,
    };
  } catch (error) {
    message.error(error);
  }
};

export interface ExecuteActionOnOnboardingParams {
  entityId: string;
  onboardingId: string;
  action: "resend" | "newId" | "newidphoto" | "update" | "create";
  updateData?: any;
}

export const executeActionOnOnboarding = async ({
  entityId,
  onboardingId,
  action,
  updateData = {},
}: ExecuteActionOnOnboardingParams) => {
  const response = await AxiosPrivateFirebaseInstance.post<IResponse>(
    `/admin_actions/onboarding/${entityId}/${onboardingId}/${action}`,
    updateData
  );

  return response;
};

export interface UploadRecipientFileParams {
  entityId: string;
  recipientId: string;
  fileToUpload: File;
}

export const uploadRecipientFile = async ({
  entityId,
  recipientId,
  fileToUpload,
}: UploadRecipientFileParams) => {
  try {
    const filePathRef = storage
      .ref()
      .child(`onboarding/${entityId}/recipients/${recipientId}`);

    await filePathRef.child(fileToUpload.name).put(fileToUpload);

    const downloadUrl = await filePathRef
      .child(fileToUpload.name)
      .getDownloadURL();

    return downloadUrl;
  } catch (error) {
    console.log(error);
  }
};

export interface RemoveRecipientFileParams {
  storagePath: string;
}

export const removeRecipientFile = async ({
  storagePath,
}: RemoveRecipientFileParams) => {
  try {
    await storage.ref(storagePath).delete();
  } catch (error) {
    console.log(error);
  }
};

export interface UploadSenderFileParams {
  entityId: string;
  senderId: string;
  fileToUpload: File;
}

export const uploadSenderFile = async ({
  entityId,
  senderId,
  fileToUpload,
}: UploadSenderFileParams) => {
  try {
    const filePathRef = storage
      .ref()
      .child(`onboarding/${entityId}/senders/${senderId}`);

    await filePathRef.child(fileToUpload.name).put(fileToUpload);

    const downloadUrl = await filePathRef
      .child(fileToUpload.name)
      .getDownloadURL();

    return downloadUrl;
  } catch (error) {
    console.log(error);
  }
};

export interface RemoveSenderFileParams {
  storagePath: string;
}

export const removeSenderFile = async ({
  storagePath,
}: RemoveSenderFileParams) => {
  try {
    await storage.ref(storagePath).delete();
  } catch (error) {
    console.log(error);
  }
};
