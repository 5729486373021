import { validateUserClaims } from "./../../utils/roles";
import { message } from "antd";
import { action, Action, thunk, Thunk, computed, Computed } from "easy-peasy";
import firebase from "firebase/app";

import { Firebase } from "services";
import { TRoles, IUser } from "types";
import { Notify, getRolesFromClaims } from "utils";

export interface UserStateModel {
  user: IUser | null;
  // TODO: add correct interface for user entity
  userEntity: any;
  setUser: Action<UserStateModel, IUser>;
  setUserEntity: Action<UserStateModel, any>;
  signIn: Thunk<
    UserStateModel,
    {
      email: string;
      password: string;
    }
  >;
  signInWithGoogle: Thunk<UserStateModel>;
  getUser: Thunk<UserStateModel, { id: string; roles: TRoles }>;
  getUserEntity: Thunk<UserStateModel, Firebase.GetUserEntityParams>;
  userId: Computed<UserStateModel, IUser["id"]>;
  isUserRegistrationDone: Computed<UserStateModel, IUser["enabled"]>;
  entityId: Computed<UserStateModel, IUser["entityId"]>;
  // TODO: add correct type for entity
  isEntityEnabled: Computed<UserStateModel, boolean>;
  isAuthDone: boolean;
  setIsAuthDone: Action<UserStateModel, boolean>;
}

export const UserState: UserStateModel = {
  user: null,
  userEntity: null,
  isAuthDone: false,
  setIsAuthDone: action((state, payload) => {
    state.isAuthDone = payload;
  }),
  setUserEntity: action((state, payload) => {
    state.userEntity = payload;
  }),
  setUser: action((state, payload) => {
    state.user = payload;
  }),
  getUserEntity: thunk(async (actions, payload) => {
    const entity = await Firebase.getUserEntity(payload);

    if (entity) {
      actions.setUserEntity(entity);
    }
  }),
  signIn: thunk(async (actions, payload) => {
    try {
      const { user: authUser } = await Firebase.auth.signInWithEmailAndPassword(
        payload.email,
        payload.password
      );

      if (!authUser) {
        return;
      }

      const userFromDatabase = await Firebase.getUser({
        id: authUser.uid,
      });

      // this means user did not complete sign up step 3
      if (!userFromDatabase) {
        actions.setUser({
          id: authUser.uid,
          email: payload.email,
          name: authUser.displayName,
          enabled: false,
          status: "",
          entityId: "",
        });
      } else {
        actions.setUser({
          ...(userFromDatabase as IUser),
        });
      }

      return true;
    } catch (error) {
      console.log(error);
    }
  }),
  signInWithGoogle: thunk(async (actions, payload) => {
    const authProvider = new firebase.auth.GoogleAuthProvider();

    // auth listener will redirect user to home page if account exists already
    try {
      const response = await Firebase.auth.signInWithPopup(authProvider);
      const { user } = response;
      const { claims } = await user.getIdTokenResult();
      const isValidUser = validateUserClaims(claims);

      if (user && isValidUser) {
        const { uid } = user;

        const userFromDatabase = await Firebase.getUser({ id: uid });

        const userToSave = {
          ...userFromDatabase,
          roles: getRolesFromClaims(claims),
        };

        if (userFromDatabase) {
          actions.setUser(userToSave as IUser);
        }
      } else {
        message.error("Account has no access.");
      }
    } catch (error) {
      if (error.code === "auth/multi-factor-auth-required") {
        // user has 2FA enabled
        return error.resolver;
      } else {
        Notify.error(error.message);
      }
    }
  }),
  getUser: thunk(async (actions, payload) => {
    const user = await Firebase.getUser(payload);

    if (user) {
      actions.setUser({
        ...user,
        roles: payload.roles,
      } as IUser);
    }
  }),
  userId: computed(({ user }) => user?.id || null),
  isUserRegistrationDone: computed(({ user }) => user?.enabled),
  entityId: computed(({ user }) => user?.entityId || null),
  isEntityEnabled: computed(({ userEntity }) => userEntity?.enabled),
};
