import { FC, useEffect, useState } from "react";
import { Table, Typography, Switch, Input, Space, Button, Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

import { Firebase } from "services";
import { toggleUserDebugMode } from "services/firebase";
import { useParams } from "react-router";
import { TCustomRouteComponentProps } from "utils";

const { Title } = Typography;
const { Column } = Table;

const Users: FC<TCustomRouteComponentProps> = ({ allowWrite }) => {
  const [users, setUsers] = useState([]);
  const [, setSearchText] = useState("");
  const [, setSearchedColumn] = useState("");

  // @ts-ignore
  const { user_id } = useParams();

  useEffect(() => {
    const unsubscribe = Firebase.subscribeToUsers({
      callback: (data) => {
        if (user_id) {
          const filteredData = data.filter((user) => user.id === user_id);
          setUsers(filteredData);
        } else {
          setUsers(data);
        }
      },
    });

    return () => {
      unsubscribe && unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
  });

  return (
    <div>
      <Table title={() => <Title level={2}>Users</Title>} dataSource={users}>
        <Column
          title="ID"
          key="id"
          dataIndex="id"
          {...getColumnSearchProps("id")}
        />
        <Column
          title="Entity ID"
          key="entityId"
          dataIndex="entityId"
          render={(value) => (
            <Link to={`/app/entity-detail/${value}`}>{value}</Link>
          )}
          {...getColumnSearchProps("entityId")}
        />
        <Column
          title="Onboarding ID"
          key="onboardingId"
          dataIndex="onboardingId"
          {...getColumnSearchProps("onboardingId")}
        />
        <Column
          title="Created"
          key="_created"
          dataIndex="_created"
          render={(date) => dayjs(date).format("DD MMM YYYY HH:mm:ss")}
          {...getColumnSearchProps("_created")}
        />
        <Column
          title="Updated"
          key="_updated"
          dataIndex="_updated"
          render={(date) => dayjs(date).format("DD MMM YYYY HH:mm:ss")}
          {...getColumnSearchProps("_updated")}
        />
        <Column
          title="Email"
          key="email"
          dataIndex="email"
          {...getColumnSearchProps("email")}
        />
        <Column
          title="Name"
          key="name"
          dataIndex="name"
          {...getColumnSearchProps("name")}
        />
        <Column
          title="Status"
          key="enabled"
          render={({ enabled, id }) => (
            <div style={{ whiteSpace: "nowrap" }}>
              {enabled ? (
                <Tag color="green">ENABLED</Tag>
              ) : (
                <Tag color="red">DISABLED</Tag>
              )}
              {allowWrite && (
                <Button
                  onClick={async () =>
                    await Firebase.userAuthChangeStatus(
                      id,
                      enabled ? "disable" : "enable"
                    )
                  }
                >
                  {enabled ? "Disable" : "Enable"}
                </Button>
              )}
            </div>
          )}
        />
        {allowWrite && (
          <Column
            title="Debuggable"
            key="debuggable"
            dataIndex="debuggable"
            render={(value, record: any) => (
              <Switch
                checked={!!record.debuggable}
                onChange={() =>
                  toggleUserDebugMode({
                    userId: record.id,
                    value: !record.debuggable,
                  })
                }
              />
            )}
          />
        )}
      </Table>
    </div>
  );
};

export default Users;
